import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { ScrollAnchorService } from './scroll-anchor.service';

@Directive({
  selector: '[prlwScrollAnchorTarget]',
  standalone: true,
})
export class ScrollAnchorTargetDirective implements OnDestroy {
  private readonly observer: ResizeObserver;

  ngOnDestroy(): void {
    this.observer.unobserve(this.el.nativeElement);
  }

  constructor(
    private readonly scrollAnchorService: ScrollAnchorService,
    private readonly el: ElementRef,
  ) {
    this.observer = new ResizeObserver((entries) => {
      entries.forEach(() => {
        scrollAnchorService.setTargetElement(el.nativeElement);
      });
    });

    this.observer.observe(el.nativeElement);
  }
}
