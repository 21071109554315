import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { USER_SETTINGS_GATEWAY } from '@prlw/core/user-settings/user-settings-gateway.token';
import { UserSettingsGateway } from '@prlw/core/user-settings/user-settings-gateway.interface';
import { Setting } from './setting.interface';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsController implements OnDestroy {
  private readonly settingsSubjects: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: ReplaySubject<any> | undefined;
  } = {};
  private readonly _destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(USER_SETTINGS_GATEWAY)
    private readonly userSettingsGateway: UserSettingsGateway,
  ) {}

  public setting$<T, D>(
    setting: Setting<T>,
    defaultValue: D,
    fetch: boolean = true,
  ): Observable<T | D> {
    let subject$ = this.settingsSubjects[setting.key];

    if (!subject$) {
      subject$ = new ReplaySubject<T>(1);
      this.settingsSubjects[setting.key] = subject$;

      if (fetch) {
        this.userSettingsGateway
          .getSettingByKey<T>(setting.key)
          .pipe(map((val) => val ?? defaultValue))
          .subscribe((val) => {
            subject$?.next(val);
          });
      }
    }

    return subject$.asObservable();
  }

  public getSetting<T>(setting: Setting<T>): Observable<T | undefined> {
    return this.userSettingsGateway.getSettingByKey<T>(setting.key);
  }

  public updateSetting<T>(setting: Setting<T>, value: T): Observable<void> {
    this.settingsSubjects[setting.key]?.next(value);
    return this.userSettingsGateway.updateSettingByKey(setting.key, value);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
