import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtStorage } from '@prlw/core/auth/jwt/jwt.storage';
import { PrlwAuthError } from '@prlw/infrastructure/errors/error-types/error-types';
import { NotificationService } from '@prlw/infrastructure/errors/notification/notification.service';

@Injectable({ providedIn: 'root' })
export class LogoutController {
  constructor(
    private readonly storage: JwtStorage,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
  ) {}

  public logout(): void {
    this.storage.dropTokens();
  }

  public logoutWithError(e: PrlwAuthError) {
    this.storage.dropTokens();
    this.router.navigate(['/sign-in']);
    this.notificationService.notify(e);
  }
}
