import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { LicenseHistory } from '@prlw/core/profile/profile-license.entity';

@Component({
  selector: 'prlw-license-history-ui',
  templateUrl: './license-history.component.html',
  styleUrls: ['./license-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenseHistoryComponent {
  @Input() public history!: LicenseHistory[];

  public statusClass(status: string): string {
    return status === 'Оплачен' ? 'paid' : 'invoiced';
  }
}
