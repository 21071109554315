<ng-container *ngIf="organization !== undefined; else preloader">
  <prlw-profile-organization-info-ui
    *ngIf="organization !== null"
    [organization]="organization"
    [areRegulationsSigned]="areRegulationsSigned || false"
  ></prlw-profile-organization-info-ui>
  <prlw-profile-add-organization-ui
    *ngIf="organization === null"
  ></prlw-profile-add-organization-ui>
</ng-container>
<ng-template #preloader> Загрузка... </ng-template>
