import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PaginationInterceptor } from '@prlw/infrastructure/pagination/pagination.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: PaginationInterceptor,
    },
  ],
})
export class PaginationInfrastructureModule {}
