import { shareReplay } from 'rxjs/operators';
import { Product } from '@prlw/core/products/product.entity';
import { ProductsGateway } from '@prlw/core/products/products.gateway';
import { Observable } from 'rxjs';

function getCacheKey(productGroup: string, shipments: string[]): string {
  return [productGroup, shipments.join(',')].join('|');
}

export class ProductsGatewayCacheDecorator implements ProductsGateway {
  private readonly cache: { [key: string]: Observable<Product[]> } = {};

  constructor(private readonly gateway: ProductsGateway) {}

  public getProductsOfGroup(
    productGroup: string,
    shipments: string[],
  ): Observable<Product[]> {
    const key = getCacheKey(productGroup, shipments);
    if (!(key in this.cache)) {
      const result$ = this.gateway
        .getProductsOfGroup(productGroup, shipments)
        .pipe(shareReplay(1));
      this.cache[key] = result$;
    }
    return this.cache[key];
  }
}
