import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PageContextService } from '@prlw/common/page/page-context.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageContext } from '@prlw/common/page/page-context';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService implements OnDestroy {
  private readonly _subscription: Subscription | undefined;

  constructor(
    private readonly title: Title,
    private readonly pageContextService: PageContextService,
  ) {
    this._subscription = pageContextService.pageContext$
      .pipe(map((pageContext: PageContext) => pageContext.pageTitle))
      .subscribe((pageTitle: string) => this.setPageTitle(pageTitle));
  }

  public setPageTitle(pageTitle: string): void {
    if (pageTitle) {
      this.title.setTitle(`${pageTitle} | PROLEUM`);
    } else {
      this.title.setTitle('PROLEUM');
    }
  }

  public ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }
}
