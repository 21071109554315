import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { IconRegistry } from '@prlw/libs/icons/icon-registry';
import { Subscription } from 'rxjs';
import { Icon } from './icon.type';

@Component({ selector: 'prlw-icon', template: '' })
export class IconComponent implements OnInit, OnDestroy {
  private _subscription: Subscription | undefined;

  @Input() public name: string = '';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly iconRegistry: IconRegistry,
  ) {}

  public ngOnInit(): void {
    this._subscription = this.iconRegistry
      .getIcon(this.name)
      .subscribe((icon) => {
        this._renderIcon(icon);
      });
  }

  public ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _renderIcon(icon: Icon): void {
    this.elementRef.nativeElement.classList.add('prlw-icon');
    this.elementRef.nativeElement.innerHTML = icon.svg;
  }
}
