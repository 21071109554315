import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PreloaderCircleComponent } from './preloader-circle.component';
import { CommonModule } from '@angular/common';
import { PagePreloaderService } from '../page/page-preloader.service';
import { concat, delay, map, merge, of, switchMap } from 'rxjs';

@Component({
  selector: 'prlw-preloader-circle',
  standalone: true,
  imports: [CommonModule, PreloaderCircleComponent],
  templateUrl: './preloader-circle.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreloaderCircleContainer {
  constructor(private readonly pagePreloaderService: PagePreloaderService) {}

  active$ = merge(
    this.pagePreloaderService.loading$.pipe(
      switchMap(({ pageTitle }) =>
        concat(
          of(
            [
              'Маркет монитор',
              'Срочный рынок',
              'Все сделки',
              'Аналитика',
              'Регион',
            ].includes(pageTitle),
          ),
          of(false).pipe(delay(10 * 1000)),
        ),
      ),
    ),
    this.pagePreloaderService.loaded$.pipe(map(() => false)),
  );
}
