import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';
import { Subject, debounceTime, takeUntil } from 'rxjs';

export function valueValidator(minValue: number): ValidatorFn {
  return (control: AbstractControl<number>): ValidationErrors | null => {
    return control.value % minValue === 0
      ? null
      : { forbiddenValue: { value: control.value } };
  };
}

@Component({
  selector: 'prlw-number-input-ui',
  styleUrls: ['./number-input.component.scss'],
  templateUrl: './number-input.component.html',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ResponsiveDirective],
})
export class NumberInputComponent implements OnInit, OnDestroy {
  @Input() minValue: number = 60;
  @Input() maxValue: number = this.minValue * 1000;
  @Input() currentValue: number | null = null;
  @Input() label: string | null = null;
  @Input() placeholder = 'Введите значение';
  @Input() step: number | null = null;
  @Input() column = true;
  @Input() appearance: 'grey' | 'white' = 'white';

  @Output() public readonly setValue = new EventEmitter<FormControl>();

  private _destroy = new Subject<void>();

  public value = new FormControl<number>(this.minValue);

  ngOnInit(): void {
    this.value = new FormControl<number>(this.currentValue || this.minValue, [
      Validators.required,
      Validators.min(this.minValue),
      Validators.max(this.maxValue),
      valueValidator(this.minValue),
    ]);

    this.value.valueChanges
      .pipe(debounceTime(800), takeUntil(this._destroy))
      .subscribe((value) => {
        if (value) {
          this.value.setValue(this._roundValue(value), { emitEvent: false });
          this.setValue.emit(this.value);
        }
      });

    this.setValue.emit(this.value);
  }

  private _roundValue(value: number) {
    const nextValue = Math.round(value / this.minValue) * this.minValue;
    if (nextValue < this.minValue) return this.minValue;
    if (nextValue > this.maxValue) return this.maxValue;
    return nextValue;
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  public processPaste(event: ClipboardEvent): void {
    event.preventDefault();
  }

  public processKeyDown(event: KeyboardEvent) {
    if (event.key === 'e') {
      event.preventDefault();
    }
  }

  public getAppearance() {
    return `${this.appearance}-appearance`;
  }
}
