import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { JWT_GATEWAY, JwtGateway } from '@prlw/core/auth/jwt/jwt.gateway';
import { RefreshToken } from '@prlw/core/auth/jwt/refresh-token';
import { JwtStorage } from '@prlw/core/auth/jwt/jwt.storage';
import { LogoutController } from '../logout/logout.controller';

@Injectable({ providedIn: 'root' })
export class JwtRefresher implements OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  constructor(
    @Inject(JWT_GATEWAY) private readonly gateway: JwtGateway,
    private readonly storage: JwtStorage,
    private readonly logoutController: LogoutController,
  ) {}

  public init(): void {
    this.storage.tokens$
      .pipe(
        filter(
          (tokens) =>
            tokens.accessToken === null && tokens.refreshToken !== null,
        ),
        switchMap((tokens) =>
          this.gateway.refresh(tokens.refreshToken as RefreshToken),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe((result) => {
        if (result.success) {
          this.storage.updateTokens(
            result.tokens.accessToken,
            result.tokens.refreshToken,
          );
          return;
        }
        this.logoutController.logoutWithError(result.error);
      });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
