import { Inject, Injectable, InjectionToken } from '@angular/core';
import * as _ from 'lodash';
import {
  combineLatest,
  filter,
  first,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { UserSettingsController } from '../user-settings/user-settings.controller';
import { ProductGroup } from './product-group.entity';
import {
  PRODUCT_GROUPS_GATEWAY,
  ProductGroupsGateway,
} from './product-groups.gateway';
import { Setting } from '@prlw/core/user-settings/setting.interface';
import { NavigationEnd, Router } from '@angular/router';
import { ResponsiveService } from '@prlw/libs/responsive/responsive.service';

export const PRODUCT_GROUP_CODE_IN_URL_REGEX = new InjectionToken<RegExp>(
  'PRODUCT_GROUP_CODE_IN_URL_REGEX',
);

export function productCodeToProduct(
  availableProducts: ProductGroup[],
  code: string,
): ProductGroup {
  const product = availableProducts.find((p) => p.code === code);
  if (!product) {
    if (!availableProducts.length) {
      throw new Error(`availableProducts array is empty`);
    }
    return availableProducts[0];
  }
  return product;
}

function extractProductCode(productOrCode: ProductGroup | string): string {
  return typeof productOrCode === 'string' ? productOrCode : productOrCode.code;
}

const CURRENT_PRODUCT_GROUP_CODE_SETTING = new Setting<string>(
  'main-table-current-product-group',
);
const SELECTED_PRODUCT_GROUP_CODES_SETTING = new Setting<string[]>(
  'selected-product-groups',
);

@Injectable({
  providedIn: 'root',
})
export class ProductGroupsService {
  public readonly currentProductGroupCode$: Observable<string>;
  private readonly selectedProductGroupCodesSetting$: Observable<string[]>;
  public readonly productGroups$: Observable<ProductGroup[]>;

  constructor(
    private readonly userSettings: UserSettingsController,
    @Inject(PRODUCT_GROUPS_GATEWAY)
    gateway: ProductGroupsGateway,
    private readonly router: Router,
    @Inject(PRODUCT_GROUP_CODE_IN_URL_REGEX)
    private readonly productInUrlRegex: RegExp,
    private readonly responsiveService: ResponsiveService,
  ) {
    this.selectedProductGroupCodesSetting$ = userSettings.setting$(
      SELECTED_PRODUCT_GROUP_CODES_SETTING,
      [],
    );
    this.productGroups$ = gateway.getProductGroups().pipe(shareReplay(1));
    this.currentProductGroupCode$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event) => event.urlAfterRedirects),
      switchMap((url) => {
        return this.productGroups$.pipe(
          withLatestFrom(responsiveService.xsmall$),
          map(([productGroups, xsmall]) =>
            xsmall
              ? productGroups.map((item) => item.code)
              : [...productGroups.map((item) => item.code), 'all'],
          ),
          switchMap((allowedProductGroupsCodes) => {
            const parsedProductCodeFromUrl = this.parseProductCodeFromUrl(url);
            if (parsedProductCodeFromUrl) {
              if (
                allowedProductGroupsCodes.includes(parsedProductCodeFromUrl)
              ) {
                return of(parsedProductCodeFromUrl);
              }
              return of(allowedProductGroupsCodes[0]);
            }
            return userSettings
              .getSetting(CURRENT_PRODUCT_GROUP_CODE_SETTING)
              .pipe(
                map((currentProductGroupSetting) => {
                  if (currentProductGroupSetting) {
                    if (
                      allowedProductGroupsCodes.includes(
                        currentProductGroupSetting,
                      )
                    )
                      return currentProductGroupSetting;
                  }
                  return allowedProductGroupsCodes[0];
                }),
              );
          }),
        );
      }),
      tap((code) => {
        this.setCurrentProductGroup(code);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private parseProductCodeFromUrl(url: string): string | null {
    return this.productInUrlRegex.exec(url)?.[1] ?? null;
  }

  public get currentProductGroup$(): Observable<ProductGroup> {
    return combineLatest([
      this.productGroups$,
      this.currentProductGroupCode$,
    ]).pipe(
      map(([productGroups, currentProductGroupCode]) =>
        productCodeToProduct(productGroups, currentProductGroupCode),
      ),
    );
  }

  public setCurrentProductGroup(groupOrCode: ProductGroup | string): void {
    const code = extractProductCode(groupOrCode);
    this.userSettings
      .updateSetting(CURRENT_PRODUCT_GROUP_CODE_SETTING, code)
      .pipe(first())
      .subscribe();
  }

  public get selectedProductGroups$(): Observable<ProductGroup[]> {
    return combineLatest([
      this.productGroups$,
      this.selectedProductGroupCodesSetting$,
    ]).pipe(
      map(([productGroups, selectedProductGroupCodes]) =>
        selectedProductGroupCodes.map((groupCode) =>
          productCodeToProduct(productGroups, groupCode),
        ),
      ),
    );
  }

  public selectProductGroup(groupOrCode: ProductGroup | string): void {
    const code = extractProductCode(groupOrCode);
    this.selectedProductGroupCodesSetting$
      .pipe(
        first(),
        map((selectedCodes) => [...selectedCodes, code]),
        switchMap((newCodes) =>
          this.userSettings.updateSetting(
            SELECTED_PRODUCT_GROUP_CODES_SETTING,
            newCodes,
          ),
        ),
      )
      .subscribe();
  }

  public unselectProductGroup(groupOrCode: ProductGroup | string): void {
    const code = extractProductCode(groupOrCode);
    this.selectedProductGroupCodesSetting$
      .pipe(
        first(),
        map((selectedCodes) => selectedCodes.filter((c) => c !== code)),
        switchMap((newCodes) =>
          this.userSettings.updateSetting(
            SELECTED_PRODUCT_GROUP_CODES_SETTING,
            newCodes,
          ),
        ),
      )
      .subscribe();
  }

  public get isEveryProductGroupSelected$(): Observable<boolean> {
    return combineLatest([
      this.productGroups$,
      this.selectedProductGroups$,
    ]).pipe(
      map(
        ([productGroups, selectedProductGroups]) =>
          _.xor(productGroups, selectedProductGroups).length === 0,
      ),
    );
  }
}
