import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TitleLabelComponent } from './title-label.component';
import { ProfileController } from '@prlw/core/profile/profile.controller';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'prlw-title-label',
  standalone: true,
  imports: [CommonModule, TitleLabelComponent],
  templateUrl: './title-label.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleLabelContainer {
  @Input() title: string | null = 'foobar';

  constructor(private profileController: ProfileController) {}

  isPro$ = this.profileController.isPro$;
}
