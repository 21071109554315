import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JwtStorage } from '@prlw/core/auth/jwt/jwt.storage';
import {
  AuthLevel,
  DemoAuthLevel,
  GuestAuthLevel,
} from '@prlw/core/auth/auth-level';

@Injectable({ providedIn: 'root' })
export class AuthStateProvider {
  constructor(private readonly storage: JwtStorage) {}

  public get authLevel$(): Observable<AuthLevel> {
    return this.storage.tokens$.pipe(
      map((tokens) => tokens.refreshToken && tokens.accessToken),
      map((isBothTokensReleased) =>
        isBothTokensReleased ? new DemoAuthLevel() : new GuestAuthLevel(),
      ),
    );
  }

  public get hasRefreshToken$(): Observable<boolean> {
    return this.storage.tokens$.pipe(map((tokens) => !!tokens.refreshToken));
  }
}
