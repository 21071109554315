import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { MarketTimeTooltipComponent } from '@prlw/common/market/market-time/market-time-tooltip/market-time-tooltip.component';
import { ComponentPortal } from '@angular/cdk/portal';

@Directive({
  selector: '[prlwTradingTooltip]',
})
export class MarketTimeDirective implements OnDestroy {
  private readonly overlayRef: OverlayRef;
  @Input('prlwTradingTooltip') text: string | null = null;

  constructor(
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly elementRef: ElementRef,
    private readonly overlay: Overlay,
  ) {
    const Y = 9;
    const X = -24;
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: Y,
          offsetX: X,
        },
      ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter')
  private show(): void {
    const tooltipRef = this.overlayRef.attach(
      new ComponentPortal(MarketTimeTooltipComponent),
    );
    tooltipRef.instance.text = this.text;
  }

  @HostListener('mouseleave')
  private hide(): void {
    this.overlayRef.detach();
  }

  ngOnDestroy(): void {
    this.overlayRef.detach();
  }
}
