<div class="license">
  <h1 class="license-title">Лицензия</h1>
  <div class="top-grid-container">
    <h2>{{ profileLicense?.licenceType }}</h2>
    <span class="dim">
      {{ isLight ? '' : 'Осталось ' + profileLicense?.expiresDay + ' дней' }}
    </span>
    <button class="apply-btn" (click)="navigate()">
      {{ isPro ? 'Продлить' : 'Купить PRO' }}
    </button>
  </div>
  <div class="bottom-grid-container">
    <span class="alert">{{ isLight ? 'Возможны ограничения' : '' }}</span>
    <span class="license-link"
      >Уже оплатили? Загрузить подтверждение
      <div class="arrow">&#8250;</div></span
    >
  </div>
</div>
