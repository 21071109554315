<ng-container [ngSwitch]="activeLayout$ | async">
  <!-- Primary -->
  <prlw-layout-primary *ngSwitchCase="layouts.Primary">
    <ng-container *ngTemplateOutlet="pageContent"></ng-container>
  </prlw-layout-primary>

  <!-- Minimal -->
  <prlw-layout-minimal *ngSwitchCase="layouts.Minimal">
    <ng-container *ngTemplateOutlet="pageContent"></ng-container>
  </prlw-layout-minimal>

  <!-- Place new layouts here -->
</ng-container>

<ng-template #pageContent>
  <ng-content></ng-content>
</ng-template>
