<div class="gratitude-container" prlwResponsive>
  <prlw-icon
    *ngIf="(xsmall$ | async) === false"
    class="cross-circle"
    name="cross-circle"
    (click)="exit()"
  ></prlw-icon>
  <prlw-icon
    class="big-checkmark"
    name="big-checkmark"
    prlwResponsive
  ></prlw-icon>
  <h2
    class="gratitude-container__title"
    data-cy="Feedback gratitude"
    prlwResponsive
  >
    Спасибо за ваш отзыв
  </h2>
  <button
    class="ui-button ui-black"
    [class.has-hover]="(xsmall$ | async) === false"
    (click)="exit()"
  >
    Готово
  </button>
</div>
