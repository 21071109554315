import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalendarGateway } from '@prlw/core/calendar/calendar.gateway';

@Injectable({
  providedIn: 'root',
})
export class HttpCalendarGateway implements CalendarGateway {
  constructor(private readonly httpClient: HttpClient) {}

  public getHolidaysForPeriod(params: { startDate: string; endDate: string }) {
    return this.httpClient.get<string[]>(
      `api/calendar/holidays-for-period?startDate=${params.startDate}&endDate=${params.endDate}`,
    );
  }
}
