import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TabsService {
  private readonly activeTabIdSubject$ = new Subject<string>();
  public readonly activeTabId$ = this.activeTabIdSubject$.asObservable();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly tabExtraDataSubject$ = new Subject<any>();
  public readonly tabExtraData$ = this.tabExtraDataSubject$.asObservable();

  public setActiveTabId(activeTabId: string): void {
    this.activeTabIdSubject$.next(activeTabId);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  public setTabExtraData(extra: any): void {
    this.tabExtraDataSubject$.next(extra);
  }
}
