export type Destination = {
  id: string;
  name: string;
};

export type DestinationEntity = {
  destination: Destination;
  flags: { [key in DestinationStatus]: boolean };
};

/** @deprecated Use {@link DestinationEntity} instead. */
export type DestinationEntityV1 = {
  destination: Destination;
  isInWork: boolean;
  isFavorites: boolean;
  isActive: boolean;
};

export function isDestinationEntityV1(
  ent: DestinationEntity | DestinationEntityV1,
): ent is DestinationEntityV1 {
  return !(ent as DestinationEntity).flags;
}

export function migrateToDestinationEntityV2(
  old: DestinationEntityV1,
): DestinationEntity {
  return {
    destination: old.destination,
    flags: {
      isInWork: old.isInWork,
      isActive: old.isActive,
      isFavorites: old.isFavorites,
    },
  };
}

export enum DestinationStatus {
  IS_WORK = 'isInWork',
  IS_FAVORITES = 'isFavorites',
  IS_ACTIVE = 'isActive',
}

export type ExpandedView = {
  isExpandedTop: boolean;
  isExpandedBottom: boolean;
};
