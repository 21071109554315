import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';

import { JwtStorage } from '@prlw/core/auth/jwt/jwt.storage';
import { AccessToken } from '@prlw/core/auth/jwt/access-token';
import { PrlwAuthError } from '../errors/error-types/error-types';
import { AuthErrorCode } from '../errors/error-codes/client-error-codes';
import { LogoutController } from '@prlw/core/auth/logout/logout.controller';

const AUTH_HEADER_NAME = 'Authorization';
const AUTH_HEADER_PREFIX = 'Bearer ';
const NEED_REFRESH_HTTP_STATUS = 401;

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly storage: JwtStorage,
    private readonly logoutController: LogoutController,
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.get('SkipAuth')) {
      const cleanedRequest = request.clone({
        headers: request.headers.delete('SkipAuth'),
      });
      return next.handle(cleanedRequest);
    }
    return this.withToken(request).pipe(
      switchMap((requestWithToken) => next.handle(requestWithToken)),
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === NEED_REFRESH_HTTP_STATUS
        ) {
          this.storage.dropAccessToken();
          return this.retry(request, next);
        }
        return throwError(() => error);
      }),
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.error.message === AuthErrorCode.MultipleLogin
        ) {
          this.logoutController.logoutWithError(
            new PrlwAuthError(AuthErrorCode.MultipleLogin),
          );
        }
        return throwError(() => error);
      }),
    );
  }

  private retry(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.withToken(request).pipe(
      switchMap((requestWithToken) => next.handle(requestWithToken)),
    );
  }

  private withToken(
    request: HttpRequest<unknown>,
  ): Observable<HttpRequest<unknown>> {
    return this.storage.tokens$.pipe(
      filter((tokens) => tokens.accessToken !== null),
      first(),
      map((tokens) => tokens.accessToken as AccessToken),
      map((accessToken) =>
        request.clone({
          setHeaders: {
            [AUTH_HEADER_NAME]: `${AUTH_HEADER_PREFIX}${accessToken}`,
          },
        }),
      ),
    );
  }
}
