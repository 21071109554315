import { HttpSmallWholesaleGateway } from './small-wholesale.gateway';
import { SMALL_WHOLESALE_GATEWAY } from '@prlw/core/small-wholesale/small-wholesale-gateway.interface';
import {
  SmallWholesaleDataConfig,
  SMALL_WHOLESALE_DATA_CONFIG,
} from './small-wholesale-data-config.type';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: SMALL_WHOLESALE_GATEWAY, useClass: HttpSmallWholesaleGateway },
  ],
})
export class SmallWholesaleDataModule {
  public static forRoot(
    config: SmallWholesaleDataConfig,
  ): ModuleWithProviders<SmallWholesaleDataModule> {
    return {
      ngModule: SmallWholesaleDataModule,
      providers: [{ provide: SMALL_WHOLESALE_DATA_CONFIG, useValue: config }],
    };
  }
}
