import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PageContextService } from '@prlw/common/page/page-context.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'prlw-header-minimal',
  templateUrl: './header-minimal.component.html',
  styleUrls: ['./header-minimal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMinimalComponent implements OnInit {
  public title$: Observable<string> | undefined;

  constructor(private readonly pageContextService: PageContextService) {}

  public ngOnInit(): void {
    this.title$ = this.pageContextService.pageContext$.pipe(
      map((context) => context.headerTitle),
    );
  }
}
