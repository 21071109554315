<div class="container">
  <div class="header">
    <span class="header__title">Загрузить подтверждения оплаты</span>
    <div class="close" (click)="onCloseOverlay()">
      <button class="close-btn">
        <prlw-icon name="close-cross" class="close-btn__icon"></prlw-icon>
      </button>
    </div>
  </div>
  <div class="content">
    <div class="header-content">
      <prlw-uploaded-documents></prlw-uploaded-documents>
    </div>
    <div class="file">
      <prlw-upload-steps></prlw-upload-steps>
    </div>
  </div>
</div>
