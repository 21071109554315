import { Layout } from '@prlw/common/layout/layout.enum';
import { IPage } from '@prlw/common/page/page.interface';

export class PageContext {
  constructor(
    public readonly pageTitle: string,
    public readonly headerTitle: string,
    public readonly layout: Layout,
  ) {}

  public static fromPage(page: IPage): PageContext {
    return new PageContext(page.pageTitle, page.headerTitle, page.layout);
  }
}
