import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CheckList } from '@prlw/core/profile/summary.entity';

@Component({
  selector: 'prlw-profile-summary-check-list',
  templateUrl: './profile-summary-check-list.component.html',
  styleUrls: ['./profile-summary-check-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSummaryCheckListComponent {
  public isExpand = true;
  @Input() public profileCheckList: CheckList | null = null;

  public expand(): void {
    this.isExpand = !this.isExpand;
  }
}
