import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ANALYTICS_CHARTS_GATEWAY } from '@prlw/core/analytics-charts/analytics-charts-gateway.token';
import { HttpAnalyticsChartsGateway } from './analytics-charts.gateway';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    { provide: ANALYTICS_CHARTS_GATEWAY, useClass: HttpAnalyticsChartsGateway },
  ],
})
export class AnalyticsChartsDataModule {}
