<div *ngIf="marketTime">
  <div
    *ngIf="!day"
    class="container"
    data-test="time-container"
    [class.trading-in-progress]="!tradingStatus"
    prlwResponsive
  >
    <div class="time">
      {{ marketTime.value | formatSeconds: 'h' | number: '2.0' }}
    </div>
    <div class="delimiter"></div>
    <div class="time">
      {{ marketTime.value | formatSeconds: 'm' | number: '2.0' }}
    </div>
    <div class="delimiter"></div>
    <div class="time">
      {{ marketTime.value | formatSeconds: 's' | number: '2.0' }}
    </div>
    <div class="desc" data-test="trading-time" *ngIf="!xsmall">
      {{ !tradingStatus ? 'до конца торгов' : 'до начала торгов' }}
    </div>
  </div>
  <div *ngIf="day" class="container" prlwResponsive>
    <span class="day" data-test="day"
      >{{ marketTime.value | formatSeconds: 'd' | number: '1.0' }}
    </span>
    &nbsp;
    <span class="desc pluralize" data-test="pluralize" prlwResponsive>
      {{ pluralize(marketTime.value) }} до начала торгов
    </span>
  </div>
</div>
