<div class="container">
  <div class="card" [class.active-card]="isLight">
    <div class="overlay">
      <div class="active-text" *ngIf="isLight">Активна</div>
    </div>
    <div class="wrapper">
      <div class="title">PROLEUM Light</div>
      <div class="text bold">Ограниченные возможности</div>
    </div>

    <div class="wrapper text-wrapper">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua.
    </div>

    <div class="wrapper" [class.free-text]="isLight">
      <div class="text bold">Бесплатно</div>
      <button [disabled]="!isLight" class="btn disabled" *ngIf="!isLight!">
        Перейти
      </button>
    </div>
  </div>

  <div class="card card-xl">
    <div class="overlay"></div>
    <div class="left-side">
      <div class="wrapper title-xl">
        <div class="title">PROLEUM Pro</div>
        <div class="text bold">Полные возможности</div>
      </div>

      <div class="wrapper">
        <form [formGroup]="form">
          <div class="tariff-wrapper">
            <div class="input-wrapper">
              <input
                class="radio-btn"
                type="radio"
                id="min-price"
                formControlName="price"
                value="1030"
              />
              <label class="bold" for="min-price">30 дней </label>
              <div class="check">
                <div class="dot"></div>
              </div>
            </div>
            <div class="price-wrapper">
              <div class="price bold">3 750 ₽</div>
            </div>
          </div>

          <div class="tariff-wrapper">
            <div class="input-wrapper">
              <input
                type="radio"
                id="mid-price"
                formControlName="price"
                value="200"
              />
              <label class="bold" for="mid-price">90 дней </label>
              <div class="check">
                <div class="dot"></div>
              </div>
            </div>
            <div class="price-wrapper">
              <div class="discount">22 500 ₽</div>
              <div class="price bold">16 875 ₽</div>
            </div>
          </div>

          <div class="tariff-wrapper">
            <div class="input-wrapper">
              <input
                type="radio"
                id="high-price"
                formControlName="price"
                value="100"
              />
              <label class="bold" for="high-price">360 дней </label>
              <div class="check">
                <div class="dot"></div>
              </div>
            </div>
            <div class="price-wrapper">
              <div class="discount">90 000 ₽</div>
              <div class="price bold">45 000 ₽</div>
            </div>
          </div>
        </form>
      </div>

      <div class="wrapper btn-buy">
        <button class="btn" (click)="onBuyLicense()">Купить PRO</button>
      </div>
    </div>

    <div class="right-side">
      <div class="wrapper wrapper-xl">
        <div class="bold">Вы получаете</div>
        <ul class="list">
          <li class="list-item">Мгновенный доступ к торговой информации</li>
          <li class="list-item">Модуль прогноза</li>
          <li class="list-item">Модуль «Все сделки»</li>
          <li class="list-item">Аналитика</li>
          <li class="list-item">Расчет ж/д тарифа</li>
          <li class="list-item">Цена на месте</li>
          <li class="list-item">График «время в пути»</li>
        </ul>
      </div>
    </div>
  </div>
</div>
