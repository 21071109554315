import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollAnchorService {
  private readonly elements = new Map<string, ElementRef>();
  targetElement$ = new BehaviorSubject<Element>(
    document.querySelector('body') as unknown as Element,
  );

  intersectionAnchor$ = new BehaviorSubject('foobarAnchor');

  addElement(key: string, el: ElementRef) {
    this.elements.set(key, el);
  }

  getElement(key: string) {
    return this.elements.get(key);
  }

  setTargetElement(el: Element) {
    this.targetElement$.next(el);
  }

  setIntersectionAnchor(value: string) {
    this.intersectionAnchor$.next(value);
  }

  scrollTo(anchor: string, offset = 0) {
    this.targetElement$.getValue().scrollTo({
      top: this.elements.get(anchor)?.nativeElement.offsetTop + offset,
    });
  }
}
