import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnsState } from '@prlw/libs/columns-state/columns.state';
import {
  AllDealsTableColumn,
  AllDealsTableColumnId,
} from '@prlw/core/deals/types/all-deals.types';
import { UserSettingsController } from '@prlw/core/user-settings/user-settings.controller';
import { allDealsTableColumns } from '@prlw/core/deals/data/all-deals.data';
import { Setting } from '../user-settings/setting.interface';

export const ALL_DEALS_TABLE_COLUMNS_STATE = 'all-deals-columns-state';

export const ALL_DEALS_TABLE_COLUMNS_SETTING = new Setting<
  AllDealsTableColumn<AllDealsTableColumnId>[]
>('all-deals-table-columns-setting');

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: ALL_DEALS_TABLE_COLUMNS_STATE,
      useFactory: (userSettingsController: UserSettingsController) =>
        new ColumnsState<AllDealsTableColumnId>(
          userSettingsController,
          ALL_DEALS_TABLE_COLUMNS_SETTING,
          allDealsTableColumns,
        ),
      deps: [UserSettingsController],
    },
  ],
})
export class DealsCoreModule {}
