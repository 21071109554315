<div class="feedback-container__header" *ngIf="(xsmall$ | async) === false">
  <div class="flex-space-between">
    <h2 class="feedback-container__title" data-cy="Feedback title">
      Обратная связь
    </h2>

    <img
      class="cancel"
      src="assets/icons/cross-circle-white.svg"
      (click)="exit()"
      data-cy="Feedback cancel"
      alt="cancel"
    />
  </div>
</div>
<div class="feedback-container" prlwResponsive>
  <div class="feedback-container__inputs textarea-inputs" prlwResponsive>
    <div class="label thanks-for-feedback" prlwResponsive>
      Мы рады обратной связи! <br *ngIf="xsmall$ | async" />Поделитесь с нами
      своими мыслями.
    </div>
    <textarea
      class="ui-input"
      [(ngModel)]="themeInput"
      required
      minlength="1"
      rows="1"
      placeholder="Тема"
      data-cy="Feedback input theme"
      prlwResponsive
    ></textarea>
    <textarea
      class="ui-input message"
      [(ngModel)]="messageInput"
      required
      minlength="1"
      rows="3"
      placeholder="Сообщение"
      data-cy="Feedback input message"
      prlwResponsive
    ></textarea>
  </div>
  <div class="feedback-container__inputs attached-files-input" prlwResponsive>
    <div class="flex-space-between">
      <div class="label">Приложенные файлы</div>
      <div>
        <input
          hidden="true"
          #fileInput
          type="file"
          class="file-input"
          [accept]="acceptedFileExtensions"
          (change)="attachFile($event)"
        />
        <button
          class="ui-button-add"
          action="file"
          (click)="onSelectFile()"
          type="submit"
        >
          Добавить
        </button>
      </div>
    </div>

    <ng-container *ngIf="attachedFiles$ | async as attachedFiles">
      <div *ngFor="let attachedFile of attachedFiles; let i = index">
        <div class="file-container">
          <div class="flex-space-between">
            <div class="flex">
              <prlw-icon
                name="upload-file"
                class="icon-upload-file"
                [class.grey]="attachedFile.error"
              ></prlw-icon>
              <div class="file-name-progress">
                <div class="file-name" [class.grey]="attachedFile.error">
                  {{ attachedFile.fileName }}
                </div>
                <div class="progress" *ngIf="attachedFile.progress">
                  <prlw-file-upload-progress [progress]="attachedFile.progress">
                  </prlw-file-upload-progress>
                </div>
                <div *ngIf="attachedFile.error" class="red">
                  {{ attachedFile.error }}
                </div>
              </div>
            </div>
            <img
              *ngIf="!attachedFile.pending"
              class="cross-circle-grey"
              src="/assets/icons/cross-circle-grey.svg"
              (click)="onRemoveAttachedFile(attachedFile.fileName)"
              alt="pending"
            />
          </div>
          <div
            class="grey-line"
            [class.display-none]="i === attachedFiles.length - 1"
          ></div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="flex-space-between send-container" prlwResponsive>
    <div class="you-may-attach">
      Вы можете приложить видео .mp4, изображения в формате .jpg, .png, .gif или
      документ в формате .pdf
    </div>
    <div class="feedback-container__action" *ngIf="(xsmall$ | async) === false">
      <button
        class="ui-button ui-black"
        [ngClass]="{ 'ui-loading': isLoading }"
        data-cy="Send feedback"
        (click)="send()"
        [disabled]="
          !themeInput ||
          !messageInput ||
          (thereArePendingAttachedFiles$ | async) ||
          isLoading
        "
      >
        <span *ngIf="!isLoading">Отправить</span>
        <div *ngIf="isLoading" class="loader"></div>
      </button>
    </div>
  </div>
</div>
<div class="mm-guide-container" prlwResponsive>
  <a href="https://www.youtube.com/watch?v=kOrEskCFhgI" target="_blank">
    <img
      class="logo-youtube"
      src="assets/logo/social-network/youtube.svg"
      alt="youtube"
    />
    <div class="label">Обучающее видео о Маркет Мониторе</div>
  </a>
  <button
    *ngIf="xsmall$ | async"
    class="ui-button ui-black"
    [ngClass]="{ 'ui-loading': isLoading }"
    data-cy="Send feedback"
    (click)="send()"
    [disabled]="
      !themeInput ||
      !messageInput ||
      (thereArePendingAttachedFiles$ | async) ||
      isLoading
    "
  >
    <span *ngIf="!isLoading">Отправить</span>
    <div *ngIf="isLoading" class="loader"></div>
  </button>
</div>
