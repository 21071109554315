import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { Contract } from '@prlw/core/contracts/contracts.entity';
import { ContractsFilterQuery } from '@prlw/core/contracts/contracts-filter.provider';

export const CONTRACTS_GATEWAY = new InjectionToken<ContractsGateway>(
  'contracts-gateway',
);

export interface ContractsGateway {
  getContracts(filters: ContractsFilterQuery): Observable<Contract[]>;
}
