<ng-container *ngIf="(profile$ | async) as profile; else preloader">
  <ng-container *ngIf="(summaryInfo$ | async) as summaryInfo; else preloader">
    <prlw-profile-summary-ui
      [profile]="profile"
      [summaryInfo]="summaryInfo"
    ></prlw-profile-summary-ui>
  </ng-container>
</ng-container>
<ng-template #preloader>
  <div class="preloader">
    <p>Что-то пошло не так.</p>
    <p>Перезагрузите страницу.</p>
  </div>
</ng-template>
