import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileController } from '@prlw/core/profile/profile.controller';
import { Profile } from '@prlw/core/profile/profile.entity';

@Component({
  selector: 'prlw-demo-access-page',
  templateUrl: './demo-access.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoAccessContainer {
  public readonly profile$: Observable<Profile>;
  @Output() public readonly closeModal = new EventEmitter<void>();

  constructor(private readonly profileController: ProfileController) {
    this.profile$ = profileController.profile$;
  }

  public exit(): void {
    this.closeModal.emit();
  }
}
