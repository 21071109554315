import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const CALENDAR_GATEWAY = new InjectionToken<CalendarGateway>(
  'calendar-gateway',
);

export interface CalendarGateway {
  getHolidaysForPeriod(params: {
    startDate: string;
    endDate: string;
  }): Observable<string[]>;
}
