import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  RecaptchaModule,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
} from 'ng-recaptcha';

import { environment } from '../../environments/environment';

const RECAPTCHA_KEY = environment.recaptchaKey;

@NgModule({
  declarations: [],
  imports: [CommonModule, RecaptchaModule, RecaptchaV3Module],
  providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: RECAPTCHA_KEY }],
})
export class RecaptchaServiceModule {}
