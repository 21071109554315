<div class="container">
  <div class="title">Файл-подтверждение</div>
  <div class="files">
    <div class="file-item" *ngFor="let file of files$ | async; let i = index">
      <div class="file-wrapper">
        <div class="icon" [class.wrong-file]="file.error"></div>
        <div class="file">
          <div class="file-text" [class.wrong-file]="file.error">
            {{ file.file.name }}
          </div>
          <div class="progress" *ngIf="!file.error">
            <prlw-file-upload-progress [progress]="file.progress">
            </prlw-file-upload-progress>
          </div>
          <div class="progress error-text" *ngIf="file.error">
            {{ file.error }}
          </div>
        </div>
      </div>
      <div class="delete-file">
        <div class="remove-icon" (click)="onDelete(i)"></div>
      </div>
      <div class="line"></div>
    </div>
  </div>
  <div class="wrapper footer">
    <div class="title">Комментарий</div>
    <div class="input-wrapper">
      <textarea
        [(ngModel)]="commentaries"
        class="ui-input"
        required
        minlength="1"
        rows="14"
        placeholder="Текст комментария, если необходимо"
      ></textarea>
    </div>
    <div class="upload-status">
      <prlw-file-upload-status
        *ngIf="status$ | async as status"
        [status]="status"
      ></prlw-file-upload-status>
    </div>
    <div class="control-wrapper">
      <input
        multiple
        (change)="onFileBrowser($event)"
        #inputElement
        type="file"
      />
      <button class="upload" (click)="onAddFile()">Загрузить еще</button>
      <button (click)="onSendFile()" class="submit">Отправить</button>
    </div>
  </div>
</div>
