import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { Product } from '@prlw/core/deals/entities/product.entity';
import { Basis } from '@prlw/core/deals/entities/basis.entity';
import { MinAndMaxData } from '@prlw/core/deals/entities/min-and-max-data.entity';

export const DEALS_FILTER_GATEWAY = new InjectionToken<DealsFilterGateway>(
  'deals-filter-gateway',
);

export interface DealsFilterGateway {
  getProducts(): Observable<Product[]>;
  getBases(): Observable<Basis[]>;
  getMaxAndMinAmount(): Observable<MinAndMaxData>;
  getMaxAndMinPrice(): Observable<MinAndMaxData>;
}
