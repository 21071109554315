import { Injectable } from '@angular/core';
import { Icon } from '@prlw/libs/icons/icon.type';
import { IconLoader } from '@prlw/libs/icons/icon-loader';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IconRegistry {
  private readonly _cache = new Map<string, Observable<Icon>>();

  constructor(private readonly iconLoader: IconLoader) {}

  public getIcon(name: string): Observable<Icon> {
    const cachedResponse$ = this._cache.get(name);
    if (cachedResponse$) {
      return cachedResponse$;
    }

    const response$ = this.iconLoader.loadIcon(name).pipe(
      map((svg) => ({
        name,
        svg,
      })),
      shareReplay(1),
    );
    this._cache.set(name, response$);
    return response$;
  }
}
