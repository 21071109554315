<svg
  width="50px"
  height="50px"
  xmlns="http://www.w3.org/2000/svg"
  class="circle"
  prlwResponsive
>
  <circle
    [attr.stroke-dashoffset]="calculateRestDemoAccess"
    [class.alert]="accessPeriod.isAlertActive"
    stroke-dasharray="107"
    r="17"
    cy="25"
    cx="25"
    stroke-width="3"
    fill="none"
  />
</svg>
