export enum FileUploadSteps {
  Preload = 'Preload',
  Uploaded = 'Uploaded',
}

export enum FileUploadError {
  SIZE = 'Превышает допустимый размер файла',
  TYPE = 'Неверный формат файла',
}

export enum FileType {
  PDF = 'pdf',
  IMAGE = 'image',
}

export enum FileSize {
  sizeMin = 1,
  sizeMax = 10000000,
}

export type DocumentInfo = {
  title: string;
  documentId: string | null;
};

export type FileMessage = boolean | string;

export type FileUpload = {
  file: File;
  progress: number;
  error: FileMessage;
};
