import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { LayoutMinimalComponent } from './minimal/layout-minimal.component';
import { LayoutPrimaryComponent } from './primary/layout-primary.component';
import { HeaderModule } from '@prlw/common/header/header.module';
import { NavbarModule } from '@prlw/common/navbar/navbar.module';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';

@NgModule({
  declarations: [
    LayoutComponent,
    LayoutMinimalComponent,
    LayoutPrimaryComponent,
  ],
  imports: [CommonModule, HeaderModule, NavbarModule, ResponsiveDirective],
  exports: [LayoutComponent],
})
export class LayoutModule {}
