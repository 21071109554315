import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Shipment } from '@prlw/core/shipments/shipment.entity';
import { ProductGroup } from '../products/product-group.entity';

export const SHIPMENTS_GATEWAY = new InjectionToken<ShipmentsGateway>(
  'shipments-gateway',
);

export interface ShipmentsGateway {
  getShipmentsByProductGroupsList(
    products: ProductGroup[],
  ): Observable<Shipment[]>;
}
