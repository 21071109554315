import { InjectionToken } from '@angular/core';
import { JwtTokenSet } from '@prlw/core/auth/jwt/jwt.gateway';
import { Observable } from 'rxjs';
import { PrlwAuthError } from '@prlw/infrastructure/errors/error-types/error-types';

export const SIGN_IN_BY_LOGIN_GATEWAY =
  new InjectionToken<SignInByLoginGateway>('sign-in-by-login-gateway');

export type SignInByLoginCredentials = {
  login: string;
  password: string;
};

export type SignInByLoginResultWithTokens =
  | { success: true; tokens: JwtTokenSet }
  | { success: false; error: PrlwAuthError };

export interface SignInByLoginGateway {
  signIn(
    credentials: SignInByLoginCredentials,
  ): Observable<SignInByLoginResultWithTokens>;
}
