import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  mergeMap,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { map, scan, shareReplay, takeUntil, tap } from 'rxjs/operators';
import {
  DocumentInfo,
  FileUpload,
} from '@prlw/core/profile/file-upload.entity';
import {
  mapFileToEntity,
  removeFile,
} from '../../app/features/profile/license-tab/file-upload-overlay/file-upload/file-upload.utils';

const BUFFER_SIZE = 1;

@Injectable({
  providedIn: 'root',
})
export class FileUploadController implements OnDestroy {
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly _files$: BehaviorSubject<File[]>;
  private readonly _fileToDelete$: BehaviorSubject<number | null>;
  private readonly _comment$: BehaviorSubject<string | null>;
  private readonly _document$: ReplaySubject<DocumentInfo>;
  private readonly _status$: BehaviorSubject<null | string>;

  constructor() {
    this._files$ = new BehaviorSubject<File[]>([]);
    this._comment$ = new BehaviorSubject<string | null>(null);
    this._document$ = new ReplaySubject<DocumentInfo>(BUFFER_SIZE);
    this._fileToDelete$ = new BehaviorSubject<number | null>(null);
    this._status$ = new BehaviorSubject<string | null>(null);
  }

  public get files$(): Observable<FileUpload[]> {
    return combineLatest([this.updateFiles$]).pipe(
      map(([files]) => files),
      takeUntil(this._destroy$),
    );
  }

  public get updateFiles$(): Observable<FileUpload[]> {
    return this.filesWithProgress$.pipe(
      tap(() => this._fileToDelete$.next(null)),
      scan((acc, current) => [...acc, ...current]),
      mergeMap((files) =>
        this._fileToDelete$.pipe(
          map((fileIndex) => removeFile(fileIndex, files)),
        ),
      ),
      shareReplay(BUFFER_SIZE),
      takeUntil(this._destroy$),
    );
  }

  public get filesWithProgress$(): Observable<FileUpload[]> {
    return this._files$.pipe(
      map((files) => mapFileToEntity(files)),
      takeUntil(this._destroy$),
    );
  }

  public get sendToServerStatus$(): Observable<string | null> {
    return this._status$.asObservable();
  }

  public selectFileToDelete(index: number | null): void {
    this._fileToDelete$.next(index);
  }

  public addFiles(files: File[]): void {
    this._status$.next(null);
    this._files$.next(files);
  }

  public setComment(comment: string): void {
    this._comment$.next(comment);
  }

  public setDocument(document: DocumentInfo): void {
    this._document$.next(document);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public sendDocument(): void {}

  public ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
