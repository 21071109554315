<div class="action-list">
  <div (click)="expand()" [class.rotate]="isExpand" class="arrow">&#8249;</div>
  <h2>
    Выполните все пункты и получите
    <span class="bold">PROLEUM Pro на 30 дней в подарок</span>
  </h2>

  <ul *ngIf="isExpand" class="padding">
    <li [class.confirmed]="profileCheckList?.isFIOConfirm">
      {{ profileCheckList?.isFIOConfirm ? '&#10003;' : '-' }} Добавить ФИО
    </li>
    <li [class.confirmed]="profileCheckList?.isEmailConfirm">
      {{ profileCheckList?.isEmailConfirm ? '&#10003;' : '-' }} Подтвердить
      E-mail
    </li>
    <li [class.confirmed]="profileCheckList?.isOrganizationConfirm">
      {{ profileCheckList?.isOrganizationConfirm ? '&#10003;' : '-' }} Добавить
      организацию
    </li>
    <li [class.confirmed]="profileCheckList?.isOrderSign">
      {{ profileCheckList?.isOrderSign ? '&#10003;' : '-' }} Скачать и подписать
      регламент. <span class="link">Узнать что это</span>
    </li>
    <li [class.confirmed]="profileCheckList?.isOrderSent">
      {{ profileCheckList?.isOrderSent ? '&#10003;' : '-' }} Отправить
      подписанный регламент по ЭДО или электронной почте.
      <span class="link">Инструкция</span>
    </li>
  </ul>
</div>
