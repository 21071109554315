import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, of } from 'rxjs';
import {
  debounceTime,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {
  Destination,
  DestinationEntity,
  DestinationStatus,
  ExpandedView,
} from '@prlw/core/destinations/destination.entity';
import {
  DESTINATIONS_GATEWAY,
  DestinationsGateway,
} from '@prlw/core/destinations/destinations.gateway';
import { DestinationState } from '@prlw/core/destinations/destination.state';

const QUERY_DEBOUNCE_TIME = 500;

@Injectable({
  providedIn: 'root',
})
export class DestinationsProvider implements OnDestroy {
  private readonly _cache = new Map<string, DestinationEntity[]>();
  private readonly _query$ = new BehaviorSubject<string>('');
  private readonly _destinations$ = new BehaviorSubject<DestinationEntity[]>(
    [],
  );
  private readonly _destinationsWithoutUserDestinations$ = new BehaviorSubject<
    DestinationEntity[]
  >([]);
  private readonly _isLoading$ = new BehaviorSubject<boolean>(true);
  private readonly _expandedView$ = new BehaviorSubject<ExpandedView>({
    isExpandedTop: true,
    isExpandedBottom: true,
  });
  private readonly _destroy$ = new Subject<void>();

  constructor(
    @Inject(DESTINATIONS_GATEWAY) private readonly gateway: DestinationsGateway,
    private readonly destinationState: DestinationState,
  ) {
    this._query$
      .asObservable()
      .pipe(
        debounceTime(QUERY_DEBOUNCE_TIME),
        switchMap((query) => {
          const cachedResult = this._cache.get(query);
          const result$ = cachedResult
            ? of(cachedResult)
            : gateway.findDestinations(query);
          return combineLatest([
            result$,
            of(query),
            this.destinationState.destinations$,
          ]);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe(([foundDestinations, query, userDestinations]) => {
        this._destinations$.next(foundDestinations);
        const destinationsWithoutUserDestinations = foundDestinations.filter(
          (foundDestination: DestinationEntity) =>
            !userDestinations.some(
              ({ destination }) =>
                foundDestination.destination.name === destination.name,
            ),
        );
        this._destinationsWithoutUserDestinations$.next(
          destinationsWithoutUserDestinations,
        );
        this._cache.set(query, foundDestinations);
        this._isLoading$.next(false);
      });
  }

  public get expandedView$(): Observable<ExpandedView> {
    return this._expandedView$.asObservable();
  }

  public changeExpandedView(expandedView: ExpandedView): void {
    this._expandedView$.next(expandedView);
  }

  public get currentDestination$(): Observable<Destination | null> {
    return this.destinationState.currentDestination$;
  }

  public get destinations$(): Observable<DestinationEntity[]> {
    return this._destinations$.asObservable();
  }

  public get destinationsWithoutUserDestinations$(): Observable<
    DestinationEntity[]
  > {
    return this._destinationsWithoutUserDestinations$.asObservable();
  }

  public get isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  public searchDestinations(query: string): void {
    this._isLoading$.next(true);
    this._query$.next(query);
  }

  public setDestination(
    destination: DestinationEntity,
    status: DestinationStatus,
  ): void {
    this.destinationState.setDestination(destination, status);
  }

  public userDestinations$(
    status: DestinationStatus,
  ): Observable<DestinationEntity[]> {
    return this.destinationState.destinations$.pipe(
      mergeMap((destinations) =>
        this._query$.pipe(
          map((query) =>
            destinations.filter(
              (destination) =>
                destination.flags[status] &&
                destination.destination.name
                  .toLowerCase()
                  .includes(query.toLowerCase()),
            ),
          ),
        ),
      ),
    );
  }

  public get mainPanelDestinations$(): Observable<DestinationEntity[]> {
    return this.destinationState.destinations$.pipe(
      map((destinations) => destinations.filter((i) => i.flags.isInWork)),
    );
  }

  public get isUserDestinations$(): Observable<boolean> {
    return this.destinationState.destinations$.pipe(
      map((destinations) => Boolean(destinations.length)),
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
