import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonPaneComponent } from '@prlw/common/common-pane/common-pane.component';
import { Profile } from '@prlw/core/profile/profile.entity';
import { IconsModule } from '@prlw/libs/icons/icons.module';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';
import { ResponsiveService } from '@prlw/libs/responsive/responsive.service';
import { DemoAccessModule } from 'src/app/features/demo-access/demo-access.module';

@Component({
  selector: 'prlw-title-profile-ui',
  standalone: true,
  imports: [
    CommonModule,
    CommonPaneComponent,
    IconsModule,
    DemoAccessModule,
    ResponsiveDirective,
  ],
  templateUrl: './title-profile.component.html',
  styleUrl: './title-profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleProfileComponent {
  @Input() profile: Profile | null = null;

  @Output() eventProfileButtonClick = new EventEmitter<void>();

  constructor(private responsiveService: ResponsiveService) {}

  xsmall$ = this.responsiveService.xsmall$;
}
