<div class="data-item" *ngIf="displayName">
  <div class="label">Название компании</div>
  <div class="value org-name">{{ organization.name }}</div>
</div>
<div class="data-item">
  <div class="label">Юридический адрес</div>
  <div class="value legal-address">
    {{ organization.legalAddress || '—' }}
  </div>
</div>
<div class="data-item">
  <div class="label">Фактический адрес</div>
  <div class="value actual-address">
    {{ organization.actualAddress || '—' }}
  </div>
</div>
<div class="data-item">
  <div class="label">Руководитель организации</div>
  <div class="value head-of-org">
    {{ organization.headOfOrganization || '—' }}
  </div>
</div>
<div class="data-item">
  <div class="label">Регистрационные данные</div>
  <div class="value">
    <div class="data-subitem">
      <div class="label">ИНН</div>
      <div class="value inn">{{ organization.inn }}</div>
    </div>
    <div class="data-subitem">
      <div class="label">КПП</div>
      <div class="value kpp">{{ organization.kpp }}</div>
    </div>
    <div class="data-subitem">
      <div class="label">ОКПО</div>
      <div class="value okpo">{{ organization.okpo }}</div>
    </div>
    <div class="data-subitem">
      <div class="label">ОГРН</div>
      <div class="value ogrn">{{ organization.ogrn || '—' }}</div>
    </div>
  </div>
</div>
<div class="data-item">
  <div class="label">Контактная информация</div>
  <div class="value">
    <div class="data-subitem">
      <div class="label">Телефон</div>
      <div class="value phone">{{ organization.phone || '—' }}</div>
    </div>
    <div class="data-subitem">
      <div class="label">E-mail</div>
      <div class="value email">{{ organization.email || '—' }}</div>
    </div>
  </div>
</div>
