<div class="container" *ngIf="accessToPro">
  <div class="card">
    <div class="overlay"></div>
    <div class="left-side">
      <div class="wrapper">
        <div class="title">PROLEUM Pro</div>
        <div class="text-wrapper">
          <span class="bold">Полные возможности</span>
          <span class="date">до {{ accessToPro.accessInDate }}</span>
        </div>
      </div>
    </div>
    <div class="right-side">
      <div class="wrapper">
        <div class="days">
          <div class="day">Осталось</div>
          <div class="day day-count bold">{{ accessToPro.accessInDays }}</div>
          <div class="day bold">{{ pluralize(accessToPro.accessInDays) }}</div>
        </div>
      </div>
      <div class="wrapper">
        <button class="btn">Продлить</button>
      </div>
    </div>
  </div>
</div>
