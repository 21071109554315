import { CommonModule, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MobileButtonComponent } from '../mobile-button/mobile-button.component';
import { SwipeDirective } from '@prlw/libs/swipe/swipe.directive';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import { OverflowFaderDirective } from '@prlw/libs/overflow-fader/overflow-fader.directive';
import { ScrollAnchorTargetDirective } from '@prlw/libs/scroll-anchor/scroll-anchor-target.directive';

@Component({
  selector: 'prlw-mobile-overlay-card-ui',
  standalone: true,
  imports: [
    CommonModule,
    MobileButtonComponent,
    SwipeDirective,
    OverflowFaderDirective,
    ScrollAnchorTargetDirective,
  ],
  templateUrl: './mobile-overlay-card.component.html',
  styleUrl: './mobile-overlay-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileOverlayCardComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() label: string | null = null;
  @Input() greyLabel: string | null = null;
  @Input() showDoneButton = false;
  @Input() showXButton = true;
  @Input() buttonLabel = 'Готово';
  @Input() maxHeight = 73;

  @Output() eventClickX = new EventEmitter();
  @Output() eventClickMobileButton = new EventEmitter();

  eventTouchmove = new EventEmitter<number>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly renderer: Renderer2,
  ) {
    this.eventTouchmove.pipe(takeUntil(this.destroy$)).subscribe((clientY) => {
      if (this.initHeight && this.initTop && this.container) {
        const diff = this.initTop + this.initHeight - clientY;
        if (diff > 0) return;
        this.container.nativeElement.style.bottom = `${diff.toString()}px`;
      }
    });
  }

  ngOnInit(): void {
    this.renderer.addClass(
      this.document.querySelector(':root'),
      'overscroll-behavior-none',
    );
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.renderer.removeClass(
      this.document.querySelector(':root'),
      'overscroll-behavior-none',
    );
  }

  @ViewChild('container') container: ElementRef | null = null;
  handleTouchend() {
    if (this.container) {
      this.container.nativeElement.style.bottom = '0px';
    }
  }

  initTop: number | null = null;
  initHeight: number | null = null;
  @ViewChild('plugContainer') set setEl(el: ElementRef) {
    setTimeout(() => {
      const { height, top } = el.nativeElement.getBoundingClientRect();
      this.initTop = top;
      this.initHeight = height;
    }, 0);
  }

  @ViewChild('overflowContainer') set setOverflowContainer(
    el: ElementRef<Element>,
  ) {
    setTimeout(() => {
      fromEvent(el.nativeElement, 'scroll')
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          const { scrollTop } = <HTMLElement>res.target;
          if (scrollTop > 0) {
            if (this.container) {
              this.container.nativeElement.style.maxHeight = `86vh`;
            }
          }
        });
    }, 0);
  }

  ngAfterViewInit(): void {
    if (this.container) {
      this.container.nativeElement.style.maxHeight = `${this.maxHeight}vh`;
    }
  }
}
