import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'prlw-mobile-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mobile-button.component.html',
  styleUrl: './mobile-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileButtonComponent {
  @Input() label: string | null = 'foobar';
  @Input() greyLabel: string | null = null;
  @Input() color: 'green' | 'grey' | 'black' | 'white' = 'black';
  @Input() border = false;
  @Input() disabled = false;

  @Output() eventClick = new EventEmitter();

  getColor() {
    return this.color;
  }
}
