import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProductGroup } from '@prlw/core/products/product-group.entity';
import { ProductGroupsGateway } from '@prlw/core/products/product-groups.gateway';
import { GetAllProductsResponse as GetProductGroupsResponse } from '@prlw/data/products/api-types/get-all-products.api';
import {
  ProductsDataConfig,
  PRODUCTS_DATA_CONFIG,
} from '@prlw/data/products/products-data.config';
import { Observable } from 'rxjs';

@Injectable()
export class HttpProductGroupsGateway implements ProductGroupsGateway {
  constructor(
    @Inject(PRODUCTS_DATA_CONFIG) private readonly config: ProductsDataConfig,
    private readonly http: HttpClient,
  ) {}

  public getProductGroups(): Observable<ProductGroup[]> {
    return this.http.get<GetProductGroupsResponse>(
      `${this.config.apiPrefix}-group`,
    );
  }
}
