import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TitleProfileComponent } from './title-profile.component';
import { ProfileController } from '@prlw/core/profile/profile.controller';
import { AuthStateProvider } from '@prlw/core/auth/auth-state.provider';
import { Router } from '@angular/router';
import { first, map } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'prlw-title-profile',
  standalone: true,
  imports: [CommonModule, TitleProfileComponent],
  templateUrl: './title-profile.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleProfileContainer {
  constructor(
    private profileController: ProfileController,
    private authStateProvider: AuthStateProvider,
    private readonly router: Router,
  ) {}

  profile$ = this.profileController.profile$;

  isAuthorized$ = this.authStateProvider.authLevel$.pipe(
    map((level) => level.isDemo),
  );

  public redirectToSignInIfNotAuthorized(): void {
    this.isAuthorized$.pipe(first()).subscribe((isAuthorized) => {
      if (!isAuthorized) {
        this.router.navigate(['sign-in']);
        return;
      }
      this.router.navigate(['profile']);
    });
  }
}
