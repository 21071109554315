import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FeedbackGateway } from '@prlw/core/feedback/feedback-gateway.interface';
import { SendFeedbackResponse } from '@prlw/data/feedback/api-types/send.api';
import { FEEDBACK_DATA_CONFIG } from '@prlw/data/feedback/feedback-data-config.token';
import { FeedbackDataConfig } from '@prlw/data/feedback/feedback-data.module';
import { Feedback } from '@prlw/core/feedback/feedback.entity';
import { IUploadResult } from '@prlw/core/feedback/upload-result.entity';
import { TFileUploadResponse } from './api-types/file-upload.api';

@Injectable({
  providedIn: 'root',
})
export class HttpFeedbackGateway implements FeedbackGateway {
  constructor(
    @Inject(FEEDBACK_DATA_CONFIG) private readonly config: FeedbackDataConfig,
    private readonly http: HttpClient,
  ) {}

  public sendFeedback(data: Feedback): Observable<SendFeedbackResponse> {
    return this.http
      .post<SendFeedbackResponse>(this.config.apiPrefix, data)
      .pipe(
        map(() => ({ success: true, error: null })),
        catchError((errorResponse: HttpErrorResponse) =>
          of({
            success: false,
            error: errorResponse.error.message,
          }),
        ),
      );
  }

  public fileUpload(formData: FormData): Observable<TFileUploadResponse> {
    const uploadFileHeaders = new HttpHeaders({
      Accept: `application/json, text/plain, */*`,
    });
    const ONE_HUNDREED = 100;
    return this.http
      .post<IUploadResult>(`${this.config.apiPrefix}/file_upload`, formData, {
        observe: 'events',
        reportProgress: true,
        headers: uploadFileHeaders,
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              return {
                done: false,
                pending: true,
                progress: event.total
                  ? Math.round((event.loaded / event.total) * ONE_HUNDREED)
                  : 0,
              };
            case HttpEventType.Response:
              return {
                done: true,
                pending: false,
                data: event.body as IUploadResult,
              };
            default:
              return {
                done: false,
                pending: true,
              };
          }
        }),
        catchError((errorResponse: HttpErrorResponse) =>
          of({
            done: true,
            pending: false,
            error: errorResponse.error.message,
          }),
        ),
      );
  }
}
