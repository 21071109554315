import { Injectable } from '@angular/core';
import { PageContextService } from './page-context.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PagePreloaderService {
  constructor(private readonly pageContextService: PageContextService) {}

  loading$ = this.pageContextService.pageContext$;

  loaded$ = new Subject<void>();
  emit() {
    this.loaded$.next();
  }
}
