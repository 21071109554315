import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'prlw-market-time-tooltip',
  templateUrl: './market-time-tooltip.component.html',
  styleUrls: ['./market-time-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketTimeTooltipComponent {
  @Input() text: string | null = null;
}
