import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  mergeMap,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { TProductPickerItem } from './types/product-picker-item';
import { TDynamicsOfVolumesItem } from './types/dynamics-of-volumes-item';
import {
  TObservableWithPending,
  observableWithPending,
  mapResult,
  asOwpSuccess,
  mapPending,
  mapError,
} from '@prlw/infrastructure/observableWithPending-v2';
import { AnalyticsChartsController } from './analytics-charts.controller';
import { TCargoFlowItem } from './types/cargo-flow-item';
import { TPeriodItem } from './types/period-item';
import { Setting } from '../user-settings/setting.interface';
import { UserSettingsController } from '../user-settings/user-settings.controller';
import { getEndDate, getStartDate } from '@prlw/libs/date-periods';
import { MarketTimeController } from '../market-time/market-time.controller';

const ANALYTICS_PRODUCT_PICKER_ITEMS_SETTING = new Setting<
  TProductPickerItem[]
>('analytics-product-picker-items');

@Injectable({
  providedIn: 'root',
})
export class AnalyticsChartsProvider implements OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  getProductPickerItems$ = new BehaviorSubject<
    TObservableWithPending<TProductPickerItem[]>
  >(asOwpSuccess([]));
  productPickerItems$ = mapResult(this.getProductPickerItems$);
  productPickerItemsPending$ = mapPending(this.getProductPickerItems$);
  productPickerItemsError$ = mapError(this.getProductPickerItems$);
  productPickerItemsBriefString$ = this.productPickerItems$.pipe(
    map(
      (productPickerItems) =>
        productPickerItems
          .map((productPickerItem) => {
            const res = productPickerItem.rows.filter((item) => item.checked);
            if (res.length)
              return `${productPickerItem.title}: ${res
                .map((item) => item.name)
                .join(', ')}`;
            return null;
          })
          .filter((item) => !!item)
          .join('; ') || 'Выбрать продукты',
    ),
  );

  getProductPickerItemsSingle$ = new BehaviorSubject<
    TObservableWithPending<TProductPickerItem[]>
  >(asOwpSuccess([]));
  productPickerItemsSingle$ = mapResult(this.getProductPickerItemsSingle$);

  getProductPickerCargoItems$ = new BehaviorSubject<
    TObservableWithPending<TProductPickerItem[]>
  >(asOwpSuccess([]));
  productPickerCargoItems$ = mapResult(this.getProductPickerCargoItems$);

  getDynamicsOfVolumesTwoWeeksItems$ = new BehaviorSubject<
    TObservableWithPending<TDynamicsOfVolumesItem[]>
  >(asOwpSuccess([]));
  dynamicsOfVolumesTwoWeeksItems$ = mapResult(
    this.getDynamicsOfVolumesTwoWeeksItems$,
  );
  dynamicsOfVolumesTwoWeeksItemsPending$ = mapPending(
    this.getDynamicsOfVolumesTwoWeeksItems$,
  );

  dynamicsByPlantsSessions$ = new BehaviorSubject({
    primary: true,
    secondary: true,
  });
  updateDynamicsByPlantsSessions(primary: boolean, secondary: boolean) {
    this.dynamicsByPlantsSessions$.next({
      primary,
      secondary,
    });
  }
  dynamicsByPlantsDeliveryMethods$ = new BehaviorSubject({
    f: true,
    w: true,
    u: true,
  });
  updateDynamicsByPlantsDeliveryMethods(f: boolean, w: boolean, u: boolean) {
    this.dynamicsByPlantsDeliveryMethods$.next({
      f,
      w,
      u,
    });
  }

  getCargoFlowItems$ = new BehaviorSubject<
    TObservableWithPending<TCargoFlowItem[]>
  >(asOwpSuccess([]));
  cargoFlowItems$ = mapResult(this.getCargoFlowItems$);
  cargoFlowItemsPending$ = mapPending(this.getCargoFlowItems$);

  updateProductPickerItems$ = new Subject<TProductPickerItem>();
  updateProductPickerItemsSingle$ = new Subject<TProductPickerItem>();
  updateProductPickerCargoItems$ = new Subject<TProductPickerItem>();

  getStartDate(date: Date) {
    return getStartDate(date);
  }

  getEndDate(date: Date) {
    return getEndDate(date);
  }

  periodItems: TPeriodItem[] = [
    {
      label: '1 мес',
      startDate: this.getStartDate(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 1),
      ),
      endDate: this.getEndDate(new Date(new Date().getTime())),
    },
    {
      label: '3 мес',
      startDate: this.getStartDate(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 3),
      ),
      endDate: this.getEndDate(new Date(new Date().getTime())),
    },
    {
      label: '6 мес',
      startDate: this.getStartDate(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 6),
      ),
      endDate: this.getEndDate(new Date(new Date().getTime())),
    },
    {
      label: '1 год',
      startDate: this.getStartDate(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 12),
      ),
      endDate: this.getEndDate(new Date(new Date().getTime())),
    },
    {
      label: '1 нед',
      startDate: this.getStartDate(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
      ),
      endDate: this.getEndDate(new Date(new Date().getTime())),
    },
    ...[...Array(3)].map((_, index) => {
      const year = new Date().getFullYear() - 2 + index;
      return <TPeriodItem>{
        label: String(year),
        startDate: `${year}-01-01T00:00:00.000Z`,
        endDate: `${year}-12-31T23:59:59.000Z`,
      };
    }),
  ];

  customPeriodItemSub$ = new Subject<TPeriodItem>();
  customPeriodItem$ = merge(
    this.customPeriodItemSub$,
    this.marketTimeController.lastTradingSessionDate$.pipe(
      map(
        (lastTradingSessionDate) =>
          ({
            label: 'свой период',
            startDate: this.getStartDate(
              new Date(
                new Date(lastTradingSessionDate).getTime() -
                  1000 * 60 * 60 * 24 * 30 * 1,
              ),
            ),
            endDate: this.getEndDate(new Date(lastTradingSessionDate)),
          }) as TPeriodItem,
      ),
    ),
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
  updateCustomPeriodItem(item: TPeriodItem) {
    this.customPeriodItemSub$.next(item);
  }

  getLabelByDate = (date: Date) =>
    new Intl.DateTimeFormat('ru', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(date);
  customDateItemSub$ = new Subject<TPeriodItem>();
  customDateItem$ = merge(
    this.customDateItemSub$,
    this.marketTimeController.lastTradingSessionDate$.pipe(
      map(
        (lastTradingSessionDate) =>
          ({
            label: this.getLabelByDate(new Date(lastTradingSessionDate)),
            startDate: this.getStartDate(new Date(lastTradingSessionDate)),
            endDate: this.getEndDate(new Date(lastTradingSessionDate)),
          }) as TPeriodItem,
      ),
    ),
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
  updateCustomDateItem(item: TPeriodItem) {
    this.customDateItemSub$.next(item);
  }

  dynamicsOfVolumesPeriod$ = new BehaviorSubject<TPeriodItem>(
    this.periodItems[7],
  );
  priceComparisonPeriod$ = new BehaviorSubject<TPeriodItem>(
    this.periodItems[0],
  );

  updateDynamicsOfVolumesPeriod(item: TPeriodItem) {
    this.dynamicsOfVolumesPeriod$.next(item);
  }
  updatePriceComparisonPeriod(item: TPeriodItem) {
    this.priceComparisonPeriod$.next(item);
  }

  productGroupNames$ = this.productPickerItems$.pipe(
    debounceTime(500),
    map((items) =>
      items.reduce((acc, { rows }) => {
        return [
          ...acc,
          ...rows.filter((row) => row.checked).map((row) => row.name),
        ];
      }, [] as string[]),
    ),
    filter((names) => !!names.length),
  );

  productGroupNamesSingle$ = this.productPickerItemsSingle$.pipe(
    debounceTime(500),
    map((items) =>
      items.reduce((acc, { rows }) => {
        return [
          ...acc,
          ...rows.filter((row) => row.checked).map((row) => row.name),
        ];
      }, [] as string[]),
    ),
    filter((names) => !!names.length),
  );

  productCargoGroupNames$ = this.productPickerCargoItems$.pipe(
    debounceTime(500),
    map((items) =>
      items.reduce((acc, { rows }) => {
        return [
          ...acc,
          ...rows.filter((row) => row.checked).map((row) => row.name),
        ];
      }, [] as string[]),
    ),
    filter((names) => !!names.length),
  );

  getDynamicsByPlantsItems$ = combineLatest([
    this.productGroupNames$,
    this.dynamicsByPlantsSessions$.pipe(
      debounceTime(500),
      distinctUntilChanged(
        ({ primary, secondary }, next) =>
          primary === next.primary && secondary === next.secondary,
      ),
    ),
    this.customDateItem$.pipe(
      debounceTime(500),
      distinctUntilChanged(
        ({ startDate, endDate }, next) =>
          startDate === next.startDate && endDate === next.endDate,
      ),
    ),
    this.dynamicsByPlantsDeliveryMethods$.pipe(
      debounceTime(500),
      distinctUntilChanged(
        ({ f, w, u }, next) => f === next.f && w === next.w && u === next.u,
      ),
    ),
  ]).pipe(
    switchMap(
      ([names, { primary, secondary }, { startDate, endDate }, { f, w, u }]) =>
        observableWithPending(
          this.controller.getDynamicsByPlantsItems(
            names,
            startDate,
            endDate,
            primary,
            secondary,
            f,
            w,
            u,
          ),
        ),
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  dynamicsByBidsSessions$ = new BehaviorSubject({
    primary: true,
    secondary: true,
  });

  updateDynamicsByBidsSessions(primary: boolean, secondary: boolean) {
    this.dynamicsByBidsSessions$.next({
      primary,
      secondary,
    });
  }
  dynamicsByBidsDeliveryMethods$ = new BehaviorSubject({
    f: true,
    w: true,
    u: true,
  });
  updateDynamicsByBidsDeliveryMethods(f: boolean, w: boolean, u: boolean) {
    this.dynamicsByBidsDeliveryMethods$.next({
      f,
      w,
      u,
    });
  }

  getDynamicsByBidsItems$ = combineLatest([
    this.productGroupNames$,
    this.customDateItem$.pipe(
      debounceTime(500),
      distinctUntilChanged(
        ({ startDate, endDate }, next) =>
          startDate === next.startDate && endDate === next.endDate,
      ),
    ),
    this.dynamicsByBidsSessions$.pipe(
      debounceTime(500),
      distinctUntilChanged(
        ({ primary, secondary }, next) =>
          primary === next.primary && secondary === next.secondary,
      ),
    ),
    this.dynamicsByBidsDeliveryMethods$.pipe(
      debounceTime(500),
      distinctUntilChanged(
        ({ f, w, u }, next) => f === next.f && w === next.w && u === next.u,
      ),
    ),
  ]).pipe(
    switchMap(
      ([names, { startDate, endDate }, { primary, secondary }, { f, w, u }]) =>
        observableWithPending(
          this.controller.getDynamicsByBidsItems(
            names,
            startDate,
            endDate,
            primary,
            secondary,
            f,
            w,
            u,
          ),
        ),
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  getPriceComparisonItems$ = combineLatest([
    this.productGroupNames$,
    this.priceComparisonPeriod$.pipe(debounceTime(500)),
  ]).pipe(
    switchMap(([names, { startDate, endDate }]) =>
      observableWithPending(
        this.controller.getPriceComparisonItems(names, startDate, endDate),
      ),
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  getDynamicsOfVolumesItems$ = combineLatest([
    this.productGroupNames$,
    this.dynamicsOfVolumesPeriod$.pipe(
      debounceTime(500),
      distinctUntilChanged(
        ({ startDate, endDate }, next) =>
          startDate === next.startDate && endDate === next.endDate,
      ),
    ),
  ]).pipe(
    switchMap(([names, { startDate, endDate }]) =>
      observableWithPending(
        this.controller.getDynamicsOfVolumesItems(names, startDate, endDate),
      ),
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  constructor(
    private readonly controller: AnalyticsChartsController,
    private readonly userSettingsController: UserSettingsController,
    private readonly marketTimeController: MarketTimeController,
  ) {
    this.productPickerItems$
      .pipe(
        debounceTime(500),
        mergeMap((value) =>
          userSettingsController.updateSetting(
            ANALYTICS_PRODUCT_PICKER_ITEMS_SETTING,
            value,
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe();

    userSettingsController
      .getSetting(ANALYTICS_PRODUCT_PICKER_ITEMS_SETTING)
      .pipe(
        switchMap((setting) =>
          observableWithPending(controller.getProductPickerItems()).pipe(
            map((items) => {
              if ('result' in items) {
                if (setting) {
                  return asOwpSuccess(
                    items.result.map((item) => ({
                      ...item,
                      rows: item.rows.map((row) => ({
                        ...row,
                        icon: `product-${row.name.toLowerCase()}`,
                        checked: !!setting
                          .find((nextItem) => nextItem.title === item.title)
                          ?.rows.find((nextItem) => nextItem.name === row.name)
                          ?.checked,
                      })),
                    })),
                  );
                }
                return asOwpSuccess(
                  items.result.map((item, i) => ({
                    ...item,
                    rows: item.rows.map((row, ii) => ({
                      ...row,
                      icon: `product-${row.name.toLowerCase()}`,
                      checked: i === 0 && ii === 0,
                    })),
                  })),
                );
              }
              return items;
            }),
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe((items) => {
        this.getProductPickerItems$.next(items);
        this.getProductPickerItemsSingle$.next(items);
      });

    observableWithPending(controller.getProductPickerCargoItems())
      .pipe(
        map((items) => {
          if ('result' in items) {
            const nextItems = items.result.slice();
            nextItems[0].rows[0].checked = true;
            return asOwpSuccess(
              nextItems.map((nextItem) => ({
                ...nextItem,
                rows: nextItem.rows.map((row) => ({
                  ...row,
                  icon: `product-reg`,
                })),
              })),
            );
          }
          return items;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe((items) => {
        this.getProductPickerCargoItems$.next(items);
      });

    this.updateProductPickerItems$
      .pipe(
        withLatestFrom(this.productPickerItems$),
        map(([item, items]) => {
          return items.map(({ title, rows }) => ({
            title,
            rows:
              title === item.title
                ? item.rows
                : rows.map((row) => ({ ...row, checked: false })),
          }));
        }),
        takeUntil(this._destroy$),
      )
      .subscribe((nextItems) =>
        this.getProductPickerItems$.next(asOwpSuccess(nextItems)),
      );

    this.updateProductPickerItemsSingle$
      .pipe(
        withLatestFrom(this.productPickerItemsSingle$),
        map(([item, items]) => {
          return items.map(({ title, rows }) => ({
            title,
            rows:
              title === item.title
                ? item.rows
                : rows.map((row) => ({ ...row, checked: false })),
          }));
        }),
        takeUntil(this._destroy$),
      )
      .subscribe((nextItems) =>
        this.getProductPickerItemsSingle$.next(asOwpSuccess(nextItems)),
      );

    this.updateProductPickerCargoItems$
      .pipe(
        withLatestFrom(this.productPickerCargoItems$),
        map(([item, items]) => {
          return items.map(({ title, rows }) => ({
            title,
            rows:
              title === item.title
                ? item.rows
                : rows.map((row) => ({ ...row, checked: false })),
          }));
        }),
        takeUntil(this._destroy$),
      )
      .subscribe((nextItems) =>
        this.getProductPickerCargoItems$.next(asOwpSuccess(nextItems)),
      );

    combineLatest([this.productGroupNames$])
      .pipe(
        switchMap(([names]) =>
          observableWithPending(
            controller.getDynamicsOfVolumesItems(
              names,
              this.getStartDate(
                new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14),
              ),
              this.getEndDate(new Date(new Date().getTime())),
            ),
          ),
        ),
        tap((items) => this.getDynamicsOfVolumesTwoWeeksItems$.next(items)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.productCargoGroupNames$
      .pipe(
        switchMap((names) =>
          observableWithPending(controller.getCargoFlowItems(names)),
        ),
        tap((items) => this.getCargoFlowItems$.next(items)),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
