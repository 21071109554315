import {
  AuthErrorCode,
  AuthErrorDescription,
} from '@prlw/infrastructure/errors/error-codes/client-error-codes';

export class PrlwBaseError extends Error {
  public readonly name: string;

  constructor(name: string, description: string) {
    super(description);
    Object.setPrototypeOf(this, new.target.prototype);

    this.name = name;
  }
}

export class PrlwClientError extends PrlwBaseError {
  public readonly section: string | undefined;

  constructor(
    name: string,
    description = 'В приложении возникла ошибка!',
    section?: string,
  ) {
    super(name, description);

    this.section = section;
  }
}

export class PrlwAuthError extends PrlwClientError {
  public readonly code: string;

  constructor(code: AuthErrorCode) {
    super(AuthErrorDescription[code], 'Ошибка авторизации');

    this.code = code;
  }
}
