import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  filter,
  mergeMap,
  shareReplay,
  skip,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  timer,
} from 'rxjs';
import { FuturesController } from './futures.controller';
import {
  TObservableWithPending,
  observableWithPending,
  asOwpSuccess,
  mapPending,
  mapResult,
  mapError,
} from '@prlw/infrastructure/observableWithPending-v2';
import { TFuturesBestordersBuyPriceLastDailyItem } from './types/futures-bestorders-buy-price-last-daily-item';
import { TFuturesType } from './types/futures-type';
import { TFuturesTariff } from './types/futures-tariff';
import { DestinationsProvider } from '../destinations/destinations.provider';
import { getEndDate, getStartDate } from '@prlw/libs/date-periods';
import { TBasisPlantStationNames } from './types/futures-basis-plant-station-names';
import { ProductGroup } from '../products/product-group.entity';
import { Setting } from '../user-settings/setting.interface';
import { UserSettingsController } from '../user-settings/user-settings.controller';

const FUTURES_PRODUCT_CODE_TYPE_CODE_SETTING = new Setting<string[]>(
  'futures-product-code-type-code',
);

@Injectable({
  providedIn: 'root',
})
export class FuturesService implements OnDestroy {
  private readonly _destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  constructor(
    private readonly controller: FuturesController,
    private readonly destinationsProvider: DestinationsProvider,
    private readonly userSettingsController: UserSettingsController,
  ) {
    combineLatest([
      this.futuresProductGroupsCurrent$.pipe(
        filter((item): item is ProductGroup => item !== null),
      ),
      this.futuresTypeCodesCurrent$.pipe(
        filter((item): item is string => item !== null),
      ),
    ])
      .pipe(
        debounceTime(300),
        mergeMap(([futuresProductGroupsCurrent, futuresTypeCodesCurrent]) =>
          userSettingsController.updateSetting(
            FUTURES_PRODUCT_CODE_TYPE_CODE_SETTING,
            [futuresProductGroupsCurrent.code, futuresTypeCodesCurrent],
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.futuresTypeCodes$
      .pipe(
        mergeMap((codes) =>
          userSettingsController
            .getSetting(FUTURES_PRODUCT_CODE_TYPE_CODE_SETTING)
            .pipe(
              tap((setting) => {
                const futuresProductGroups = this.futuresProductGroups
                  .slice()
                  .sort((a, b) => {
                    const deliverable = codes.filter((code) =>
                      this.futuresProductGroups
                        .map(({ code }) => code)
                        .includes(code.slice(0, 3)),
                    );
                    const getIndex = (str: string) =>
                      deliverable.findIndex((item) => item.includes(str));
                    return getIndex(a.code) - getIndex(b.code);
                  });
                this.futuresProductGroups$.next(futuresProductGroups);

                if (!setting) {
                  this.setFuturesProductGroupsCurrent(futuresProductGroups[0]);
                  this.setFuturesTypeCodesCurrent(codes[0]);
                  return;
                }

                const futuresProductGroupsCurrentCustom =
                  futuresProductGroups.find((item) => item.code === setting[0]);
                if (futuresProductGroupsCurrentCustom) {
                  this.setFuturesProductGroupsCurrent(
                    futuresProductGroupsCurrentCustom,
                  );
                } else {
                  this.setFuturesProductGroupsCurrent(futuresProductGroups[0]);
                }

                if (codes.includes(setting[1])) {
                  this.setFuturesTypeCodesCurrent(setting[1]);
                } else {
                  this.setFuturesTypeCodesCurrent(codes[0]);
                }
              }),
            ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe();

    observableWithPending(controller.getFuturesTypeCodes())
      .pipe(takeUntil(this._destroy$))
      .subscribe((items) => {
        this.getFuturesTypeCodes$.next(items);
      });

    combineLatest([
      this.futuresTypeCodesCurrent$.pipe(
        debounceTime(300),
        filter((item): item is string => item !== null),
      ),
      this.destinationsProvider.currentDestination$,
    ])
      .pipe(
        mergeMap(([code, currentDestination]) =>
          observableWithPending(
            controller.getFuturesTypeByCode({
              code,
              destinationStationId:
                (currentDestination && parseInt(currentDestination.id, 10)) ||
                0,
            }),
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe((item) => {
        this.getFuturesTypeByCode$.next(item);
      });

    combineLatest([
      this.futuresInstrumentCodeCurrent$,
      this.chartPeriodItemsCurrent$.pipe(debounceTime(300)),
    ])
      .pipe(
        mergeMap(([instrumentCode, index]) =>
          observableWithPending(
            controller.getBestordersByInstrumentCode({
              instrumentCode,
              startDate: this.chartPeriodItems[index].startDate,
              endDate: this.chartPeriodItems[index].endDate,
            }),
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe((item) => {
        this.getBestordersByInstrumentCode$.next(item);
      });
  }

  menuItems$ = new BehaviorSubject<string[]>([]);
  menuItemCurrent$ = new Subject<string>();

  getFuturesTypeCodes$ = new BehaviorSubject<TObservableWithPending<string[]>>(
    asOwpSuccess([]),
  );
  futuresTypeCodes$ = mapResult(this.getFuturesTypeCodes$);
  futuresTypeCodesPending$ = mapPending(this.getFuturesTypeCodes$);
  futuresTypeCodesError$ = mapError(this.getFuturesTypeCodes$);
  futuresTypeCodesCurrent$ = new BehaviorSubject<string | null>(null);

  setFuturesTypeCodesCurrent(str: string) {
    this.futuresTypeCodesCurrent$.next(str);
  }

  getFuturesTypeByCode$ = new BehaviorSubject<
    TObservableWithPending<{
      futuresType: TFuturesType;
      tariff: TFuturesTariff;
      basisPlantStationNames: TBasisPlantStationNames | null;
    } | null>
  >(asOwpSuccess(null));
  futuresTypeByCode$ = mapResult(this.getFuturesTypeByCode$);
  futuresTypeByCodePending$ = mapPending(this.getFuturesTypeByCode$);

  futuresProductGroups: ProductGroup[] = [
    {
      name: 'Бензин АИ-92-К5',
      code: 'REG',
    },
    {
      name: 'Бензин АИ-95-К5',
      code: 'PRM',
    },
    {
      name: 'ДТ ЕВРО сорт C минус 5 (ДТ-Л-К5)',
      code: 'DTL',
    },
    {
      name: 'ДТ ЕВРО сорт Е (ДТ-Е-К5) минус 15',
      code: 'DM1',
    },
    {
      name: 'ДТ ЕВРО сорт F (ДТ-Е-К5) минус 20',
      code: 'DM2',
    },
    {
      name: 'Газы углеводородные сжиженные марок ПБТ или ПБА',
      code: 'PBA',
    },
    {
      name: 'Конденсат газовый стабильный',
      code: 'PCK',
    },
  ];
  futuresProductGroups$ = new BehaviorSubject<ProductGroup[]>([]);
  futuresProductGroupsCurrent$ = new BehaviorSubject<ProductGroup | null>(null);
  setFuturesProductGroupsCurrent(productGroup: ProductGroup) {
    this.futuresProductGroupsCurrent$.next(productGroup);
  }

  getFuturesBestordersTradesLastItemsByFuturesType$ =
    this.futuresTypeCodesCurrent$.pipe(
      debounceTime(300),
      filter((item): item is string => item !== null),
      distinctUntilChanged(),
      switchMap((code) =>
        timer(0, 3000).pipe(
          exhaustMap(() =>
            observableWithPending(
              this.controller.getFuturesBestordersTradesLastItemsByFuturesType({
                code,
              }),
            ),
          ),
        ),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  futuresBestordersTradesLastItemsByFuturesType$ = mapResult(
    this.getFuturesBestordersTradesLastItemsByFuturesType$,
  );
  futuresBestordersTradesLastItemsByFuturesTypePending$ =
    this.futuresTypeCodesCurrent$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(() =>
        mapPending(this.getFuturesBestordersTradesLastItemsByFuturesType$).pipe(
          skip(1),
          takeWhile((res) => res === true, true),
        ),
      ),
    );

  futuresInstrumentCodeCurrent$ = new ReplaySubject<string>(1);
  setFuturesInstrumentCodeCurrent(str: string) {
    this.futuresInstrumentCodeCurrent$.next(str);
  }

  getBestordersByInstrumentCode$ = new BehaviorSubject<
    TObservableWithPending<TFuturesBestordersBuyPriceLastDailyItem[]>
  >(asOwpSuccess([]));
  bestordersByInstrumentCode$ = mapResult(this.getBestordersByInstrumentCode$);
  bestordersByInstrumentCodePending$ = mapPending(
    this.getBestordersByInstrumentCode$,
  );

  getStartDate(date: Date) {
    return getStartDate(date);
  }

  getEndDate(date: Date) {
    return getEndDate(date);
  }

  getPastDateFromMilliseconds(ms: number) {
    return new Date(new Date().getTime() - ms);
  }

  chartPeriodItems = [
    {
      label: 'сегодня',
      startDate: this.getStartDate(new Date()),
      endDate: this.getEndDate(new Date()),
    },
    {
      label: 'неделя',
      startDate: this.getStartDate(
        this.getPastDateFromMilliseconds(1000 * 60 * 60 * 24 * 7),
      ),
      endDate: this.getEndDate(new Date()),
    },
    {
      label: 'месяц',
      startDate: this.getStartDate(
        this.getPastDateFromMilliseconds(1000 * 60 * 60 * 24 * 30),
      ),
      endDate: this.getEndDate(new Date()),
    },
    {
      label: 'все время',
      startDate: this.getStartDate(
        this.getPastDateFromMilliseconds(1000 * 60 * 60 * 24 * 30 * 12),
      ),
      endDate: this.getEndDate(new Date()),
    },
  ];
  chartPeriodItemsCurrent$ = new BehaviorSubject(1);
  setChartPeriodItemsCurrent(index: number) {
    this.chartPeriodItemsCurrent$.next(index);
  }
}
