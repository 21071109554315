import { Injectable } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';

import { FeedbackContainer } from './feedback.container';

@Injectable({
  providedIn: 'root',
})
export class FeedbackOverlayService {
  private readonly overlayRef: OverlayRef;
  private closeModalSub!: Subscription;
  private backdropClickSub!: Subscription;
  private keydownSub!: Subscription;

  constructor(private readonly overlay: Overlay) {
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      positionStrategy: this.overlay
        .position()
        .global()
        .centerVertically()
        .centerHorizontally(),
    });

    this.overlayRef = this.overlay.create(overlayConfig);
  }

  public show(): void {
    const modalPortal = new ComponentPortal(FeedbackContainer);
    const ref = this.overlayRef.attach(modalPortal);

    this.closeModalSub = ref.instance.closeModal.subscribe(() => this.hide());
    this.backdropClickSub = this.overlayRef
      .backdropClick()
      .subscribe(() => this.hide());
    this.keydownSub = this.overlayRef
      .keydownEvents()
      .subscribe((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.hide();
        }
      });
  }

  public hide(): void {
    this.overlayRef.detach();
    this.closeModalSub.unsubscribe();
    this.backdropClickSub.unsubscribe();
    this.keydownSub.unsubscribe();
  }
}
