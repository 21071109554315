import * as Sentry from '@sentry/angular';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 0,
  integrations: [
    Sentry.replayIntegration({
      mutationLimit: 150000,
    }),
  ],
  release: process.env.SENTRY_RELEASE,
  environment: process.env.SENTRY_ENVIRONMENT,
});
