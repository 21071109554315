import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonPaneComponent } from '@prlw/common/common-pane/common-pane.component';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';
import { ResponsiveService } from '@prlw/libs/responsive/responsive.service';

@Component({
  selector: 'prlw-title-label-ui',
  standalone: true,
  imports: [CommonModule, ResponsiveDirective, CommonPaneComponent],
  templateUrl: './title-label.component.html',
  styleUrl: './title-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleLabelComponent {
  @Input() title: string | null = 'foobar';
  @Input() isPro: boolean | null = false;

  constructor(private responsiveService: ResponsiveService) {}

  xsmall$ = this.responsiveService.xsmall$;
}
