import { Injectable, OnDestroy } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileUploadOverlayContainer } from './file-upload-overlay-container';

@Injectable({
  providedIn: 'root',
})
export class FileUploadOverlayService implements OnDestroy {
  private overlayRef?: OverlayRef;
  private readonly _isOpen$!: BehaviorSubject<boolean>;
  private readonly _destroy$ = new Subject<void>();

  constructor(private readonly overlay: Overlay) {
    this._isOpen$ = new BehaviorSubject<boolean>(false);
  }

  public show(): void {
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay',
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });

    this.overlayRef = this.overlay.create(overlayConfig);
    const modalPortal = new ComponentPortal(FileUploadOverlayContainer);
    const ref = this.overlayRef.attach(modalPortal);
    ref.instance.closeOverlay
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.hide());
    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.hide());
    this.overlayRef
      .keydownEvents()
      .pipe(takeUntil(this._destroy$))
      .subscribe((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.hide();
        }
      });
    this._isOpen$.next(true);
  }

  public hide(): void {
    this.overlayRef?.detach();
    this._isOpen$.next(false);
    this._destroy$.next();
  }

  public ngOnDestroy(): void {
    this.hide();
    this._destroy$.complete();
  }
}
