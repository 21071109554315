import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LicenseStatus } from '@prlw/core/profile/profile-license.entity';

export type Alert = Pick<LicenseStatus, 'type' | 'accessInDate'>;

@Component({
  selector: 'prlw-license-alert',
  templateUrl: './license-alert.component.html',
  styleUrls: ['./license-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenseAlertComponent {
  @Input() public demo?: Alert;

  public get demoStatus(): boolean {
    return this.demo?.type === 'DEMO';
  }
}
