import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[prlwSwipe]',
  standalone: true,
})
export class SwipeDirective {
  @Output() eventSwipeUp = new EventEmitter<void>();
  @Output() eventSwipeDown = new EventEmitter<void>();
  @Output() eventTouchmove = new EventEmitter<number>();
  @Output() eventTouchend = new EventEmitter<void>();

  swipeCoord = [0, 0];
  swipeTime = new Date().getTime();

  @HostListener('touchstart', ['$event']) onSwipeStart(event: TouchEvent) {
    this.onSwipe(event, 'start');
  }

  @HostListener('touchend', ['$event']) onSwipeEnd(event: TouchEvent) {
    this.eventTouchend.emit();
    this.onSwipe(event, 'end');
  }

  @HostListener('touchmove', ['$event']) onSwipeMove(event: TouchEvent) {
    this.eventTouchmove.emit(event.changedTouches[0].clientY);
  }

  onSwipe(e: TouchEvent, when: string) {
    this.swipe(e, when);
  }

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY,
    ];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [
        coord[0] - this.swipeCoord[0],
        coord[1] - this.swipeCoord[1],
      ];
      const duration = time - this.swipeTime;

      if (
        duration < 1000 && //
        Math.abs(direction[1]) > 30 && // Long enough
        Math.abs(direction[1]) > Math.abs(direction[0] * 3)
      ) {
        // Vertical enough
        const swipeDir = direction[1] < 0 ? 'up' : 'down';
        if (swipeDir === 'up') {
          this.eventSwipeUp.emit();
        } else {
          this.eventSwipeDown.emit();
        }
      }
    }
  }
}
