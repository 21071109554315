<a class="main-page-link" routerLink="/" aria-label="Главная страница">
  <img
    class="logo-image"
    src="/assets/logo/logo-full.svg"
    width="123"
    height="24"
    aria-hidden="true"
    alt="logo-full"
  />
</a>
<div class="title">
  <h1 class="title-text">{{ title$ | async }}</h1>
</div>
