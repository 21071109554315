<div class="container" #container>
  <div
    class="plug-container"
    prlwSwipe
    (eventSwipeDown)="eventClickX.emit()"
    (eventTouchend)="handleTouchend()"
    (eventTouchmove)="eventTouchmove.emit($event)"
    #plugContainer
  >
    <svg
      width="31"
      height="4"
      viewBox="0 0 31 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="31" height="4" rx="2" fill="#D9D9D9" />
    </svg>
  </div>
  <svg
    *ngIf="showXButton"
    (click)="eventClickX.emit()"
    class="x"
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 18.5C0 8.28273 8.28273 0 18.5 0C28.7173 0 37 8.28273 37 18.5C37 28.7173 28.7173 37 18.5 37C8.28273 37 0 28.7173 0 18.5Z"
      fill="#F5F5F5"
    />
    <path d="M13 13L24 24" stroke="black" stroke-width="1.5" />
    <path d="M24 13L13 24" stroke="black" stroke-width="1.5" />
  </svg>
  <span class="label" *ngIf="label; else header">{{ label }}</span>
  <ng-template #header>
    <div class="header">
      <ng-content select="[header]"></ng-content>
    </div>
  </ng-template>
  <div
    class="overflow-container"
    prlwOverflowFader
    prlwScrollAnchorTarget
    #overflowContainer
  >
    <ng-content select="[body]"></ng-content>
  </div>
  <prlw-mobile-button
    *ngIf="showDoneButton"
    [label]="buttonLabel"
    [greyLabel]="greyLabel"
    class="done-button"
    (eventClick)="eventClickMobileButton.emit()"
  ></prlw-mobile-button>
</div>
