<prlw-mobile-overlay-card-ui
  label="Обратная связь"
  [maxHeight]="82"
  [showDoneButton]="false"
  (eventClickX)="eventClose.emit()"
>
  <prlw-feedback-page
    body
    (closeModal)="eventClose.emit()"
  ></prlw-feedback-page>
</prlw-mobile-overlay-card-ui>
