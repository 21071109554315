import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { mainTableColumns } from '@prlw/core/monitor/init/main-table';
import {
  MainTableColumn,
  MainTableColumnId,
} from '@prlw/core/monitor/types/main-table';
import { UserSettingsController } from '@prlw/core/user-settings/user-settings.controller';
import { ColumnsState } from '@prlw/libs/columns-state/columns.state';
import { Setting } from '../user-settings/setting.interface';

export const MAIN_TABLE_COLUMNS_STATE = 'main-table-columns-state';
export const MAIN_TABLE_COLUMNS_SETTING = new Setting<
  MainTableColumn<MainTableColumnId>[]
>('main-table-columns-setting');

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: MAIN_TABLE_COLUMNS_STATE,
      useFactory: (userSettingsController: UserSettingsController) =>
        new ColumnsState<MainTableColumnId>(
          userSettingsController,
          MAIN_TABLE_COLUMNS_SETTING,
          mainTableColumns,
        ),
      deps: [UserSettingsController],
    },
  ],
})
export class MonitorCoreModule {}
