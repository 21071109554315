import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FeedbackController } from './feedback.controller';
import { TFileUploadResponse } from '@prlw/data/feedback/api-types/file-upload.api';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private readonly feedbackController: FeedbackController) {}

  // eslint-disable-next-line
  files$ = new BehaviorSubject<{ [key: string]: TFileUploadResponse }>({});

  public attachFile(file: File): void {
    of(file)
      .pipe(
        switchMap((fileToCheck) => {
          const error = this.isNotValid(fileToCheck);
          if (error) {
            return of({
              done: true,
              pending: false,
              error,
            });
          }
          return this.feedbackController.fileUpload(fileToCheck);
        }),
      )
      .subscribe((event) => {
        this.files$.next({
          ...this.files$.getValue(),
          [file.name]: event,
        });
      });
  }

  public removeAttachedFile(fileName: string): void {
    const files = this.files$.getValue();
    // eslint-disable-next-line
    delete files[fileName];
    this.files$.next(files);
  }

  private isNotValid(file: File): string | null {
    const ALLOWED_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'mp4'];
    const MAX_FILE_SIZE_BINARY = 10485760;
    const errors: string[] = [];
    if (file.size > MAX_FILE_SIZE_BINARY) {
      errors.push('Файл больше 10 Мб.');
    }

    if (
      !ALLOWED_FILE_EXTENSIONS.includes(
        //check mime
        this.getExtension(file.name.toLowerCase()),
      )
    ) {
      errors.push(
        `Недопустимый формат файла. Допустимые форматы: ${ALLOWED_FILE_EXTENSIONS.join(
          ', ',
        )}.`,
      );
    }

    return !errors.length ? null : errors.join(' ');
  }

  private getExtension(filename: string): string {
    return filename.substring(filename.lastIndexOf('.') + 1);
  }
}
