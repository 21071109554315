<a
  routerLink="/monitor"
  routerLinkActive="navbar-item-active"
  class="navbar-item"
>
  <prlw-icon name="nav-spot" class="navbar-icon"></prlw-icon>
  <span class="navbar-label">Спот</span>
</a>
<a
  routerLink="/futures"
  routerLinkActive="navbar-item-active"
  class="navbar-item"
>
  <prlw-icon name="nav-futures" class="navbar-icon"></prlw-icon>
  <span class="navbar-label">Срочный рынок</span>
</a>
<a
  routerLink="/deals"
  routerLinkActive="navbar-item-active"
  class="navbar-item"
>
  <prlw-icon name="nav-deals" class="navbar-icon"></prlw-icon>
  <span class="navbar-label">Все сделки</span>
</a>
<a
  routerLink="/analytics"
  routerLinkActive="navbar-item-active"
  class="navbar-item"
>
  <prlw-icon name="nav-analytics" class="navbar-icon"></prlw-icon>
  <span class="navbar-label">Аналитика</span>
</a>
<a
  routerLink="/region"
  routerLinkActive="navbar-item-active"
  class="navbar-item"
>
  <prlw-icon name="nav-small-wholesale" class="navbar-icon"></prlw-icon>
  <span class="navbar-label">Регион</span>
</a>
