<div class="error-container" prlwResponsive>
  <img src="/assets/icons/feedback-error.svg" alt="feedback-error" />

  <h2 class="error-container__title" data-cy="Feedback error title">
    Что-то пошло не так
  </h2>

  <p class="error-container__message">
    При отправке сообщения произошла ошибка. Попробуйте еще раз или попробуйте
    позже.
  </p>

  <div class="error-container__action" prlwResponsive>
    <button
      class="ui-button ui-black"
      [ngClass]="{ 'ui-loading': isLoading }"
      data-cy="Send feedback"
      (click)="send()"
      [disabled]="isLoading"
    >
      <span *ngIf="!isLoading">Попробовать еще раз</span>
      <span *ngIf="isLoading" class="loader"></span>
    </button>

    <button
      class="ui-button ui-black ui-black-transparent"
      data-cy="Feedback cancel"
      (click)="exit()"
    >
      Отмена
    </button>
  </div>
</div>
