import { DestinationEntity } from '@prlw/core/destinations/destination.entity';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const DESTINATIONS_GATEWAY = new InjectionToken<DestinationsGateway>(
  'destinations-gateway',
);

export interface DestinationsGateway {
  findDestinations(query: string): Observable<DestinationEntity[]>;
}
