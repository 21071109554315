import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatSecondsPipe } from '@prlw/infrastructure/time-format/format-seconds.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FormatSecondsPipe],
  exports: [FormatSecondsPipe],
})
export class TimeFormatModule {}
