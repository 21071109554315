<ng-container *ngIf="(feedbackStatus$ | async) as status">
  <prlw-feedback-input-ui
    *ngIf="isInit(status) || (isSending(status) && !isError(lastStatus))"
    (closeModal)="exit()"
    (sendFeedback)="send($event)"
    [isLoading]="isSending(status)"
    [allowedFileExtensions]="['jpg', 'jpeg', 'png', 'gif', 'pdf', 'avi']"
  ></prlw-feedback-input-ui>

  <prlw-feedback-gratitude-ui
    *ngIf="isSent(status)"
    (closeModal)="exit()"
  ></prlw-feedback-gratitude-ui>

  <prlw-feedback-error-ui
    *ngIf="isError(status) || (isSending(status) && isError(lastStatus))"
    (closeModal)="exit()"
    (sendFeedback)="send($event)"
    [isLoading]="isSending(status)"
  ></prlw-feedback-error-ui>
</ng-container>
