import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FuturesGateway } from '@prlw/core/futures/futures-gateway.interface';
import { TCode } from '@prlw/core/futures/types/code';
import { TDestinationStation } from '@prlw/core/futures/types/destination';
import { TInstrumentCode } from '@prlw/core/futures/types/instrument-code';
import { TDatePeriod } from '@prlw/core/futures/types/date-period';
import { TGetFuturesBestordersTradesLastItemsByFuturesType } from '@prlw/core/futures/types/get-futures-bestorders-trades-last-items-by-futures-type';
import { TGetFuturesTypeByCode } from '@prlw/core/futures/types/get-futures-type-by-code';
import { TGetBestordersByInstrumentCode } from '@prlw/core/futures/types/get-bestorders-by-instrument-code';

@Injectable({
  providedIn: 'root',
})
export class HttpFuturesGateway implements FuturesGateway {
  prefix = '/api/futures';

  constructor(private readonly http: HttpClient) {}

  getFuturesTypeCodes() {
    return this.http.get<string[]>(`${this.prefix}/type/codes`);
  }

  getFuturesTypeByCode({
    code,
    destinationStationId,
  }: TCode & TDestinationStation) {
    return this.http.get<TGetFuturesTypeByCode>(
      `${this.prefix}/type/${code}?destinationStationId=${destinationStationId}`,
    );
  }

  getFuturesBestordersTradesLastItemsByFuturesType({ code }: TCode) {
    return this.http.get<TGetFuturesBestordersTradesLastItemsByFuturesType>(
      `${this.prefix}/bestorders-trades-last/${code}`,
    );
  }

  getBestordersByInstrumentCode({
    instrumentCode,
    startDate,
    endDate,
  }: TInstrumentCode & TDatePeriod) {
    return this.http.get<TGetBestordersByInstrumentCode>(
      `${this.prefix}/bestorders/${instrumentCode}?startDate=${startDate}&endDate=${endDate}`,
    );
  }
}
