import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AllInstruments } from '@prlw/core/monitor/all-instruments.entity';
import {
  DealStat,
  DealStatInterval,
} from '@prlw/core/monitor/deal-stat.entity';
import { SessionAgeStatus } from '@prlw/core/monitor/session-age-status.enum';
import { ForecastTrend } from '@prlw/core/monitor/forecast-trend.entity';
import { Instrument } from '@prlw/core/monitor/instrument.entity';
import {
  DealStatScale,
  MonitorGateway,
} from '@prlw/core/monitor/monitor.gateway';
import { TariffStatus } from '@prlw/core/monitor/tariff-status.enum';
import { Modifier } from '@prlw/core/monitor/types/common';
import { Product } from '@prlw/core/products/product.entity';
import { DealsStatResponse } from '@prlw/data/monitor/api-types/deals-stats.api';
import { GetAllInstrumentsResponse } from '@prlw/data/monitor/api-types/get-all-instruments.api';
import { GetInstrumentsResponse } from '@prlw/data/monitor/api-types/get-instruments.api';
import {
  MONITOR_DATA_CONFIG,
  MonitorDataConfig,
} from '@prlw/data/monitor/monitor-data.config';
import { modifierSerializer } from '@prlw/data/monitor/utils/monitor.util';
import { MarketTimeController } from '@prlw/core/market-time/market-time.controller';

function parseForecastTrend(source?: string): ForecastTrend | null {
  if (source?.toUpperCase() === 'UP') {
    return ForecastTrend.Up;
  }
  if (source?.toUpperCase() === 'DOWN') {
    return ForecastTrend.Down;
  }
  if (source?.toUpperCase() === 'SIDE') {
    return ForecastTrend.Lateral;
  }
  return null;
}

function parseTariffStatus(source: string): TariffStatus {
  if (source.toUpperCase() === 'TARIFF') {
    return TariffStatus.Ready;
  }
  if (source.toUpperCase() === 'WAIT') {
    return TariffStatus.Wait;
  }
  return TariffStatus.Error;
}

function parseSessionAgeStatus(source: string): SessionAgeStatus {
  if (source === '2') {
    return SessionAgeStatus.LastSession;
  } else if (source === '3') {
    return SessionAgeStatus.OldSession;
  }
  return SessionAgeStatus.Actual;
}

function mapResponseToEntity(resp: GetInstrumentsResponse): Instrument[] {
  return resp.map((item) => ({
    instrumentCode: item.instrumentCode,
    instrumentName: item.instrumentName,
    shipmentBasisStations: item.shipmentBasisStations,
    shipment: {
      code: item.shipmentBasis,
      briefDescription: item.shipmentBasisBriefDescription,
      name: item.shipmentBasisName,
      plants: item.shipmentBasisPlants.map((plantName) => ({
        name: plantName,
      })),
    },
    quote: {
      buyPrice: item.quote.bestBuyPrice,
      buyAmount: item.quote.bestBuyAmount,
      sellPrice: item.quote.bestSellPrice,
      sellAmount: item.quote.bestSellAmount,
      lastDealPrice: item.quote.lastDealPrice,
      prevDealPrice: item.prevDealPrice,
      delta: item.dynamic,
      dealStatus: parseSessionAgeStatus(item.dealStatus),
      quoteStatus: parseSessionAgeStatus(item.quoteStatus),
      timestamp: item.quote.timestamp,
    },
    forecastTrend: parseForecastTrend(item.forecast?.trend),
    forecastDate: item.forecast?.date ?? null,
    currentAmount: item.amount.current,
    expectedAmount: item.amount.forecast,
    previousSessionAmount: item.prevDealAmount,
    previousSessionDate: item.prevDealDate,
    prevDealsAmountDiff: item.prevDealsAmountDiff,
    tariffStatus: parseTariffStatus(item.tariffStatus),
    totalPrice: item.total,
    tariff: item.tariff?.price ?? null,
    tariffSource: item.tariff?.source ?? null,
    tariffTimestamp: item.tariff?.timestamp ?? null,
    shipmentDays: item.tariff?.shipmentTime ?? null,
    deliveryDays: item.tariff?.deliveryTime ?? null,
    deliveryMethod: item.deliveryMethod,
    stationDescription: item.tariff?.stationDescription ?? null,
    expectedTradingStart: new Date(item.startSale),
    dealTimestamp: item.dealTimestamp,
    isDealTotal: item.isDealTotal,
    bestBuy: item.isBestDemand,
    bestSell: item.isBestSupply,
    bestLastDeal: item.isBestLastBuy,
    bestTotalPrice: item.isBestTotal,
    bestTariff: item.isBestTariff,
    mode: item.mode,
    productId: item.tariff?.productId ?? null,
    shipmentBasisId: item.tariff?.shipmentBasisId ?? null,
    marketDepth: item.marketDepth ?? null,
  }));
}

function convertToAllProductEntity(
  response: GetAllInstrumentsResponse,
): AllInstruments[] {
  return Object.entries(response).map(([key, value]) => ({
    shipmentBasis: key,
    shipmentBasisData: {
      code: key,
      stations: value.shipmentBasisStations,
      shipmentBasisName: value.shipmentBasisName,
      manufacturers: value.shipmentBasisPlants,
      shipmentBasisBriefDescription: value.shipmentBasisBriefDescription,
    },
    instrumentsByProductGroup: value,
  }));
}

@Injectable({
  providedIn: 'root',
})
export class HttpMonitorGateway implements MonitorGateway {
  constructor(
    @Inject(MONITOR_DATA_CONFIG) private readonly config: MonitorDataConfig,

    private readonly http: HttpClient,
    private readonly marketTimeController: MarketTimeController,
  ) {}

  public getInstruments(
    product: string,
    destinationId: string | null,
    shipments: string[],
    activeProducts: Product[],
    modifier: Modifier,
  ): Observable<Instrument[]> {
    const params = {
      productGroup: product,
      ...(shipments.length && { shipmentBasics: shipments.join(',') }),
      ...(destinationId && { destinationStationId: destinationId }),
      ...(activeProducts.length && {
        productsCodes: activeProducts.map((item) => item.code).join(','),
        ...modifierSerializer(modifier),
      }),
    };
    return this.marketTimeController.timerSpot$
      .pipe(
        map(({ target }) => target),
        distinctUntilChanged(),
      )
      .pipe(
        switchMap(() =>
          this.http
            .get<GetInstrumentsResponse>(
              `${this.config.apiPrefix}/instruments`,
              {
                params,
              },
            )
            .pipe(map((resp) => mapResponseToEntity(resp))),
        ),
      );
  }

  public getAllInstruments(
    productGroups: string[],
    shipmentBasises: string[],
    destination: string,
    modifier: Modifier,
  ): Observable<AllInstruments[]> {
    const params = {
      productGroups: productGroups.join(','),
      shipmentBasises: shipmentBasises.join(','),
      ...(destination && { destination }),
      ...modifierSerializer(modifier),
    };
    return this.http
      .get<GetAllInstrumentsResponse>(
        `${this.config.apiPrefix}/all-instruments`,
        { params },
      )
      .pipe(map((response) => convertToAllProductEntity(response)));
  }

  public getDealsStats(
    instrumentCode: string,
    scale: DealStatScale,
  ): Observable<DealStat[]> {
    const params = { instrumentCode, scale };
    return this.http
      .get<DealsStatResponse[]>(`${this.config.apiPrefix}/deals-stat`, {
        params,
      })
      .pipe(
        map((stats) =>
          stats.map((stat) => ({
            ...stat,
            interval: {
              start: new Date(stat.interval.start.replaceAll('.', '-')),
              type: stat.interval.type.toUpperCase() as DealStatInterval,
            },
          })),
        ),
      );
  }
}
