import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const MARKET_TIME_GATEWAY = new InjectionToken<MarketTimeGateway>(
  'market-time-gateway',
);

export type MarketTimeData = {
  tradingTimeBeforeStart: number;
  tradingTimeEnd: number;
};

export type LastTradingSessionDateData = {
  lastTradingSessionDate: string;
};

export interface MarketTimeGateway {
  getTradingTime(): Observable<MarketTimeData>;
  getLastTradingSessionDate(): Observable<LastTradingSessionDateData>;
}
