import { Inject, Injectable } from '@angular/core';
import { FUTURES_GATEWAY } from './futures-gateway.token';
import { FuturesGateway } from './futures-gateway.interface';
import { TCode } from './types/code';
import { TDestinationStation } from './types/destination';
import { TInstrumentCode } from './types/instrument-code';
import { TDatePeriod } from './types/date-period';

@Injectable({
  providedIn: 'root',
})
export class FuturesController {
  constructor(
    @Inject(FUTURES_GATEWAY)
    private readonly gateway: FuturesGateway,
  ) {}

  getFuturesTypeCodes() {
    return this.gateway.getFuturesTypeCodes();
  }

  getFuturesTypeByCode(p: TCode & TDestinationStation) {
    return this.gateway.getFuturesTypeByCode(p);
  }

  getFuturesBestordersTradesLastItemsByFuturesType(p: TCode) {
    return this.gateway.getFuturesBestordersTradesLastItemsByFuturesType(p);
  }

  getBestordersByInstrumentCode(p: TInstrumentCode & TDatePeriod) {
    return this.gateway.getBestordersByInstrumentCode(p);
  }
}
