export enum AllDealsTableColumnId {
  PRODUCT = 1,
  BASIS = 2,
  VOLUME = 3,
  PRICE = 4,
  TOTAL_PRICE = 5,
  DEAL_NUMBER = 6,
  CREATE_TIME = 7,
  CREATE_DATE = 8,
  INSTRUMENT_CODE = 9,
}

export type AllDealsTableColumn<AllDealsTableColumnId> = {
  id: AllDealsTableColumnId;
  name: string;
  isVisible: boolean;
  isStatic: boolean;
  cellSizeMin: string;
  cellSizeMax?: string;
  cellFixed?: boolean;
};

export type AllDealsTableSorting = {
  columnId: AllDealsTableColumnId;
  direction: 'ASC' | 'DESC';
};
