import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ProfileController } from '@prlw/core/profile/profile.controller';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LicenseStatus } from '@prlw/core/profile/profile-license.entity';

@Component({
  selector: 'prlw-license-tab',
  templateUrl: './license-tab.component.html',
  styleUrls: ['./license-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenseTabComponent {
  @Input() public license!: LicenseStatus;
  public license$: Observable<LicenseStatus>;
  public hasHistory$: Observable<boolean>;
  public docsVerification$: Observable<boolean>;

  constructor(private readonly user: ProfileController) {
    this.license$ = this.user.licenseStatus$;
    this.hasHistory$ = this.user.licenseHistory$.pipe(
      map((history) => Boolean(history.length)),
    );
    this.docsVerification$ = of(true);
  }
}
