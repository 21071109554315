import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[prlwFileUploadDnd]',
})
export class DndDirective {
  @HostBinding('class.file-over') public fileOver: boolean = false;
  @HostBinding('class.file-error') public fileError: boolean = false;
  @Output() public readonly fileDropped = new EventEmitter();
  @Output() public readonly fileHovered = new EventEmitter<boolean>();
  @Output() public readonly hasError = new EventEmitter<boolean>();

  @HostListener('dragover', ['$event'])
  public onDragOver(event: DragEvent): void {
    DndDirective._stopEvent(event);
    this.fileOver = true;
    this.fileHovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: DragEvent): void {
    DndDirective._stopEvent(event);
    this.fileOver = false;
    this.fileHovered.emit(false);
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent): void {
    DndDirective._stopEvent(event);
    this.fileOver = false;
    this.fileHovered.emit(false);
    this.fileDropped.emit(event.dataTransfer?.files);
  }

  private static _stopEvent(event: DragEvent | MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  // private static _getFiles(event: DragEvent): File[] {
  //   const files = event.dataTransfer?.files ?? [];
  //   const items = event.dataTransfer?.items ?? [];
  //   if (files.length) {
  //     return Array.from(files);
  //   } else if (items.length) {
  //     return Array.from(items as File[]);
  //   }
  //   return [];
  // }

  // private static _getMaxFilesSize(event: DragEvent): boolean {
  //   const files = event.dataTransfer?.files ?? [];
  //   const maxSize = Math.max(...Array.from(files).map((f) => f.size));
  //   if (maxSize > FileSize.sizeMax) {
  //     console.log(`Превышает максимально допустимый размер файла`);
  //     return true;
  //   }
  //   return false;
  // }

  // private _getCorrectFiles(event: DragEvent): File[] {
  //   return (FILES_TYPE.map((t) =>
  //     this._getFilesType(event, t)
  //   ).flat() as unknown) as File[];
  // }
  //
  // private _getFilesType(event: DragEvent, type: string): File[] {
  //   return DndDirective._getFiles(event).filter((f) => {
  //     if (!f.type.includes(type)) {
  //     }
  //     return f.type.includes(type);
  //   });
  // }
  //
  // private _hasFilesType(event: DragEvent, type: string): boolean {
  //   return DndDirective._getFiles(event).some((f) => f.type.includes(type));
  // }
  //
  // private _hasDocuments(event: DragEvent): boolean {
  //   return Boolean(FILES_TYPE.filter((t) => this._hasFilesType(event, t)));
  // }
}
