import {
  ContractsTableColumn,
  ContractsTableColumnId,
  ContractsTableSorting,
} from '@prlw/core/contracts/types/contracts.types';

export const contractsTableColumns: ContractsTableColumn<ContractsTableColumnId>[] =
  [
    {
      name: 'Покупатель',
      id: ContractsTableColumnId.BUYER,
      isVisible: true,
      isStatic: true,
      cellSizeMin: '260px',
      cellSizeMax: '1.4fr',
    },
    {
      name: 'Продавец',
      id: ContractsTableColumnId.SELLER,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '150px',
      cellSizeMax: '0.8fr',
      cellFixed: true,
    },
    {
      name: 'Продукт',
      id: ContractsTableColumnId.PRODUCT,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '70px',
      cellSizeMax: '0.5fr',
      cellFixed: true,
    },
    {
      name: 'Базис',
      id: ContractsTableColumnId.BASIS,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '70px',
      cellSizeMax: '0.5fr',
      cellFixed: true,
    },
    {
      name: 'Объем',
      id: ContractsTableColumnId.AMOUNT,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '70px',
      cellSizeMax: '0.5fr',
      cellFixed: true,
    },
    {
      name: 'Стоимость',
      id: ContractsTableColumnId.PRICE,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '110px',
      cellSizeMax: '0.6fr',
      cellFixed: true,
    },
    {
      name: 'Станция назначения',
      id: ContractsTableColumnId.STATION,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '165px',
      cellSizeMax: '0.8fr',
    },
    {
      name: 'Дата создания',
      id: ContractsTableColumnId.CREATE_DATE,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '125px',
      cellSizeMax: '1fr',
    },
    {
      name: 'Дата заключения',
      id: ContractsTableColumnId.SIGNING_DATE,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '125px',
      cellSizeMax: '1fr',
    },
    {
      name: 'Условия',
      id: ContractsTableColumnId.DOCUMENT_VIEW,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '75px',
      cellSizeMax: '1fr',
    },
    {
      name: 'Статус',
      id: ContractsTableColumnId.STATUS,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '150px',
      cellFixed: true,
    },
  ];

export const contractsTableSortDefaults: ContractsTableSorting[] = [
  { columnId: ContractsTableColumnId.BUYER, direction: 'ASC' },
  { columnId: ContractsTableColumnId.SELLER, direction: 'ASC' },
  { columnId: ContractsTableColumnId.PRODUCT, direction: 'ASC' },
  { columnId: ContractsTableColumnId.BASIS, direction: 'ASC' },
  { columnId: ContractsTableColumnId.AMOUNT, direction: 'ASC' },
  { columnId: ContractsTableColumnId.PRICE, direction: 'ASC' },
  { columnId: ContractsTableColumnId.STATION, direction: 'ASC' },
  { columnId: ContractsTableColumnId.CREATE_DATE, direction: 'DES' },
  { columnId: ContractsTableColumnId.SIGNING_DATE, direction: 'DES' },
  { columnId: ContractsTableColumnId.DOCUMENT_VIEW, direction: 'ASC' },
  { columnId: ContractsTableColumnId.STATUS, direction: 'ASC' },
];
