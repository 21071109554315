<div class="container">
  <div class="alert" *ngIf="demoStatus">
    <div class="text">
      У вас активна лицензия
      <span class="bold">PROLEUM Demo до {{ demo?.accessInDate }}.</span> По
      истечении вы перейдете на Light. Продлите лицензию, чтобы использовать
      полные возможности
    </div>
  </div>
  <div class="alert">
    <div class="text">
      При совершении сделок суммарным объемом более 120 т — 1 месяц PROLEUM PRO
      в подарок
    </div>
  </div>
</div>
