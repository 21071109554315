import { Modifier, ModifierType } from '@prlw/core/monitor/types/common';

export const modifierSerializer = (
  modifier: Modifier,
): { [key: string]: string } => {
  if (
    modifier.type === ModifierType.BASIC ||
    modifier.type === ModifierType.SIMPLE
  ) {
    return { modifier: modifier.type };
  }
  const data = Object.entries(modifier.data).reduce(
    (acc, [key, value]) => ({ ...acc, ...(value && { [key]: `${value}` }) }),
    {},
  );
  return {
    modifier: modifier.type,
    ...data,
  };
};
