import { Setting } from '@prlw/core/user-settings/setting.interface';
import { UserSettingsController } from '@prlw/core/user-settings/user-settings.controller';
import { CommonColumn } from '@prlw/libs/columns-state/columns-state.types';
import { Observable, map } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

export class ColumnsState<Id> {
  private readonly _columns$: Observable<CommonColumn<Id>[]>;

  constructor(
    private readonly userSettingsController: UserSettingsController,
    private readonly setting: Setting<CommonColumn<Id>[]>,
    private readonly columns: CommonColumn<Id>[],
  ) {
    this._columns$ = userSettingsController.setting$(setting, columns);
  }

  public get columns$(): Observable<CommonColumn<Id>[]> {
    return this._columns$;
  }

  public updateColumns$(columns: CommonColumn<Id>[]): void {
    const hiddenColumns = this.columns
      .filter((column) => !columns.find((i) => i.id === column.id))
      .map((column) => ({ ...column, isVisible: false }));

    this.userSettingsController
      .updateSetting(this.setting, [...columns, ...hiddenColumns])
      .pipe(first())
      .subscribe();
  }

  public get activeColumns$(): Observable<CommonColumn<Id>[]> {
    return this._columns$.pipe(
      map((columns: CommonColumn<Id>[]) =>
        columns.filter(
          (column: CommonColumn<Id>) => column.isVisible || column.isStatic,
        ),
      ),
    );
  }

  public toggleColumn(columnId: Id): void {
    this._columns$
      .pipe(
        first(),
        switchMap((state) => {
          const newCol = state.map((column) => {
            if (column.id === columnId && !column.isStatic) {
              return {
                ...column,
                isVisible: !column.isVisible,
              };
            }
            return column;
          });
          return this.userSettingsController.updateSetting(
            this.setting,
            newCol,
          );
        }),
      )
      .subscribe();
  }
}
