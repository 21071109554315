export enum MarketTimeTarget {
  Start = 'START',
  End = 'END',
}

export type MarketTimeToStart = {
  target: MarketTimeTarget.Start;
  value: number;
};

export type MarketTimeToEnd = {
  target: MarketTimeTarget.End;
  value: number;
};

export type MarketTime = MarketTimeToStart | MarketTimeToEnd;
