import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PrlwBaseError } from '../error-types/error-types';

export abstract class NotificationBase {
  public abstract notify(error: PrlwBaseError): void;

  public abstract customNotify(message: string, title: string): void;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements NotificationBase {
  constructor(private readonly toasterService: ToastrService) {}

  public notify(error: PrlwBaseError): void {
    this.toasterService.warning(error.name, error.message);
  }

  public customNotify(message: string, title: string): void {
    this.toasterService.warning(message, title);
  }
}
