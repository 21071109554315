import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  DEALS_DATA_CONFIG,
  DealsDataConfig,
} from '@prlw/data/deals/deals-data.config';
import { DEALS_GATEWAY } from '@prlw/core/deals/deals.gateway';
import { HttpDealsGateway } from '@prlw/data/deals/deals.gateway';
import { DEALS_FILTER_GATEWAY } from '@prlw/core/deals/deals-filter.gateway';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: DEALS_GATEWAY,
      useClass: HttpDealsGateway,
    },
    {
      provide: DEALS_FILTER_GATEWAY,
      useClass: HttpDealsGateway,
    },
  ],
})
export class DealsDataModule {
  public static forRoot(
    config: DealsDataConfig,
  ): ModuleWithProviders<DealsDataModule> {
    return {
      ngModule: DealsDataModule,
      providers: [{ provide: DEALS_DATA_CONFIG, useValue: config }],
    };
  }
}
