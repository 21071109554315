import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { ICONS_CONFIG } from './icons-config.token';
import { IconsConfig } from '@prlw/libs/icons/icons.module';

@Injectable({
  providedIn: 'root',
})
export class IconLoader {
  constructor(
    @Inject(ICONS_CONFIG) private readonly config: IconsConfig,
    private readonly http: HttpClient,
  ) {}

  public loadIcon(name: string): Observable<string> {
    return this.http
      .get(this._getUrl(name), {
        responseType: 'text',
        headers: { SkipAuth: 'true' },
      })
      .pipe(share());
  }

  private _getUrl(name: string): string {
    return `${this.config.baseUrl}/${name}.svg`;
  }
}
