import {
  EventEmitter,
  Injectable,
  InjectionToken,
  Injector,
} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Subject, takeUntil } from 'rxjs';

export const MOBILE_OVERLAY_DATA_TOKEN = new InjectionToken<
  Record<string, unknown>
>('mobile-overlay-data');

@Injectable({
  providedIn: 'root',
})
export class MobileOverlayService {
  private readonly overlayRef: OverlayRef;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(private readonly overlay: Overlay) {
    const overlayConfig = new OverlayConfig({
      height: '100%',
      scrollStrategy: overlay.scrollStrategies.block(),
      hasBackdrop: true,
      backdropClass: 'custom-mobile-overlay-backdrop',
      positionStrategy: this.overlay.position().global().bottom('0px'),
    });

    this.overlayRef = this.overlay.create(overlayConfig);
  }

  // eslint-disable-next-line
  private arr: Array<{ Component: ComponentType<any>; param: any }> = [];

  public show<
    T extends {
      eventClose: EventEmitter<void>;
      eventCloseAll?: EventEmitter<void>;
    },
    P extends object,
  >(
    Component: ComponentType<T>,
    //@ts-expect-error TODO fix
    param: P = {},
  ): void {
    const modalPortal = new ComponentPortal(
      Component,
      null,
      Injector.create({
        providers: [
          {
            provide: MOBILE_OVERLAY_DATA_TOKEN,
            useValue: param,
          },
        ],
      }),
    );
    this.overlayRef.detach();
    const ref = this.overlayRef.attach(modalPortal);
    // TODO should not use with injector
    Object.entries(param).forEach(([key, value]) => {
      //@ts-expect-error TODO fix
      ref.instance[key] = value;
    });

    this.arr.push({
      Component,
      param,
    });

    if (ref.instance.eventCloseAll) {
      ref.instance.eventCloseAll
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => this.hide(true));
    }
    ref.instance.eventClose
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.hide());
    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.hide());
    this.overlayRef
      .keydownEvents()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.hide();
        }
      });
  }

  public hide(all = false): void {
    if (all) {
      this.arr = [];
    }

    this.arr.pop();
    const prev = this.arr.pop();
    if (prev) {
      const { Component, param } = prev;
      this.unsubscribe$.next();
      this.show(Component, param);
      return;
    }

    this.overlayRef.detach();
    this.unsubscribe$.next();
  }
}
