<div class="container" [class.column]="column">
  <div *ngIf="label" class="label">{{ label }}</div>
  <input
    [class]="getAppearance()"
    [formControl]="value"
    [placeholder]="placeholder"
    type="number"
    [min]="minValue"
    [step]="step || minValue"
    [max]="maxValue"
    (paste)="processPaste($event)"
    (keydown)="processKeyDown($event)"
    prlwResponsive
  />
</div>
