import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DelimitNumberPipe } from '@prlw/libs/number-format/delimit-numbers.pipe';
import { SignNumberPipe } from '@prlw/libs/number-format/sign-number.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [DelimitNumberPipe, SignNumberPipe],
  exports: [DelimitNumberPipe, SignNumberPipe],
})
export class NumberFormatModule {}
