import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { PRODUCTS_GATEWAY } from '@prlw/core/products/products.gateway';
import {
  ProductsDataConfig,
  PRODUCTS_DATA_CONFIG,
} from '@prlw/data/products/products-data.config';
import { HttpProductsGateway } from '@prlw/data/products/products.gateway';
import { PRODUCT_GROUPS_GATEWAY } from './../../core/products/product-groups.gateway';
import { HttpProductGroupsGateway } from './product-groups.gateway';
import { ProductsGatewayCacheDecorator } from './products-gateway-cache.decorator';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    HttpProductsGateway,
    {
      provide: PRODUCT_GROUPS_GATEWAY,
      useClass: HttpProductGroupsGateway,
    },
    {
      provide: PRODUCTS_GATEWAY,
      deps: [HttpProductsGateway],
      useFactory: (gateway: HttpProductsGateway) =>
        new ProductsGatewayCacheDecorator(gateway),
    },
  ],
})
export class ProductsDataModule {
  public static forRoot(
    config: ProductsDataConfig,
  ): ModuleWithProviders<ProductsDataModule> {
    return {
      ngModule: ProductsDataModule,
      providers: [{ provide: PRODUCTS_DATA_CONFIG, useValue: config }],
    };
  }
}
