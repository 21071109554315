import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmStatus } from '@prlw/core/profile/profile.controller';
import { PersonalData } from '@prlw/core/profile/profile.entity';
import { PrlwAuthError } from '@prlw/infrastructure/errors/error-types/error-types';

@Component({
  selector: 'prlw-personal-data-ui',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalDataComponent implements OnInit {
  @Input() public personalData!: PersonalData;
  @Input() public confirmEmailStatus: ConfirmStatus | null = ConfirmStatus.INIT;
  @Output() public readonly takeCode = new EventEmitter<string>();
  @Output() public readonly codeConfirm = new EventEmitter<string>();
  @Input() public nextSend?: number | null;
  @Input() public error: PrlwAuthError | null = null;
  @Input() public codeTime?: number | null;
  @Output() public readonly changeEmail = new EventEmitter<null>();
  @Output() public readonly submitForm = new EventEmitter<PersonalData>();
  @Output() public readonly showFeedbackModal = new EventEmitter<null>();

  public readonly personalDataForm = new UntypedFormGroup({
    surname: new UntypedFormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    patronymic: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    phone: new UntypedFormControl('', [Validators.required]),
    code: new UntypedFormControl(''),
  });

  public ngOnInit(): void {
    this.personalDataForm.get('phone')?.patchValue(this.personalData.phone);
    this.personalDataForm.get('phone')?.disable({ onlySelf: true });

    if (!this.personalData.isFullNameEditable) {
      this.personalDataForm
        .get('surname')
        ?.patchValue(this.personalData.surname);
      this.personalDataForm.get('name')?.patchValue(this.personalData.name);
      this.personalDataForm
        .get('patronymic')
        ?.patchValue(this.personalData.patronymic);
      this.personalDataForm.get('name')?.disable({ onlySelf: true });
      this.personalDataForm.get('surname')?.disable({ onlySelf: true });
      this.personalDataForm.get('patronymic')?.disable({ onlySelf: true });
      this.personalDataForm.get('name')?.clearValidators();
      this.personalDataForm.get('surname')?.clearValidators();
      this.personalDataForm.get('patronymic')?.clearValidators();
    }

    if (!this.personalData.isEmailEditable) {
      this.personalDataForm.get('email')?.patchValue(this.personalData.email);
      this.personalDataForm.get('email')?.disable({ onlySelf: true });
      this.personalDataForm.get('email')?.clearValidators();
    }
  }

  public showModal(): void {
    this.showFeedbackModal.emit();
  }

  public isInit(): boolean {
    return this.confirmEmailStatus === ConfirmStatus.INIT;
  }

  public isConfirmByCode(): boolean {
    return this.confirmEmailStatus === ConfirmStatus.CONFIRM_BY_CODE;
  }

  public isConfirmed(): boolean {
    return this.confirmEmailStatus === ConfirmStatus.CONFIRMED;
  }

  public codeRequest(): void {
    this._resetState();
    this.personalDataForm.get('email')?.disable({ onlySelf: true });
    this.takeCode.emit(this.personalDataForm.get('email')?.value);
  }

  public codeConfirmRequest(): void {
    this.codeConfirm.emit(this.personalDataForm.get('code')?.value);
    this.personalDataForm.get('email')?.disable({ onlySelf: true });
  }

  public submit(): void {
    this.personalDataForm.disable({ onlySelf: true });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { code, ...formValue } = this.personalDataForm.value;
    const personalData = { ...this.personalData, ...formValue };
    this.submitForm.emit(personalData);
  }

  private _resetState(): void {
    this.error = null;
    this.nextSend = null;
  }

  public get reSendTimer(): boolean {
    return this.codeTime === 0 || this.codeTime === null;
  }

  public onReSendCode(): void {
    this.takeCode.emit(this.personalDataForm.get('email')?.value);
  }

  public get nextSendTimer(): number {
    return this.codeTime as number;
  }

  public onChangeEmail(): void {
    this.changeEmail.emit(null);
    this.personalDataForm.get('email')?.enable({ onlySelf: true });
  }

  public get emailError(): boolean {
    return (
      (this.personalDataForm.get('email')?.touched &&
        !!this.personalDataForm.get('email')?.invalid) ||
      this.error !== null
    );
  }

  public checkValidity(): boolean {
    if (
      this.personalData.isFullNameEditable &&
      this.personalData.isEmailEditable
    ) {
      return Boolean(
        (this.personalDataForm.get('name')?.invalid ||
          this.personalDataForm.get('surname')?.invalid ||
          this.personalDataForm.get('patronymic')?.invalid ||
          this.personalDataForm.get('email')?.dirty) &&
          (this.personalDataForm.get('email')?.invalid || !this.isConfirmed()),
      );
    }
    return (
      this.personalDataForm.invalid ||
      (this.personalData.isEmailEditable && !this.isConfirmed())
    );
  }
}
