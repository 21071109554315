import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpCalendarGateway } from './calendar.gateway';
import { CALENDAR_GATEWAY } from '@prlw/core/calendar/calendar.gateway';

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [{ provide: CALENDAR_GATEWAY, useClass: HttpCalendarGateway }],
})
export class CalendarDataModule {}
