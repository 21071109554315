import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  MONITOR_DATA_CONFIG,
  MonitorDataConfig,
} from '@prlw/data/monitor/monitor-data.config';
import { MONITOR_GATEWAY } from '@prlw/core/monitor/monitor.gateway';
import { HttpMonitorGateway } from '@prlw/data/monitor/monitor.gateway';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [{ provide: MONITOR_GATEWAY, useClass: HttpMonitorGateway }],
})
export class MonitorDataModule {
  public static forRoot(
    config: MonitorDataConfig,
  ): ModuleWithProviders<MonitorDataModule> {
    return {
      ngModule: MonitorDataModule,
      providers: [{ provide: MONITOR_DATA_CONFIG, useValue: config }],
    };
  }
}
