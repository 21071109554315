import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyProductComponent } from './buy-product.component';
import { BuyProductContainer } from './buy-product.container';
import { NumberFormatModule } from '@prlw/libs/number-format/number-format.module';
import { IconsModule } from '@prlw/libs/icons/icons.module';
import { NumberInputComponent } from '../../common/number-input/number-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MobileOverlayCardComponent } from '@prlw/common/mobile-overlay-card/mobile-overlay-card.component';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';
import { TextTagOtpComponent } from '../monitor/text-tag-otp/text-tag-otp.component';

@NgModule({
  declarations: [BuyProductContainer, BuyProductComponent],
  imports: [
    CommonModule,
    NumberFormatModule,
    IconsModule,
    ReactiveFormsModule,
    NumberInputComponent,
    MobileOverlayCardComponent,
    ResponsiveDirective,
    TextTagOtpComponent,
  ],
})
export class BuyProductModule {}
