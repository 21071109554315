import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { mobileMenuItems } from '../mobile-menu/mobile-menu.container';
import { Router } from '@angular/router';
import { MobileFooterComponent } from './mobile-footer.component';
import { CommonModule } from '@angular/common';
import { TMobileSubmenuItem } from '../mobile-menu/mobile-menu.component';
import { Subject, takeUntil } from 'rxjs';
import { MobileOverlayService } from '@prlw/libs/mobile-overlay/mobile-overlay.service';
import { FeedbackMobileComponent } from 'src/app/features/feedback/feedback-mobile.component';

@Component({
  selector: 'prlw-mobile-footer',
  standalone: true,
  imports: [CommonModule, MobileFooterComponent],
  templateUrl: './mobile-footer.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileFooterContainer implements OnDestroy {
  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private mobileOverlayService: MobileOverlayService,
  ) {
    router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
      cdr.detectChanges();
    });
  }

  get items(): TMobileSubmenuItem[] {
    return mobileMenuItems.map((item) => ({
      ...item,
      active: this.router.url.includes(item.routerLink),
    }));
  }

  handleEventClick(item: TMobileSubmenuItem) {
    this.router.navigate([item.routerLink]);
  }

  handleShowFeedbackOverlay() {
    this.mobileOverlayService.show(FeedbackMobileComponent);
  }
}
