import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';

@Component({
  selector: 'prlw-common-pane',
  standalone: true,
  imports: [ResponsiveDirective],
  templateUrl: './common-pane.component.html',
  styleUrl: './common-pane.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonPaneComponent {
  @Input() transparent: boolean | null = null;
}
