<div
  prlwClickOutside
  class="container"
  [class.shadow]="isCollapsed"
  (toggle)="onClose()"
>
  <div class="content">
    <div class="select" [class.collapsed]="isCollapsed" (click)="onOpen()">
      <div class="arrow" [class.arrow--active]="isCollapsed">
        <prlw-icon name="triangle"></prlw-icon>
      </div>
      <div class="selected-item">
        {{ selectItem.title }}
      </div>
      <div
        class="document-item"
        [class.line]="document"
        [class.space]="isFirst"
        (click)="onSelect(document.description, document.documentId)"
        *ngFor="
          let document of documents;
          let idx = index;
          last as isLast;
          first as isFirst
        "
      >
        {{ document.description }}
      </div>
      <div class="document-item" (click)="onSelect()">
        Моего договора нет в списке
      </div>
    </div>
  </div>
</div>
