import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import {
  AUTH_DATA_CONFIG,
  AuthDataConfig,
} from '@prlw/data/auth/auth-data.config';
import {
  SignInByLoginCredentials,
  SignInByLoginGateway,
  SignInByLoginResultWithTokens,
} from '@prlw/core/auth/sign-in-by-login/sign-in-by-login.gateway';
import { SignInByLoginResponse } from '@prlw/data/auth/api-types/sign-in-by-login.api';
import {
  JwtGateway,
  JwtRefreshResult,
  JwtTokenSet,
} from '@prlw/core/auth/jwt/jwt.gateway';
import { RefreshToken } from '@prlw/core/auth/jwt/refresh-token';
import { JwtRefreshResponse } from '@prlw/data/auth/api-types/jwt-refresh.api';
import {
  SendRecoveryCodeResult,
  SetNewPasswordData,
  SetNewPasswordResult,
  ValidateCodeData,
  ValidateCodeResult,
} from '@prlw/core/auth/password-recovery/password-recovery.types';
import { SendRecoveryCodeResponse } from '@prlw/data/auth/api-types/send-recovery-code.api';
import { SetNewPasswordResponse } from '@prlw/data/auth/api-types/set-new-password.api';
import { ValidateCodeResponse } from '@prlw/data/auth/api-types/validate-code.api';
import {
  ConfirmationResult,
  SignInByPhoneGateway,
} from '@prlw/core/auth/sign-in-by-phone/sign-in-by-phone.gateway';
import {
  ConfirmationResultResponse,
  SignInByPhoneResponse,
} from '@prlw/data/auth/api-types/sign-in-by-phone';
import { PrlwAuthError } from '@prlw/infrastructure/errors/error-types/error-types';
import { AuthErrorCode } from '@prlw/infrastructure/errors/error-codes/client-error-codes';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable()
export class AuthGateway
  implements SignInByLoginGateway, SignInByPhoneGateway, JwtGateway
{
  constructor(
    @Inject(AUTH_DATA_CONFIG) private readonly config: AuthDataConfig,
    private readonly http: HttpClient,
    private readonly recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  public signIn(
    credentials: SignInByLoginCredentials,
  ): Observable<SignInByLoginResultWithTokens> {
    return this.http
      .post<SignInByLoginResponse>(
        `${this.config.apiPrefix}/login`,
        credentials,
        { headers: { SkipAuth: 'true' } },
      )
      .pipe(
        catchError(() => of({})),
        map((response) => {
          if ('accessToken' in response && 'refreshToken' in response) {
            const { accessToken, refreshToken } = response;
            return {
              success: true,
              tokens: {
                accessToken,
                refreshToken,
              },
            };
          }
          return {
            success: false,
            error: new PrlwAuthError(AuthErrorCode.SignInByLogin),
          };
        }),
      );
  }

  public refresh(token: RefreshToken): Observable<JwtRefreshResult> {
    return this.http
      .post<JwtRefreshResponse>(
        `${this.config.apiPrefix}/update-jwt`,
        { token },
        { headers: { SkipAuth: 'true' } },
      )
      .pipe(
        catchError(({ error }) => of(error as JwtRefreshResponse)),
        map((response) => {
          if ('message' in response) {
            return {
              success: false,
              error: new PrlwAuthError(response.message as AuthErrorCode),
            };
          }
          if ('accessToken' in response && 'refreshToken' in response) {
            const { accessToken, refreshToken } = response;
            return {
              success: true,
              tokens: {
                accessToken,
                refreshToken,
              },
            };
          }
          return {
            success: false,
            error: new PrlwAuthError(AuthErrorCode.JwtRefresh),
          };
        }),
      );
  }

  public takeSmsCode(phone: string): Observable<ConfirmationResult> {
    return this.recaptchaV3Service.execute('takeSmsCode').pipe(
      switchMap((token: string) => {
        const data = { phone, recaptcha: token };
        return this.http.post<ConfirmationResultResponse>(
          `${this.config.apiPrefix}/code`,
          data,
          {
            headers: { SkipAuth: 'true' },
          },
        );
      }),
      map((response) => response),
      catchError((error: HttpErrorResponse) =>
        of({ message: error.error.message }),
      ),
    );
  }

  public signInByPhone(
    code: string,
    phone: string,
  ): Observable<JwtTokenSet | JwtRefreshResult> {
    const data = { smsCode: code, phone };
    return this.http
      .post<SignInByPhoneResponse>(`${this.config.apiPrefix}/login-sms`, data, {
        headers: { SkipAuth: 'true' },
      })
      .pipe(
        catchError((error: HttpErrorResponse) =>
          of({ message: error.error.message }),
        ),
        map((response) => {
          if ('accessToken' in response && 'refreshToken' in response) {
            const { accessToken, refreshToken } = response;
            return {
              success: true,
              tokens: {
                accessToken,
                refreshToken,
              },
            };
          }
          if ('message' in response) {
            const { message } = response;
            return {
              success: false,
              error: this.getError(message),
            };
          }
          return {
            success: false,
            error: new PrlwAuthError(AuthErrorCode.SignInByPhone),
          };
        }),
      );
  }

  private getError(message: string): PrlwAuthError {
    if (message.includes('попыток')) {
      return new PrlwAuthError(AuthErrorCode.WrongConfirmationCode);
    }

    if (message.includes('Израсходованы')) {
      return new PrlwAuthError(AuthErrorCode.TooManyCodeAttempts);
    }

    return new PrlwAuthError(AuthErrorCode.ConfirmationCodeExpired);
  }

  public sendRecoveryCode(login: string): Observable<SendRecoveryCodeResult> {
    return this.recaptchaV3Service.execute('sendRecoveryCode').pipe(
      switchMap((token: string) => {
        const data = { login, recaptcha: token };

        return this.http.post<SendRecoveryCodeResponse>(
          `${this.config.apiPrefix}/send-sms-for-recovery`,
          data,
          { headers: { SkipAuth: 'true' } },
        );
      }),
      catchError((error: HttpErrorResponse) =>
        of({ message: error.error.message, statusCode: error.status }),
      ),
      map((response) => {
        if ('codesWasSent' in response && response.codesWasSent) {
          const { messageId, phone, codesWasSent, timeToNextSend } = response;
          return {
            success: true,
            data: {
              messageId,
              phone,
              codesWasSent,
              timeToNextSend,
            },
          };
        }
        return {
          success: false,
          error: new PrlwAuthError(AuthErrorCode.SendRecoveryCode),
        };
      }),
    );
  }

  public setNewPassword(
    data: SetNewPasswordData,
  ): Observable<SetNewPasswordResult> {
    return this.http
      .post<SetNewPasswordResponse>(
        `${this.config.apiPrefix}/set-new-password`,
        data,
        { headers: { SkipAuth: 'true' } },
      )
      .pipe(
        map((response) => response ?? {}),
        catchError((error: HttpErrorResponse) =>
          of({ message: error.error.message, statusCode: error.status }),
        ),
        map((response) => {
          if ('message' in response && 'statusCode' in response) {
            return {
              success: false,
              error: new PrlwAuthError(AuthErrorCode.SetNewPassword),
            };
          }
          return {
            success: true,
          };
        }),
      );
  }

  public validateCode(data: ValidateCodeData): Observable<ValidateCodeResult> {
    return this.http
      .post<ValidateCodeResponse>(
        `${this.config.apiPrefix}/validate-sms-code`,
        data,
        { headers: { SkipAuth: 'true' } },
      )
      .pipe(
        catchError((error: HttpErrorResponse) =>
          of({ message: error.error.message, statusCode: error.status }),
        ),
        map((response) => {
          if ('message' in response && 'statusCode' in response) {
            return {
              success: false,
              error: new PrlwAuthError(AuthErrorCode.ValidateCode),
            };
          }
          return {
            success: true,
            data: { token: response.token },
          };
        }),
      );
  }
}
