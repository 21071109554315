import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  xsmall$ = new BehaviorSubject(false);

  constructor(private responsive: BreakpointObserver) {
    this.responsive.observe(Breakpoints.XSmall).subscribe(({ matches }) => {
      this.xsmall$.next(matches);
    });
  }
}
