import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MARKET_TIME_DATA_CONFIG } from '@prlw/data/market-time/market-time-data-config.token';
import { MarketTimeDataConfig } from '@prlw/data/market-time/market-time-data.module';
import { Observable } from 'rxjs';
import {
  MarketTimeData,
  MarketTimeGateway,
} from '@prlw/core/market-time/market-time-gateway.interface';
import { MarketTimeResponse } from '@prlw/data/market-time/api-types/market-time-response.api';
import { LastTradingSessionDateResponse } from './api-types/last-trading-session-date-response.api';

@Injectable({
  providedIn: 'root',
})
export class HttpMarketTimeGateway implements MarketTimeGateway {
  constructor(
    @Inject(MARKET_TIME_DATA_CONFIG)
    private readonly config: MarketTimeDataConfig,
    private readonly http: HttpClient,
  ) {}

  public getTradingTime(): Observable<MarketTimeData> {
    return this.http.get<MarketTimeResponse>(this.config.apiPrefix);
  }

  public getLastTradingSessionDate() {
    return this.http.get<LastTradingSessionDateResponse>(
      '/api/calendar/last-trading-session-date',
    );
  }
}
