import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { FEEDBACK_GATEWAY } from '@prlw/core/feedback/feedback-gateway.token';
import { FEEDBACK_DATA_CONFIG } from '@prlw/data/feedback/feedback-data-config.token';
import { HttpFeedbackGateway } from '@prlw/data/feedback/feedback.gateway';

export type FeedbackDataConfig = {
  apiPrefix: string;
};

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [{ provide: FEEDBACK_GATEWAY, useClass: HttpFeedbackGateway }],
})
export class FeedbackDataModule {
  public static forRoot(
    config: FeedbackDataConfig,
  ): ModuleWithProviders<FeedbackDataModule> {
    return {
      ngModule: FeedbackDataModule,
      providers: [{ provide: FEEDBACK_DATA_CONFIG, useValue: config }],
    };
  }
}
