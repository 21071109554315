import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { DocumentView } from '@prlw/core/contracts/document-view.entity';
import { Buyer } from '@prlw/core/contracts/buyer.entity';
import { Seller } from '@prlw/core/contracts/seller.entity';
import { Station } from '@prlw/core/contracts/station.entity';
import { MinAndMaxData } from '@prlw/core/contracts/min-and-max-data.entity';
import { ContractStatus } from '@prlw/core/contracts/contract-status.entity';

export const CONTRACTS_FILTER_GATEWAY =
  new InjectionToken<ContractsFilterGateway>('contracts-filter-gateway');

export interface ContractsFilterGateway {
  getAllSellers(): Observable<Seller[]>;
  getAllBuyers(): Observable<Buyer[]>;
  getMaxAndMinAmount(): Observable<MinAndMaxData>;
  getMaxAndMinPrice(): Observable<MinAndMaxData>;
  getAllStations(): Observable<Station[]>;
  getAllDocumentView(): Observable<DocumentView[]>;
  getStatuses(): Observable<ContractStatus[]>;
}
