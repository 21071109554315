import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';

const INTERVAL = 1000;
const DEFAULT_SECONDS = 200;

export type State = {
  initial: number;
  timestamp: number;
};

@Injectable({ providedIn: 'root' })
export class TimerService {
  private readonly _state$ = new BehaviorSubject<State | null>(null);

  public getTime(): Observable<number | null> {
    return combineLatest([this._state$, timer(0, INTERVAL)]).pipe(
      map(([state]) => {
        if (state) {
          const { initial, timestamp } = state;
          const value = Math.round(
            (initial - +new Date() + timestamp) / INTERVAL,
          );
          return value >= 0 ? value : null;
        }
        return null;
      }),
    );
  }

  public setTime(seconds: number = DEFAULT_SECONDS): void {
    if (seconds) {
      this._state$.next({
        initial: seconds * INTERVAL,
        timestamp: +new Date(),
      });
    }
  }

  public clearTimer(): void {
    this._state$.next(null);
  }
}
