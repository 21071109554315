import { Pipe, PipeTransform } from '@angular/core';

const DAY = 24;
const HOUR = 60;

@Pipe({
  name: 'formatSeconds',
})
export class FormatSecondsPipe implements PipeTransform {
  public transform(value: number, variant: 'd' | 'h' | 'm' | 's'): number {
    switch (variant) {
      case 'd':
        return Math.floor(value / (HOUR * HOUR * DAY));
      case 'h':
        return Math.floor(value / (HOUR * HOUR));
      case 'm':
        return Math.floor((value % (HOUR * HOUR)) / HOUR);
      case 's':
        return value % HOUR;
      default:
        return 0;
    }
  }
}
