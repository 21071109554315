import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CONTRACTS_GATEWAY } from '@prlw/core/contracts/contracts.gateway';
import { HttpContractsGateway } from '@prlw/data/contracts/contracts-gateway';
import {
  CONTRACTS_DATA_CONFIG,
  ContractsDataConfig,
} from '@prlw/data/contracts/contracts-data.config';
import { CONTRACTS_FILTER_GATEWAY } from '@prlw/core/contracts/contracts-filter.gateway';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    { provide: CONTRACTS_GATEWAY, useClass: HttpContractsGateway },
    { provide: CONTRACTS_FILTER_GATEWAY, useClass: HttpContractsGateway },
  ],
})
export class ContractsDataModule {
  public static forRoot(
    config: ContractsDataConfig,
  ): ModuleWithProviders<ContractsDataModule> {
    return {
      ngModule: ContractsDataModule,
      providers: [{ provide: CONTRACTS_DATA_CONFIG, useValue: config }],
    };
  }
}
