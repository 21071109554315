import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { contractsTableColumns } from '@prlw/core/contracts/data/contracts.data';
import {
  ContractsTableColumn,
  ContractsTableColumnId,
} from '@prlw/core/contracts/types/contracts.types';
import { UserSettingsController } from '@prlw/core/user-settings/user-settings.controller';
import { ColumnsState } from '@prlw/libs/columns-state/columns.state';
import { Setting } from '../user-settings/setting.interface';

export const CONTRACTS_TABLE_COLUMNS_STATE = new InjectionToken(
  'contracts-table-columns-state',
);
export const CONTRACTS_TABLE_COLUMNS_SETTING = new Setting<
  ContractsTableColumn<ContractsTableColumnId>[]
>('contracts-table-columns-setting');

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: CONTRACTS_TABLE_COLUMNS_STATE,
      useFactory: (userSettingsController: UserSettingsController) =>
        new ColumnsState<ContractsTableColumnId>(
          userSettingsController,
          CONTRACTS_TABLE_COLUMNS_SETTING,
          contractsTableColumns,
        ),
      deps: [UserSettingsController],
    },
  ],
})
export class ContractsCoreModule {}
