import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { USER_SETTINGS_DATA_CONFIG } from '@prlw/data/user-settings/user-settings-data-config.token';
import { UserSettingsDataConfig } from '@prlw/data/user-settings/user-settings-data.module';
import { UserSettingsGateway } from '@prlw/core/user-settings/user-settings-gateway.interface';
import { GetSettingsByKeyResponse } from '@prlw/data/user-settings/api-types/get-settings-by-key.response';
import { UpdateSettingsByKeyResponse } from '@prlw/data/user-settings/api-types/update-settings-by-key.response';

function mapGetResponseEntity<T>(
  data: GetSettingsByKeyResponse<T>,
): T | undefined {
  // eslint-disable-next-line no-undefined
  return data?.value ?? undefined;
}

@Injectable({
  providedIn: 'root',
})
export class HttpUserSettingsGateway implements UserSettingsGateway {
  constructor(
    @Inject(USER_SETTINGS_DATA_CONFIG)
    private readonly config: UserSettingsDataConfig,
    private readonly http: HttpClient,
  ) {}

  public getSettingByKey<T>(key: string): Observable<T | undefined> {
    return this.http
      .get<GetSettingsByKeyResponse<T>>(`${this.config.apiPrefix}/${key}`)
      .pipe(map((response) => mapGetResponseEntity(response)));
  }

  public updateSettingByKey<T>(key: string, data: T): Observable<void> {
    return (
      this.http
        .put<UpdateSettingsByKeyResponse<T>>(
          `${this.config.apiPrefix}/${key}`,
          {
            data: { value: data },
          },
        )
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .pipe(map(() => {}))
    );
  }
}
