import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IPage } from '@prlw/common/page/page.interface';
import { Layout } from '@prlw/common/layout/layout.enum';
import { isProductionHost } from '@prlw/infrastructure/feature-switch';
import { ProfileController } from '@prlw/core/profile/profile.controller';
import { map } from 'rxjs';

@Component({
  selector: 'prlw-profile-page',
  templateUrl: './profile-page.html',
  styleUrls: ['./profile-page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePage implements IPage {
  public readonly headerTitle = 'Профиль';
  public readonly pageTitle = 'Профиль';
  public readonly layout = Layout.Primary;

  public get isProductionHost(): boolean {
    return isProductionHost();
  }

  constructor(private profileController: ProfileController) {}

  isEditor$ = this.profileController.profile$.pipe(
    map(({ roleEditor }) => roleEditor),
  );
}
