export type SummaryInfo = {
  checkListData: CheckList;
  organisationData: OrganizationCard;
  licenseData: LicenseCard;
};

export type CheckList = {
  isFIOConfirm: boolean;
  isEmailConfirm: boolean;
  isOrganizationConfirm: boolean;
  isOrderSign: boolean;
  isOrderSent: boolean;
};

export type OrganizationCard = {
  organizationName: string;
  dealsAmount: number;
  sellVolume: number;
  isPRO: boolean;
  orderStatus: OrderStatus;
  organizationStatus: OrganizationStatus;
};

export type LicenseCard = {
  licenceType: LicenseType;
  expiresDay: number;
};

export enum OrderStatus {
  SIGNED = 'SIGNED',
  NOT_SIGN = 'NOT_SIGNED',
}

export enum LicenseType {
  PROLEUM_DEMO = 'PROLEUM Demo',
  PROLEUM_LIGHT = 'PROLEUM Light',
  PROLEUM_PRO = 'PROLEUM Pro',
}

export enum OrganizationStatus {
  EXIST = 0,
  NOT_REGISTERED = 1,
  NOT_USER_ACCOUNT = 2,
}
