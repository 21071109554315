import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketTimeContainer } from '@prlw/common/market/market-time/market-time.container';
import { MarketTimeComponent } from '@prlw/common/market/market-time/market-time.component';
import { MarketTimeTooltipComponent } from '@prlw/common/market/market-time/market-time-tooltip/market-time-tooltip.component';
import { MarketTimeDirective } from './market-time.directive';
import { TimeFormatModule } from '@prlw/infrastructure/time-format/time-format.module';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';

@NgModule({
  declarations: [
    MarketTimeContainer,
    MarketTimeComponent,
    MarketTimeTooltipComponent,
    MarketTimeDirective,
  ],
  exports: [MarketTimeContainer, MarketTimeComponent],
  imports: [CommonModule, TimeFormatModule, ResponsiveDirective],
})
export class MarketTimeUiModule {}
