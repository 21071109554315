<div class="container">
  <h1 class="title">Личные данные</h1>

  <form [formGroup]="personalDataForm">
    <div class="field">
      <label class="label" for="surname">Фамилия</label>
      <input
        class="input"
        id="surname"
        type="text"
        formControlName="surname"
        data-cy="surname"
        placeholder="Введите фамилию"
      />
    </div>
    <div class="field">
      <label class="label" for="name">Имя</label>
      <input
        class="input"
        id="name"
        type="text"
        formControlName="name"
        placeholder="Введите имя"
        data-cy="name"
      />
    </div>
    <div class="field">
      <label class="label" for="patronymic">Отчество</label>
      <input
        class="input"
        id="patronymic"
        type="text"
        formControlName="patronymic"
        placeholder="Введите отчество"
        data-cy="patronymic"
      />
    </div>

    <ng-container *ngIf="isInit()">
      <div class="field">
        <label class="label" for="initEmail">E-mail</label>
        <div class="email-container">
          <input
            [class.validation-error]="emailError"
            [class.email-input]="personalData.isEmailEditable"
            class="input"
            id="initEmail"
            type="text"
            formControlName="email"
            placeholder="Введите E-mail"
            data-cy="email"
          />
          <button
            *ngIf="personalData?.isEmailEditable"
            [disabled]="personalDataForm.get('email')?.invalid"
            (click)="codeRequest()"
            class="apply-btn email"
          >
            Подтвердить
          </button>
        </div>
      </div>
      <div
        *ngIf="emailError"
        class="validation-container"
        data-cy="email-error"
      >
        Проверьте, пожалуйста, введенный e-mail
      </div>
    </ng-container>

    <ng-container *ngIf="isConfirmByCode()">
      <div class="field">
        <label class="label" for="confirmByCode">E-mail</label>
        <div class="email-container">
          <input
            class="input email-input with-code"
            id="confirmByCode"
            type="text"
            required
            formControlName="email"
            placeholder="Введите E-mail"
            data-cy="email"
          />
          <input
            class="input email-input code-field"
            type="text"
            required
            formControlName="code"
            placeholder="Код из письма"
            data-cy="code"
          />
          <button
            [disabled]="personalDataForm.get('code')?.invalid"
            (click)="codeConfirmRequest()"
            class="apply-btn email"
          >
            Готово
          </button>
        </div>
      </div>

      <div class="validation-container">
        <span>
          Вам на почту был отправлен код подтверждения. Пожалуйста, введите его
          в соответствующее поле
        </span>
        <button
          class="change-email-or-code"
          (click)="onChangeEmail()"
          data-cy="change-email"
          type="button"
        >
          Ввести другой e-mail
        </button>
        <button
          *ngIf="reSendTimer"
          (click)="onReSendCode()"
          class="change-email-or-code"
          type="button"
        >
          Получить новый код
        </button>
        <div class="new-code" *ngIf="!reSendTimer" data-cy="new-code">
          Вы можете получить новый код через
          {{ nextSendTimer | formatSeconds: 'm' | number: '2.0' }}
          : {{ nextSendTimer | formatSeconds: 's' | number: '2.0' }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isConfirmed()">
      <div class="field">
        <label class="label" for="confirmedEmail">E-mail</label>
        <div class="email-container">
          <input
            class="input"
            id="confirmedEmail"
            type="text"
            required
            formControlName="email"
            data-cy="email"
          />
          <span class="email-confirmed">Подтвержден</span>
        </div>
      </div>
    </ng-container>

    <div class="field">
      <label class="label" for="phone">Телефон</label>
      <input
        class="input"
        id="phone"
        type="text"
        required
        prefix="+7   "
        mask="000  000  00  00"
        [showMaskTyped]="true"
        autocomplete="disabled"
        formControlName="phone"
        placeholder="Введите телефон"
        data-cy="phone"
      />
    </div>
  </form>

  <button
    *ngIf="
      personalData?.isPhoneEditable ||
      personalData?.isEmailEditable ||
      personalData?.isFullNameEditable
    "
    (click)="submit()"
    [disabled]="checkValidity()"
    class="apply-btn"
  >
    Сохранить
  </button>

  <p style="color: var(--prlw-color-tooltip-label)">
    Изменение данных возможно через
    <span (click)="showModal()" class="change-email-or-code">
      через службу поддержки
    </span>
  </p>
</div>
