import { SmallWholesaleOrder } from './small-wholesale-order.type';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Region } from './region.type';

export const SMALL_WHOLESALE_GATEWAY =
  new InjectionToken<SmallWholesaleGateway>('SmallWholesaleGateway');

export interface SmallWholesaleGateway {
  getRegions(): Observable<Region[]>;
  submitOrder(order: SmallWholesaleOrder): Observable<void>;
}
