import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { JwtRefreshResult, JwtTokenSet } from '@prlw/core/auth/jwt/jwt.gateway';
import { PrlwAuthError } from '@prlw/infrastructure/errors/error-types/error-types';

export const SIGN_IN_BY_PHONE_GATEWAY =
  new InjectionToken<SignInByPhoneGateway>('sign-in-by-phone-gateway');

export type SignInByPhoneState = {
  codeWasSent: boolean;
};

export type ConfirmationResult =
  | {
      codesWasSent: boolean;
      timeToNextSend: number;
    }
  | { message?: ErrorStatus };

export enum ErrorStatus {
  USER_NOT_FOUND = '11',
  DEMO_EXPIRE = '12',
}
export type SignInByPhone = {
  phone: string;
  code: string;
};

export type SignInByPhoneResult =
  | { success: true }
  | { success: false; error: PrlwAuthError };

export interface SignInByPhoneGateway {
  takeSmsCode(phone: string): Observable<ConfirmationResult>;

  signInByPhone(
    phone: string,
    code: string,
  ): Observable<JwtTokenSet | JwtRefreshResult>;
}
