import { InjectionToken } from '@angular/core';
import { TOpen } from '@prlw/libs/event-source/event-source.service';
import { Observable } from 'rxjs';

export const SSE_GATEWAY = new InjectionToken<SseGateway>('sse-gateway');

export interface IChannelUpdateTariff {
  dataType: 'IChannelUpdateTariff';
  userId: string;
  instrumentCode: string;
  price: number;
}

export interface IChannelUpdateTraderTariff {
  dataType: 'IChannelUpdateTraderTariff';
  destinationStationId: number;
}

export interface IChannelUpdateQuote {
  dataType: 'IChannelUpdateQuote';
  instrumentCode: string;
  timestamp: string;
  bestBuyPrice: number;
  bestBuyAmount: number;
  bestSellPrice: number;
  bestSellAmount: number;
}

export interface IChannelUpdateDeal {
  dataType: 'IChannelUpdateDeal';
  dealId: string;
  instrumentCode: string;
  timestamp: string;
  price: number;
  amount: number;
}

export interface IChannelUpdateMarketDepth {
  dataType: 'IChannelUpdateMarketDepth';
  feed: 'SPIMEX_STE';
  SID: string;
  BID_VOL_UNIT: number | null;
  BID_VOL_UNIT_1: number | null;
  BID_VOL_UNIT_2: number | null;
  BID_VOL_UNIT_3: number | null;
  BID_VOL_UNIT_4: number | null;
  ASK_VOL_UNIT: number | null;
  ASK_VOL_UNIT_1: number | null;
  ASK_VOL_UNIT_2: number | null;
  ASK_VOL_UNIT_3: number | null;
  ASK_VOL_UNIT_4: number | null;
  BID: number | null;
  BID_1: number | null;
  BID_2: number | null;
  BID_3: number | null;
  BID_4: number | null;
  ASK: number | null;
  ASK_1: number | null;
  ASK_2: number | null;
  ASK_3: number | null;
  ASK_4: number | null;
}

export interface SseGateway {
  unicastOpened$: Observable<TOpen>;
  messageIChannelUpdateTraderTariff$: Observable<IChannelUpdateTraderTariff>;
  unicastMessage$: Observable<IChannelUpdateTariff[]>;
  multicastMessage$: Observable<
    (
      | IChannelUpdateTraderTariff
      | IChannelUpdateQuote
      | IChannelUpdateDeal
      | IChannelUpdateMarketDepth
    )[]
  >;
}
