<div class="container">
  <div class="header-container">
    <prlw-title-logo></prlw-title-logo>
    <prlw-title-profile></prlw-title-profile>
    <svg
      (click)="eventClose.emit()"
      class="cursor x-icon"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21Z"
        fill="white"
      />
      <rect
        x="16.1212"
        y="14"
        width="17"
        height="3"
        rx="1.5"
        transform="rotate(45 16.1212 14)"
        fill="black"
      />
      <rect
        x="13.9999"
        y="26.0208"
        width="17"
        height="3"
        rx="1.5"
        transform="rotate(-45 13.9999 26.0208)"
        fill="black"
      />
    </svg>
  </div>
  <div class="menu-item">
    <prlw-title-label title="Маркет монитор"></prlw-title-label>
    <div
      *ngFor="let item of items"
      class="submenu-item cursor"
      [class.active]="item.active"
      (click)="eventMobileSubmenuItemClick.emit(item)"
    >
      <div class="icon-container" [class.active]="item.active">
        <prlw-icon
          [name]="item.icon"
          class="icon"
          [class.active]="item.active"
        ></prlw-icon>
      </div>
      <div class="submenu-item-title">{{ item.title }}</div>
    </div>
  </div>
  <div class="menu-item item-title disabled">Личный кабинет ПРОЛЕУМ</div>
  <div class="menu-item item-title disabled">Блокчейн ПРОЛЕУМ</div>
  <div class="footer-container">
    <prlw-mobile-button
      label="Обратная связь"
      color="white"
      (click)="handleMobileButtonFeedbackClick()"
    >
    </prlw-mobile-button>
  </div>
</div>
