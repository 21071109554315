export enum ContractStatusType {
  DRAFT = 0,
  WAITING_SIGN = 1,
  SIGNED = 2,
  WAITING_TERMINATE = 3,
  TERMINATED = 4,
  ANNULLED = 5,
  CANCELED = 6,
}

export type ContractStatus = {
  description: string;
  type: ContractStatusType;
};
