<div class="container">
  <ng-container *ngIf="license$ | async as license">
    <prlw-license-header></prlw-license-header>
    <prlw-license-docs-alert></prlw-license-docs-alert>
    <prlw-license-alert [demo]="license"></prlw-license-alert>
    <prlw-license-cards *ngIf="license.type === 'DEMO'"></prlw-license-cards>
    <prlw-license-cards
      *ngIf="license.type === 'LIGHT'"
      [isLight]="true"
    ></prlw-license-cards>
    <prlw-license-card-pro
      *ngIf="license.type === 'PRO'"
      [accessToPro]="license"
    ></prlw-license-card-pro>
  </ng-container>
  <ng-container *ngIf="hasHistory$ | async">
    <prlw-license-history></prlw-license-history>
  </ng-container>
</div>
