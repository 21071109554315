<button
  [disabled]="disabled"
  [class]="'button ' + getColor()"
  [class.border]="border"
  (click)="eventClick.emit()"
>
  <span class="label">
    {{ label }}
    <span *ngIf="greyLabel">:</span>
    <span *ngIf="greyLabel" class="grey-label">&nbsp;{{ greyLabel }}</span>
  </span>
</button>
