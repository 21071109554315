<h2>Добавить организацию</h2>
<p class="steps">Шаг {{ step }} из 2</p>

<ng-container *ngIf="step === 1">
  <input
    class="inn"
    test-id="inn"
    type="number"
    placeholder="Введите ИНН"
    (keypress)="filterNumbers($event)"
    [(ngModel)]="inn"
    [ngModelOptions]="{ standalone: true }"
  />
  <button test-id="step-1" (click)="advanceToStep2()">Продолжить</button>
  <p class="notice">
    Добавление организации в аккаунт позволит вам заключать быстрые и выгодные
    сделки на платформе. Также вы сможете получать персональные предложения и
    актуальную информацию о ценах на основании данных о вашей компании.
  </p>
</ng-container>

<ng-container *ngIf="step === 2">
  <h3>Проверьте правильность данных</h3>

  <prlw-profile-organization-details-ui
    [displayName]="true"
  ></prlw-profile-organization-details-ui>

  <div class="actions">
    <button test-id="step-2" (click)="confirmOrganization.emit()">
      Подтвердить
    </button>
    <a (click)="step = 1">Ввести другой ИНН</a>
  </div>
</ng-container>
