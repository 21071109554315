import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ResponsiveService } from './responsive.service';
import { Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[prlwResponsive]',
  standalone: true,
})
export class ResponsiveDirective implements OnInit, OnDestroy {
  constructor(
    private service: ResponsiveService,
    private cdr: ChangeDetectorRef,
  ) {}

  @HostBinding('class.xsmall')
  xsmallClass = false;

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.service.xsmall$.pipe(takeUntil(this.destroy$)).subscribe((matches) => {
      this.xsmallClass = matches;
      this.cdr.detectChanges();
    });
  }
}
