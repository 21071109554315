export enum License {
  Demo = 'DEMO',
  Light = 'LIGHT',
  Pro = 'PRO',
}

export type LicenseHistory = {
  startPeriod: string;
  endPeriod: string;
  type: string;
  invoiceStatus: string;
  documentInvoice: string;
  documentAct?: string;
};

export type LicenseTypeDemo = {
  type: License.Demo;
  active: boolean;
  accessInDate: string;
  accessInDays: number;
};

export type LicenseTypeLight = {
  type: License.Light;
  active: boolean;
  accessInDate: string;
  accessInDays: number;
};

export type LicenseTypePro = {
  type: License.Pro;
  active: boolean;
  accessInDate: string;
  accessInDays: number;
};

export type LicenseStatus = LicenseTypeDemo | LicenseTypeLight | LicenseTypePro;
