<ng-container>
  <prlw-attachments-files-ui
    (addFile)="addFile($event)"
    (deleteFile)="deleteFile($event)"
    (sendComment)="sendComment($event)"
    (sendFile)="sendFile()"
    [files$]="files$"
    [status$]="status$"
  >
  </prlw-attachments-files-ui>
</ng-container>
