<div class="statusbar feedback" prlwResponsive>
  <button
    class="status-button feedback"
    aria-label="Оставить отзыв"
    (click)="eventShowFeedbackDialog.emit()"
  >
    <span *ngIf="(xsmall$ | async) === false">Обратная связь и помощь</span>
    <prlw-icon
      name="feedback-help"
      class="feedback-help-icon"
      prlwResponsive
    ></prlw-icon>
  </button>
</div>
