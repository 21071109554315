import { Inject, Injectable } from '@angular/core';
import { ANALYTICS_CHARTS_GATEWAY } from './analytics-charts-gateway.token';
import { AnalyticsChartsGateway } from './analytics-charts-gateway.interface';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsChartsController {
  constructor(
    @Inject(ANALYTICS_CHARTS_GATEWAY)
    private readonly gateway: AnalyticsChartsGateway,
  ) {}

  public getProductPickerItems() {
    return this.gateway.getProductPickerItems();
  }

  public getProductPickerCargoItems() {
    return this.gateway.getProductPickerCargoItems();
  }

  public getPriceComparisonItems(
    productGroupNames: string[],
    startDate: string,
    endDate: string,
  ) {
    return this.gateway.getPriceComparisonItems(
      productGroupNames,
      startDate,
      endDate,
    );
  }

  public getDynamicsOfVolumesItems(
    productGroupNames: string[],
    startDate: string,
    endDate: string,
  ) {
    return this.gateway.getDynamicsOfVolumesItems(
      productGroupNames,
      startDate,
      endDate,
    );
  }

  public getDynamicsByPlantsItems(
    productGroupNames: string[],
    startDate: string,
    endDate: string,
    primary: boolean,
    secondary: boolean,
    f: boolean,
    w: boolean,
    u: boolean,
  ) {
    return this.gateway.getDynamicsByPlants(
      productGroupNames,
      startDate,
      endDate,
      primary,
      secondary,
      f,
      w,
      u,
    );
  }

  public getDynamicsByBidsItems(
    productGroupNames: string[],
    startDate: string,
    endDate: string,
    primary: boolean,
    secondary: boolean,
    f: boolean,
    w: boolean,
    u: boolean,
  ) {
    return this.gateway.getDynamicsByBids(
      productGroupNames,
      startDate,
      endDate,
      primary,
      secondary,
      f,
      w,
      u,
    );
  }

  public getCargoFlowItems(productCargoGroupNames: string[]) {
    return this.gateway.getCargoFlowItems(productCargoGroupNames);
  }
}
