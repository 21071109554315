import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Organization } from '@prlw/core/profile/organization.entity';

@Component({
  selector: 'prlw-profile-organization-details-ui',
  templateUrl: './profile-organization-details.component.html',
  styleUrls: ['./profile-organization-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileOrganizationDetailsComponent {
  @Input() public organization!: Organization;
  @Input() public displayName = false;
}
