import {
  AllDealsTableColumn,
  AllDealsTableColumnId,
  AllDealsTableSorting,
} from '@prlw/core/deals/types/all-deals.types';

export const allDealsTableColumns: AllDealsTableColumn<AllDealsTableColumnId>[] =
  [
    {
      name: 'Продукт',
      id: AllDealsTableColumnId.PRODUCT,
      isVisible: true,
      isStatic: true,
      cellSizeMin: '120px',
      cellSizeMax: '1fr',
    },
    {
      name: 'Базис',
      id: AllDealsTableColumnId.BASIS,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '120px',
      cellSizeMax: '1fr',
      cellFixed: true,
    },
    {
      name: 'Объем',
      id: AllDealsTableColumnId.VOLUME,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '120px',
      cellSizeMax: '1fr',
      cellFixed: true,
    },
    {
      name: 'Цена',
      id: AllDealsTableColumnId.PRICE,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '120px',
      cellSizeMax: '1fr',
      cellFixed: true,
    },
    {
      name: 'Стоимость',
      id: AllDealsTableColumnId.TOTAL_PRICE,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '120px',
      cellSizeMax: '1fr',
      cellFixed: true,
    },
    {
      name: '№ сделки',
      id: AllDealsTableColumnId.DEAL_NUMBER,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '120px',
      cellSizeMax: '1fr',
      cellFixed: true,
    },
    {
      name: 'Время',
      id: AllDealsTableColumnId.CREATE_TIME,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '120px',
      cellSizeMax: '1fr',
      cellFixed: true,
    },
    {
      name: 'Дата',
      id: AllDealsTableColumnId.CREATE_DATE,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '120px',
      cellSizeMax: '1fr',
      cellFixed: true,
    },
    {
      name: 'Код инструмента',
      id: AllDealsTableColumnId.INSTRUMENT_CODE,
      isVisible: true,
      isStatic: false,
      cellSizeMin: '120px',
      cellSizeMax: '1fr',
    },
  ];

export const allDealsTableSortDefaults: AllDealsTableSorting[] = [
  { columnId: AllDealsTableColumnId.PRODUCT, direction: 'ASC' },
  { columnId: AllDealsTableColumnId.BASIS, direction: 'ASC' },
  { columnId: AllDealsTableColumnId.VOLUME, direction: 'ASC' },
  { columnId: AllDealsTableColumnId.PRICE, direction: 'ASC' },
  { columnId: AllDealsTableColumnId.TOTAL_PRICE, direction: 'ASC' },
  { columnId: AllDealsTableColumnId.DEAL_NUMBER, direction: 'ASC' },
  { columnId: AllDealsTableColumnId.CREATE_TIME, direction: 'ASC' },
  { columnId: AllDealsTableColumnId.CREATE_DATE, direction: 'ASC' },
  { columnId: AllDealsTableColumnId.INSTRUMENT_CODE, direction: 'ASC' },
];
