import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { OverflowFaderFactory } from '@prlw/libs/overflow-fader/overflow-fader-factory.service';
import { OverflowFader } from '@prlw/libs/overflow-fader/overflow-fader.class';
import { Observable } from 'rxjs';

@Directive({
  selector: '[prlwOverflowFader]',
  standalone: true,
})
export class OverflowFaderDirective implements OnDestroy {
  public isOverflowRight$: Observable<boolean>;
  public isOverflowLeft$: Observable<boolean>;
  public isOverflowTop$: Observable<boolean>;
  public isOverflowBottom$: Observable<boolean>;
  private readonly overflowFader: OverflowFader;
  constructor(
    overflowFaderFactory: OverflowFaderFactory,
    cdr: ChangeDetectorRef,
    private readonly element: ElementRef,
  ) {
    this.overflowFader = overflowFaderFactory.create(cdr);
    this.isOverflowRight$ = this.overflowFader.isOverflowRight$;
    this.isOverflowLeft$ = this.overflowFader.isOverflowLeft$;
    this.isOverflowTop$ = this.overflowFader.isOverflowTop$;
    this.isOverflowBottom$ = this.overflowFader.isOverflowBottom$;

    this.isOverflowRight$.subscribe((i) => {
      // console.log('isOverflowRight$', i);

      if (i) {
        element.nativeElement.classList.add('fade-right');
      } else {
        element.nativeElement.classList.remove('fade-right');
      }
    });
    this.isOverflowLeft$.subscribe((i) => {
      // console.log('isOverflowLeft$', i);
      if (i) {
        element.nativeElement.classList.add('fade-left');
      } else {
        element.nativeElement.classList.remove('fade-left');
      }
    });
    this.isOverflowTop$.subscribe((i) => {
      if (i) {
        element.nativeElement.classList.add('fade-top');
      } else {
        element.nativeElement.classList.remove('fade-top');
      }
    });
    this.isOverflowBottom$.subscribe((i) => {
      if (i) {
        element.nativeElement.classList.add('fade-bottom');
      } else {
        element.nativeElement.classList.remove('fade-bottom');
      }
    });

    this.overflowFader.init({
      elemX: this.element.nativeElement,
      elemY: this.element.nativeElement,
    });
  }

  @HostListener('scroll', ['$event'])
  public onScroll(): void {
    this.overflowFader.setScrollHorizontalPosition(
      this.element.nativeElement.scrollLeft,
    );
    this.overflowFader.setScrollVerticalPosition(
      this.element.nativeElement.scrollTop,
    );
  }

  public ngOnDestroy(): void {
    this.overflowFader.destroy();
  }
}
