import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'prlw-file-upload-status',
  templateUrl: './file-upload-status.component.html',
  styleUrls: ['./file-upload-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadStatusComponent {
  @Input() public status!: string;
  public textSuccess =
    'Ваша заявка на проверку оплаты лицензии успешно отправлена';
  public textFailed = 'Сервер не отвечает, попробуйте позднее';

  public get statusText(): string {
    return this.status === 'success' ? this.textSuccess : this.textFailed;
  }
}
