import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TariffsGateway } from '@prlw/core/tariffs/tariffs.gateway';

@Injectable({
  providedIn: 'root',
})
export class HttpTariffsGateway implements TariffsGateway {
  constructor(private readonly httpClient: HttpClient) {}

  public updateTraderTariff(params: {
    productId: number;
    shipmentBasisId: number;
    deliveryMethod: string;
    destinationStationId: number;
    price: number;
    changeSimilarProductsTariff: boolean;
  }) {
    return this.httpClient.post<string>(
      `api/tariff/trader-tariff`,
      {
        ...params,
        amount: 60,
        timestamp: new Date(),
      },
      {
        responseType: 'text' as 'json',
      },
    );
  }

  public deleteTraderTariff(params: {
    productId: number;
    shipmentBasisId: number;
    deliveryMethod: string;
    destinationStationId: number;
    changeSimilarProductsTariff: boolean;
  }) {
    return this.httpClient.delete<string>(`api/tariff/trader-tariff`, {
      body: {
        ...params,
      },
      responseType: 'text' as 'json',
    });
  }

  public updateWaitStatusTariff(
    params: {
      instrumentCode: string;
      productId: number;
      shipmentBasisId: number;
      destinationStationId: number;
    }[],
  ) {
    return this.httpClient.post<string>(
      `api/tariff/publish-update-tariff`,
      params,
      {
        responseType: 'text' as 'json',
      },
    );
  }
}
