import { Injectable } from '@angular/core';
import {
  Destination,
  DestinationEntity,
  DestinationEntityV1,
  DestinationStatus,
  isDestinationEntityV1,
  migrateToDestinationEntityV2,
} from '@prlw/core/destinations/destination.entity';
import { UserSettingsController } from '@prlw/core/user-settings/user-settings.controller';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { Setting } from '../user-settings/setting.interface';

const DESTINATIONS_FILTER_SETTING = new Setting<
  (DestinationEntity | DestinationEntityV1)[]
>('destinations-filter');

@Injectable({
  providedIn: 'root',
})
export class DestinationState {
  public readonly destinations$: Observable<DestinationEntity[]>;

  constructor(private readonly userSettingsController: UserSettingsController) {
    this.destinations$ = this.userSettingsController
      .setting$(DESTINATIONS_FILTER_SETTING, [])
      .pipe(
        map((destinations) =>
          destinations.map((dest) =>
            isDestinationEntityV1(dest)
              ? migrateToDestinationEntityV2(dest)
              : dest,
          ),
        ),
      );
  }

  public get currentDestination$(): Observable<Destination | null> {
    return this.destinations$.pipe(
      map((destinations) => {
        const currentDestination = destinations.find((i) => i.flags.isActive);
        return currentDestination ? currentDestination.destination : null;
      }),
    );
  }

  public setDestination(
    destination: DestinationEntity,
    status: DestinationStatus,
  ): void {
    this.isInState(destination)
      .pipe(first())
      .subscribe((inState) => {
        if (inState) {
          this.removeOrChangeStatus(destination, status);
        } else {
          this.setInState(destination, status);
        }
      });
  }

  private isInState(destination: DestinationEntity): Observable<boolean> {
    return this.destinations$.pipe(
      first(),
      map((state) =>
        Boolean(
          state.find(
            (el) => el.destination.name === destination.destination.name,
          ),
        ),
      ),
    );
  }

  private setInState(
    destination: DestinationEntity,
    status: DestinationStatus,
  ): void {
    this.destinations$
      .pipe(
        first(),
        switchMap((state) => {
          let newState = state;
          destination.flags[status] = true;

          if (status === DestinationStatus.IS_ACTIVE) {
            newState = this.removeActiveStatus(newState);
            destination.flags[DestinationStatus.IS_WORK] = true;
          }

          const sortDestinations = this.sort([destination, ...newState]);
          return this.userSettingsController.updateSetting(
            DESTINATIONS_FILTER_SETTING,
            sortDestinations,
          );
        }),
      )
      .subscribe();
  }

  private removeOrChangeStatus(
    destination: DestinationEntity,
    status: DestinationStatus,
  ): void {
    this.destinations$
      .pipe(
        first(),
        switchMap((state) => {
          let newState = state;
          const index = newState.findIndex(
            (el) => el.destination.name === destination.destination.name,
          );
          const isWorkOrFavorite =
            status === DestinationStatus.IS_FAVORITES
              ? newState[index].flags.isInWork
              : newState[index].flags.isFavorites;

          if (isWorkOrFavorite || status === DestinationStatus.IS_ACTIVE) {
            if (status === DestinationStatus.IS_ACTIVE) {
              newState = this.removeActiveStatus(newState);
            }
            newState.forEach((entity) => {
              if (entity.destination.name === destination.destination.name) {
                if (
                  status === DestinationStatus.IS_WORK &&
                  entity.flags.isActive
                ) {
                  entity.flags.isActive = false;
                }
                if (status !== DestinationStatus.IS_WORK) {
                  entity.flags.isInWork = true;
                }
                entity.flags[status] = !entity.flags[status];
              }
              return entity;
            });
            return this.userSettingsController.updateSetting(
              DESTINATIONS_FILTER_SETTING,
              newState,
            );
          }
          newState.splice(index, 1);
          return this.userSettingsController.updateSetting(
            DESTINATIONS_FILTER_SETTING,
            newState,
          );
        }),
      )
      .subscribe();
  }

  private removeActiveStatus(
    destinations: DestinationEntity[],
  ): DestinationEntity[] {
    return destinations.map((entity) => {
      if (entity.flags.isActive) {
        entity.flags.isActive = false;
      }
      return entity;
    });
  }

  private sort(destinations: DestinationEntity[]): DestinationEntity[] {
    return destinations.sort((prev, curr) =>
      prev.destination.name > curr.destination.name ? 1 : -1,
    );
  }
}
