import { Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { fromEvent, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  mapTo,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { DemoAccessContainer } from './demo-access.container';
import { ResponsiveService } from '@prlw/libs/responsive/responsive.service';

@Directive({
  selector: '[prlwDemoAccessOverlay]',
})
export class DemoAccessOverlayDirective implements OnDestroy {
  private _overlayRef?: OverlayRef;
  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly overlay: Overlay,
    private readonly responsiveService: ResponsiveService,
  ) {}

  xsmall$ = this.responsiveService.xsmall$;

  @HostListener('mouseenter')
  public open(): void {
    if (this.xsmall$.getValue()) {
      return;
    }

    if (this._overlayRef?.hasAttached()) {
      return;
    }
    const overlayConfig = new OverlayConfig({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this._elementRef.nativeElement)
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ])
        .withPush(false),
    });
    this._overlayRef = this.overlay.create(overlayConfig);
    const modalPortal = new ComponentPortal(DemoAccessContainer);
    const ref = this._overlayRef.attach(modalPortal);
    ref.instance.closeModal
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.close());
    this._overlayRef
      .keydownEvents()
      .pipe(takeUntil(this._destroy$))
      .subscribe((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.close();
        }
      });

    this._onMouseLeave(
      this._elementRef.nativeElement,
      ref.location.nativeElement,
    );
  }

  public close(): void {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
    this._destroy$.next();
  }

  public ngOnDestroy(): void {
    this.close();
    this._destroy$.complete();
  }

  private _onMouseLeave(
    buttonElement: HTMLElement,
    overlayElement: HTMLElement,
  ): void {
    const mouseleaveButton$ = fromEvent(buttonElement, 'mouseleave');
    const mouseleaveOverlay$ = fromEvent(overlayElement, 'mouseleave');
    const mouseoverOverlay$ = fromEvent(overlayElement, 'mouseover').pipe(
      mapTo(true),
      startWith(false),
    );

    mouseleaveButton$
      .pipe(
        switchMap(() => mouseoverOverlay$),
        distinctUntilChanged(),
        debounceTime(0),
        takeUntil(this._destroy$),
      )
      .subscribe((isMouseOver) => {
        if (!isMouseOver) {
          this.close();
        }
      });
    mouseleaveOverlay$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.close());
  }
}
