import { Injectable } from '@angular/core';
import { JwtRefresher } from '@prlw/core/auth/jwt/jwt-refresher';
import { Observable } from 'rxjs';
import { filter, first, mapTo } from 'rxjs/operators';
import { AuthTokens, JwtStorage } from './jwt/jwt.storage';

export function initAuth(
  initializer: AuthInitializer,
): () => Promise<boolean | undefined> {
  return () => initializer.init();
}

function isAccessTokenNotAcquiredYet(tokens: AuthTokens): boolean {
  return tokens.accessToken === null && tokens.refreshToken !== null;
}

@Injectable({ providedIn: 'root' })
export class AuthInitializer {
  constructor(
    private readonly jwtRefresher: JwtRefresher,
    private readonly jwtStorage: JwtStorage,
  ) {}

  public init(): Promise<boolean | undefined> {
    this.jwtRefresher.init();
    return this._waitForStableAuthState().toPromise();
  }

  private _waitForStableAuthState(): Observable<boolean> {
    return this.jwtStorage.tokens$.pipe(
      filter((tokens) => !isAccessTokenNotAcquiredYet(tokens)),
      mapTo(true),
      first(),
    );
  }
}
