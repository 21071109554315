import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DestinationsGateway } from '@prlw/core/destinations/destinations.gateway';
import { DestinationEntity } from '@prlw/core/destinations/destination.entity';
import { FindDestinationsResponse } from '@prlw/data/destinations/api-types/find-destinations.api';
import {
  DestinationsDataConfig,
  DESTINATIONS_DATA_CONFIG,
} from '@prlw/data/destinations/destinations-data.config';

@Injectable()
export class HttpDestinationsGateway implements DestinationsGateway {
  constructor(
    @Inject(DESTINATIONS_DATA_CONFIG)
    private readonly config: DestinationsDataConfig,
    private readonly http: HttpClient,
  ) {}

  public findDestinations(query: string): Observable<DestinationEntity[]> {
    const params = { prefix: query };
    return this.http
      .get<FindDestinationsResponse>(`${this.config.apiPrefix}/stations`, {
        params,
      })
      .pipe(
        map((destinations) =>
          destinations.map((item) => ({
            destination: { id: item.stationId, name: item.name },
            flags: {
              isActive: false,
              isInWork: false,
              isFavorites: false,
            },
          })),
        ),
      );
  }
}
