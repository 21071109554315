import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileController } from '@prlw/core/profile/profile.controller';
import { LicenseDocuments } from '@prlw/core/profile/profile-license-documents.entity';
import { FileUploadController } from '@prlw/core/profile/file-upload.controller';
import { DocumentInfo } from '@prlw/core/profile/file-upload.entity';

@Component({
  selector: 'prlw-uploaded-documents',
  templateUrl: './uploaded-documents-container.html',
  styleUrls: ['./uploaded-documents-container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadedDocumentsContainer {
  public invoiceDocuments$: Observable<LicenseDocuments[]>;

  constructor(
    private readonly profileController: ProfileController,
    private readonly fileUploadController: FileUploadController,
  ) {
    this.invoiceDocuments$ = profileController.invoiceDocuments$;
  }

  public sendDocument(document: DocumentInfo): void {
    this.fileUploadController.setDocument(document);
  }
}
