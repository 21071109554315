import { NgModule } from '@angular/core';

import { MarketTimeUiModule } from '@prlw/common/market/market-time/market-time-ui.module';

@NgModule({
  declarations: [],
  exports: [MarketTimeUiModule],
  imports: [MarketTimeUiModule],
})
export class MarketUiModule {}
