<h2>{{ organization.name }}</h2>
<h3 class="regulations" *ngIf="areRegulationsSigned; else regulationsNotSigned">
  <span>Вы присоединились к регламенту</span>
  <prlw-icon
    name="popup-hint"
    (mouseenter)="regulationsHintVisible = true"
    (mouseleave)="regulationsHintVisible = false"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
  ></prlw-icon>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="regulationsHintVisible"
  >
    <div class="regulations-hint">
      Регламент — основной документ платформы PROLEUM, который описывает права и
      обязанности всех ее участников. Подписывая его, вы получаете возможность
      заключать сделки по приобретению нефтепродуктов и СУГ у поставщиков
      платформы.
    </div>
  </ng-template>
</h3>
<ng-template #regulationsNotSigned>
  <h3 class="regulations not-signed">Вы не присоединились к регламенту</h3>
  <p class="notice">
    Добавление организации в аккаунт позволит вам заключать быстрые и выгодные
    сделки на платформе. Также вы сможете получать персональные предложения и
    актуальную информацию о ценах на основании данных о вашей компании.
  </p>
  <button>Узнать подробности</button>
</ng-template>

<prlw-profile-organization-details-ui
  class="profile-details"
  [organization]="organization"
></prlw-profile-organization-details-ui>
