import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TParams } from './user-list-tab.container';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TGetUserListItems } from '@prlw/core/profile/profile-gateway.interface';
import { ActionButtonContainer } from '@prlw/common/action-button/action-button.container';

@Component({
  selector: 'prlw-user-list-tab-ui',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    ActionButtonContainer,
  ],
  templateUrl: './user-list-tab.component.html',
  styleUrl: './user-list-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListTabComponent implements OnDestroy {
  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  displayedColumns: string[] = [
    'num',
    'fullName',
    'email',
    'phone',
    'organizationName',
    'createTimestamp',
    'createdAtLogin',
    'isBlocked',
    'action',
  ];

  @Input() items: TGetUserListItems | null = null;

  @Input() params: TParams | null = null;

  strFormControl = new FormControl('');

  @Output() eventChangeParams = new EventEmitter<TParams>();

  constructor(private cdr: ChangeDetectorRef) {
    this.strFormControl.valueChanges
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((str) => {
        if (this.params) {
          this.eventChangeParams.emit({
            ...this.params,
            str: str ?? '',
          });
        }
      });
  }

  get dataSource() {
    return new MatTableDataSource(
      this.items?.result.map((item, index) => ({
        ...item,
        num: ((this.items?.page ?? 1) - 1) * 150 + index + 1,
      })),
    );
  }

  handleSortChange(event: { active: string; direction: string }) {
    if (this.params) {
      this.eventChangeParams.emit({
        ...this.params,
        sortBy: event.active,
        sortOrder: event.direction,
      });
    }
  }

  get pages() {
    return [...Array(this.items?.pages || 0)].map((_, index) => index + 1);
  }

  handlePageClick(page: number) {
    if (this.params) {
      this.eventChangeParams.emit({
        ...this.params,
        page,
      });
    }
  }

  handleIncludeHiddenFromUserListClick(includeHiddenFromUserList: boolean) {
    if (this.params) {
      this.eventChangeParams.emit({
        ...this.params,
        includeHiddenFromUserList,
      });
    }
  }

  get activeUsers() {
    if (this.items?.countTotal && this.items.blockedTotal) {
      return this.items.countTotal - this.items.blockedTotal;
    }
    return 0;
  }

  getHideUserFromUserListAction(
    value: boolean,
    fn: (value: boolean) => Promise<unknown>,
  ) {
    return () => fn(!value);
  }
}
