import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { TARIFFS_GATEWAY } from '@prlw/core/tariffs/tariffs.gateway';
import { HttpTariffsGateway } from './tariffs.gateway';

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [{ provide: TARIFFS_GATEWAY, useClass: HttpTariffsGateway }],
})
export class TariffsDataModule {}
