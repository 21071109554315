import {
  FileSize,
  FileType,
  FileUpload,
  FileUploadError,
} from '@prlw/core/profile/file-upload.entity';

export function getMaxFilesSize(fileSize: number): boolean {
  return fileSize > FileSize.sizeMax && fileSize > FileSize.sizeMin;
}

export function fileBrowser(event: Event): File[] {
  const files = (event.target as HTMLInputElement).files as unknown as File[];
  return Array.from(files).map((file) => file);
}

export function checkFile(type: string, fileSize: number): boolean | string {
  if (getMaxFilesSize(fileSize)) {
    return FileUploadError.SIZE;
  }
  return type.includes(FileType.IMAGE) || type.includes(FileType.PDF)
    ? false
    : FileUploadError.TYPE;
}

export function mapFileToEntity(files: File[]): FileUpload[] {
  return Array.from(files).map((file) => ({
    file,
    progress: 0,
    error: checkFile(file.type, file.size),
  }));
}

export function removeFile(
  index: number | null,
  files: FileUpload[],
): FileUpload[] {
  if (index !== null) {
    files.splice(index, 1);
  }
  return files;
}
