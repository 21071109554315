import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SHIPMENTS_GATEWAY } from '@prlw/core/shipments/shipments.gateway';
import {
  SHIPMENTS_DATA_CONFIG,
  ShipmentsDataConfig,
} from '@prlw/data/shipments/shipments-data.config';
import { HttpShipmentsGateway } from './shipments.gateway';

@NgModule({
  imports: [CommonModule],
  providers: [{ provide: SHIPMENTS_GATEWAY, useClass: HttpShipmentsGateway }],
})
export class ShipmentsDataModule {
  public static forRoot(
    config: ShipmentsDataConfig,
  ): ModuleWithProviders<ShipmentsDataModule> {
    return {
      ngModule: ShipmentsDataModule,
      providers: [{ provide: SHIPMENTS_DATA_CONFIG, useValue: config }],
    };
  }
}
