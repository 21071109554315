<div class="organization">
  <h1 class="organization-title">Организация</h1>
  <ng-container [ngSwitch]="status">
    <ng-template ngSwitchCase="0">
      <div class="grid-container">
        <div class="grid-left">
          <p>{{ profileOrganization?.organizationName }}</p>
          <p class="no-wrap" [class.alert]="!isSigned">
            {{ isSigned ? 'Регламент подписан' : 'Регламент не подписан' }}
          </p>
          <div class="organization-link" (click)="navigate()">Изменить</div>
        </div>
        <div>
          <p>Сделок: {{ profileOrganization?.dealsAmount || 0 }} из 1</p>
          <p>Объем: {{ profileOrganization?.sellVolume || 0 }} т из 120 т</p>
          <div
            *ngIf="!profileOrganization?.isPRO"
            class="progress-bar-container"
          >
            <div
              class="progress-bar"
              [style.width]="calculateProgressPercent + '%'"
            ></div>
          </div>
          <div *ngIf="profileOrganization?.isPRO">
            &#10003; Добавлен бесплатный PRO месяц
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngSwitchCase="1">
      <h2 class="dim">Добавьте ИНН чтобы использовать возможности сервиса</h2>
      <div class="flex-box">
        <input
          class="input"
          type="text"
          [formControl]="inn"
          data-cy="inn"
          placeholder="ИНН"
        />
        <button
          class="apply-btn"
          [disabled]="inn.invalid"
          (click)="changeTab()"
        >
          Продолжить
        </button>
      </div>
      <div *ngIf="innErrors" class="alert">
        <span *ngIf="innErrors.required">
          Это поле обязательно для заполнения
        </span>
        <span *ngIf="innErrors.invalidInn">Некорректный ИНН</span>
      </div>
    </ng-template>
    <ng-template ngSwitchCase="2">
      <div class="stub">
        <prlw-icon name="cross-circle-black"></prlw-icon>
        <div>
          <p>Вы были удалены из акаунта</p>
          <p>компании {{ profileOrganization?.organizationName }}</p>
        </div>
        <button class="apply-btn" (click)="navigate()">Добавить новую</button>
      </div>
    </ng-template>
  </ng-container>
</div>
