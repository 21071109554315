import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs.component';
import { TabComponent } from './tab.component';
import { ResponsiveDirective } from '../responsive/responsive.directive';

@NgModule({
  declarations: [TabsComponent, TabComponent],
  imports: [CommonModule, ResponsiveDirective],
  exports: [TabsComponent, TabComponent],
})
export class TabsModule {}
