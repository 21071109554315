import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Feedback } from '@prlw/core/feedback/feedback.entity';
import { FileUploadService } from '@prlw/core/feedback/file-upload.service';
import { IUploadResult } from '@prlw/core/feedback/upload-result.entity';
import { TFileUploadResponse } from '@prlw/data/feedback/api-types/file-upload.api';
import { ResponsiveService } from '@prlw/libs/responsive/responsive.service';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'prlw-feedback-input-ui',
  templateUrl: './feedback-input.component.html',
  styleUrls: ['./feedback-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackInputComponent {
  public themeInput: string = '';
  public messageInput: string = '';
  @Input() public isLoading: boolean = false;
  @Input() public allowedFileExtensions: string[] = [];
  @Output() public readonly closeModal = new EventEmitter<void>();
  @Output() public readonly sendFeedback = new EventEmitter<Feedback>();

  @ViewChild('fileInput', { static: false })
  public fileInput: ElementRef | undefined;

  public attachedFiles$: Observable<
    (TFileUploadResponse & { fileName: string })[]
  >;
  public thereArePendingAttachedFiles$: Observable<boolean>;

  constructor(
    private readonly fileUploadService: FileUploadService,
    private readonly responsiveService: ResponsiveService,
  ) {
    this.attachedFiles$ = this.fileUploadService.files$.pipe(
      map((obj) =>
        Object.keys(obj).map((key) => ({
          fileName: key,
          ...obj[key],
        })),
      ),
    );
    this.thereArePendingAttachedFiles$ = this.attachedFiles$.pipe(
      map((attachedFiles) =>
        attachedFiles.some((attachFile) => attachFile.pending),
      ),
    );
  }

  xsmall$ = this.responsiveService.xsmall$;

  public exit(): void {
    this.closeModal.emit();
  }

  public send(): void {
    if (this.themeInput) {
      if (this.messageInput) {
        this.sendFeedback.emit({
          themeInput: this.themeInput,
          messageInput: this.messageInput,
          attachedFiles: Object.values(this.fileUploadService.files$.getValue())
            .map((item) => item.data)
            .filter((item): item is IUploadResult => !!item),
        });
      }
    }
  }

  public onRemoveAttachedFile(fileName: string): void {
    this.fileUploadService.removeAttachedFile(fileName);
  }

  public onSelectFile(): void {
    this.fileInput?.nativeElement.click();
  }

  public get acceptedFileExtensions(): string {
    return this.allowedFileExtensions.map((ext) => `.${ext}`).join(',');
  }

  public attachFile(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) {
      return;
    }
    this.fileUploadService.attachFile(file);
  }
}
