<div class="container">
  <div class="title">История</div>
  <div class="header-row">
    <div class="header-row-item">Дата начала</div>
    <div class="header-row-item">Дата окончания</div>
    <div class="header-row-item">Вид подписки</div>
    <div class="header-row-item">Счёт</div>
    <div class="header-row-item">Документы</div>
  </div>
  <div class="wrapper">
    <div class="history-row" *ngFor="let historyItem of history; last as last">
      <div class="history-item">
        {{ historyItem.startPeriod }}
      </div>
      <div class="history-item">
        {{ historyItem.endPeriod }}
      </div>
      <div class="history-item">
        {{ historyItem.type }}
      </div>
      <div class="history-item {{ statusClass(historyItem.invoiceStatus) }}">
        {{ historyItem.invoiceStatus }}
      </div>
      <div class="history-item space">
        <a
          class="document"
          target="_blank"
          href="{{ historyItem.documentInvoice }}"
        >
          Счет
        </a>
        <a
          class="document"
          target="_blank"
          href="{{ historyItem.documentAct }}"
          *ngIf="historyItem.documentAct"
        >
          Акт об оказанных услугах
        </a>
      </div>
      <div class="line" *ngIf="!last"></div>
    </div>
  </div>
</div>
