import { Injectable } from '@angular/core';
import { IPage } from '@prlw/common/page/page.interface';
import { Observable, ReplaySubject } from 'rxjs';
import { PageContext } from '@prlw/common/page/page-context';

const BUFFER_SIZE = 1;

@Injectable({
  providedIn: 'root',
})
export class PageContextService {
  private readonly _pageContext$ = new ReplaySubject<PageContext>(BUFFER_SIZE);

  public get pageContext$(): Observable<PageContext> {
    return this._pageContext$.asObservable();
  }

  public registerPage(page: IPage): void {
    const context = PageContext.fromPage(page);
    this._pageContext$.next(context);
  }
}
