import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IconsModule } from '@prlw/libs/icons/icons.module';
import { FeedbackOverlayService } from './feedback-overlay.service';
import { FeedbackContainer } from './feedback.container';
import { FeedbackInputComponent } from './feedback-input/feedback-input.component';
import { FeedbackGratitudeComponent } from './feedback-gratitude/feedback-gratitude.component';
import { FeedbackErrorComponent } from './feedback-error/feedback-error.component';
import { ProfileUiModule } from '../profile/profile-ui.module';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';

@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    FormsModule,
    ProfileUiModule,
    ResponsiveDirective,
  ],
  declarations: [
    FeedbackContainer,
    FeedbackInputComponent,
    FeedbackGratitudeComponent,
    FeedbackErrorComponent,
  ],
  providers: [FeedbackOverlayService],
  exports: [FeedbackContainer],
})
export class FeedbackUiModule {}
