import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { localStorageSync } from 'ngrx-store-localstorage';

export function localStorageSyncReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  return localStorageSync({
    keys: ['analytics'],
    rehydrate: true,
    storageKeySerializer: (key) => `${key}`,
  })(reducer);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({}, { metaReducers: [localStorageSyncReducer] }),
    EffectsModule.forRoot([]),
  ],
})
export class PersistStateModule {}
