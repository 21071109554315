import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { MobileOverlayCardComponent } from '@prlw/common/mobile-overlay-card/mobile-overlay-card.component';
import { FeedbackUiModule } from './feedback-ui.module';

@Component({
  selector: 'prlw-feedback-mobile-ui',
  templateUrl: './feedback-mobile.component.html',
  styleUrl: './feedback-mobile.component.scss',
  standalone: true,
  imports: [MobileOverlayCardComponent, FeedbackUiModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackMobileComponent {
  @Output() eventClose = new EventEmitter<void>();
}
