export enum ModifierType {
  BASIC = 'basic',
  SIMPLE = 'simple',
  PREPAID = 'prepaid',
  DEFER = 'defer',
}

export type ModifierData = {
  prepaidMargin?: number;
  deferPercent?: number;
  deferPeriod?: number;
};

type ModifierBasic = {
  type: ModifierType.BASIC;
};

type ModifierSimple = {
  type: ModifierType.SIMPLE;
};

type ModifierPrepaid = {
  type: ModifierType.PREPAID;
  data: {
    prepaidMargin: number;
  };
};

type ModifierDefer = {
  type: ModifierType.DEFER;
  data: {
    deferPercent: number;
    deferPeriod: number;
  };
};

export type Modifier =
  | ModifierBasic
  | ModifierSimple
  | ModifierPrepaid
  | ModifierDefer;
