<ng-container *ngIf="(personalData$ | async) as personalData; else preloader">
  <prlw-personal-data-ui
    [personalData]="personalData"
    (takeCode)="takeCode($event)"
    (codeConfirm)="codeConfirm($event)"
    (showFeedbackModal)="showFeedbackModal()"
    [confirmEmailStatus]="confirmEmailStatus$ | async"
    (changeEmail)="editEmail()"
    (submitForm)="onSubmit($event)"
    [error]="(error$ | async) || null"
    [codeTime]="(timer$ | async) || null"
  ></prlw-personal-data-ui>
</ng-container>
<ng-template #preloader>
  <div class="preloader">
    <p>Что-то пошло не так.</p>
    <p>Перезагрузите страницу.</p>
  </div>
</ng-template>
