<prlw-common-pane>
  <div class="text">
    @if (profile) {
      @if (xsmall$ | async) {
        <div class="flex-col">
          <div class="org-text">{{ profile.organization?.name }}</div>
          <div class="name-text">{{ profile.fullName }}</div>
        </div>
      } @else {
        <span> {{ profile.organization?.name }} ({{ profile.fullName }}) </span>
      }
    }
  </div>

  <button
    prlwDemoAccessOverlay
    class="status-button profile"
    aria-label="Профиль пользователя"
    (click)="eventProfileButtonClick.emit()"
    prlwResponsive
  >
    <prlw-status-for-icon></prlw-status-for-icon>
    <prlw-icon name="profile" class="profile-icon"></prlw-icon>
  </button>
</prlw-common-pane>
