import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import {
  SendRecoveryCodeResult,
  SetNewPasswordData,
  SetNewPasswordResult,
  ValidateCodeData,
  ValidateCodeResult,
} from '@prlw/core/auth/password-recovery/password-recovery.types';

export const RECOVERY_PASSWORD_GATEWAY =
  new InjectionToken<RecoveryPasswordGateway>('password-recovery-gateway');

export interface RecoveryPasswordGateway {
  sendRecoveryCode(login: string): Observable<SendRecoveryCodeResult>;
  validateCode(data: ValidateCodeData): Observable<ValidateCodeResult>;
  setNewPassword(data: SetNewPasswordData): Observable<SetNewPasswordResult>;
}
