<prlw-legacy-profile-info
  *ngIf="true; else newProfile"
></prlw-legacy-profile-info>
<ng-template #newProfile>
  <prlw-tabs class="tabs" activeTabBg="white">
    <prlw-tab id="overview" label="Сводка">
      <prlw-profile-summary></prlw-profile-summary>
    </prlw-tab>
    <prlw-tab id="organization" label="Организация">
      <prlw-profile-organization-tab></prlw-profile-organization-tab>
    </prlw-tab>
    <prlw-tab id="license" label="Лицензия">
      <prlw-license-tab></prlw-license-tab>
    </prlw-tab>
    <prlw-tab id="personal" label="Личные данные">
      <prlw-personal-data></prlw-personal-data>
    </prlw-tab>
  </prlw-tabs>
</ng-template>
