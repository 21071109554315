import {
  MainTableColumnId,
  MainTableColumn,
  MainTableSorting,
} from '@prlw/core/monitor/types/main-table';

export const mainTableColumns: MainTableColumn<MainTableColumnId>[] = [
  {
    name: 'Базис',
    id: MainTableColumnId.BASIS,
    isVisible: true,
    isStatic: true,
    cellSizeMin: '105px',
  },
  {
    name: 'Название',
    id: MainTableColumnId.BRIEF_DESCRIPTION,
    isVisible: true,
    isStatic: true,
    cellSizeMin: '135px',
  },
  {
    name: 'Предложение',
    id: MainTableColumnId.OFFER,
    isVisible: true,
    isStatic: true,
    cellSizeMin: '95px',
  },
  {
    name: 'Сделка',
    id: MainTableColumnId.DEAL,
    isVisible: true,
    isStatic: true,
    cellSizeMin: '75px',
  },
  {
    name: 'Спрос',
    id: MainTableColumnId.DEMAND,
    isVisible: true,
    isStatic: true,
    cellSizeMin: '95px',
  },
  {
    name: 'Изменение',
    id: MainTableColumnId.CHANGES,
    isVisible: true,
    isStatic: false,
    cellSizeMin: '80px',
  },
  {
    name: 'Объем',
    id: MainTableColumnId.VOLUME,
    isVisible: true,
    isStatic: false,
    cellSizeMin: '120px',
  },
  {
    name: 'Прошлый объем',
    id: MainTableColumnId.PREV_SESSION_VOLUME,
    isVisible: true,
    isStatic: false,
    cellSizeMin: '120px',
  },
  {
    name: 'Изменение объемa',
    id: MainTableColumnId.PREV_DEALS_AMOUNT_DIFF,
    isVisible: true,
    isStatic: false,
    cellSizeMin: '140px',
  },
  {
    name: 'Тариф',
    id: MainTableColumnId.TARIFF,
    isVisible: true,
    isStatic: false,
    cellSizeMin: '75px',
  },
  {
    name: 'Цена на месте',
    id: MainTableColumnId.LOCAL_PRICE,
    isVisible: true,
    isStatic: false,
    cellSizeMin: '100px',
  },
  {
    name: 'Отгрузка',
    id: MainTableColumnId.SHIPMENT,
    isVisible: true,
    isStatic: false,
    cellSizeMin: '65px',
  },
  {
    name: 'В пути',
    id: MainTableColumnId.ON_THE_WAY,
    isVisible: true,
    isStatic: false,
    cellSizeMin: '45px',
  },
  {
    name: 'Начало продаж',
    id: MainTableColumnId.TRADE_START,
    isVisible: true,
    isStatic: false,
    cellSizeMin: '60px',
    cellSizeMax: '110px',
  },
];

export const mainTableSortDefaults: MainTableSorting[] = [
  { columnId: MainTableColumnId.BASIS, direction: 'ASC' },
  { columnId: MainTableColumnId.BRIEF_DESCRIPTION, direction: 'ASC' },
  { columnId: MainTableColumnId.OFFER, direction: 'ASC' },
  { columnId: MainTableColumnId.DEAL, direction: 'ASC' },
  { columnId: MainTableColumnId.DEMAND, direction: 'ASC' },
  { columnId: MainTableColumnId.CHANGES, direction: 'ASC' },
  { columnId: MainTableColumnId.VOLUME, direction: 'ASC' },
  { columnId: MainTableColumnId.PREV_SESSION_VOLUME, direction: 'ASC' },
  { columnId: MainTableColumnId.PREV_DEALS_AMOUNT_DIFF, direction: 'ASC' },
  { columnId: MainTableColumnId.TARIFF, direction: 'ASC' },
  { columnId: MainTableColumnId.LOCAL_PRICE, direction: 'ASC' },
  { columnId: MainTableColumnId.SHIPMENT, direction: 'ASC' },
  { columnId: MainTableColumnId.ON_THE_WAY, direction: 'ASC' },
  { columnId: MainTableColumnId.TRADE_START, direction: 'ASC' },
];
