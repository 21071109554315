import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductGroup } from './product-group.entity';

export const PRODUCT_GROUPS_GATEWAY = new InjectionToken<ProductGroupsGateway>(
  'ProductGroupsGateway',
);

export interface ProductGroupsGateway {
  getProductGroups(): Observable<ProductGroup[]>;
}
