import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export type PaginationData = {
  currentPage: number;
  pageSize: number;
  pageCount: number;
  total: number;
};

export type PaginationInfo = {
  previousPages: number[];
  currentPage: number;
  nextPages: number[];
};

const DEFAULT_DATA: PaginationData = {
  currentPage: 1,
  pageSize: 0,
  pageCount: 0,
  total: 0,
};
const MAXIMUM_LENGTH = 10;

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  private readonly _state = new Map<string, BehaviorSubject<PaginationData>>();
  private readonly _maxLengthState = new Map<string, number>();

  public setMaxLengthByKey(key: string, maxLength: number): void {
    if (maxLength > 0) {
      this._maxLengthState.set(key, maxLength);
    }
  }

  public getStateByKey(key: string): Observable<PaginationData> {
    return this._getSubject(key).asObservable();
  }

  public getDistinctStateByKey(key: string): Observable<PaginationData> {
    return this.getStateByKey(key).pipe(
      distinctUntilChanged((a, b) => a.currentPage === b.currentPage),
    );
  }

  public getPaginationInfo(key: string): Observable<PaginationInfo> {
    return this.getStateByKey(key).pipe(
      filter((state) => Object.values(state).every(Boolean)),
      map((state) => {
        const maximumLength = this._maxLengthState.get(key) ?? MAXIMUM_LENGTH;
        const divCorrection = state.currentPage % maximumLength ? 0 : 1;
        const arrayLength =
          state.pageCount >= maximumLength ? maximumLength : state.pageCount;
        const div = Math.floor(state.currentPage / arrayLength) - divCorrection;
        const res = [...Array(arrayLength).keys()]
          .map((x) => x + div * arrayLength + 1)
          .filter((x) => x <= state.pageCount);
        return {
          previousPages: res.filter((x) => x < state.currentPage),
          currentPage: state.currentPage,
          nextPages: res.filter((x) => x > state.currentPage),
        };
      }),
    );
  }

  public hasNextPage(key: string): Observable<boolean> {
    return this.getStateByKey(key).pipe(
      map((state) => state.currentPage < state.pageCount),
      distinctUntilChanged(),
    );
  }

  public hasPreviousPage(key: string): Observable<boolean> {
    return this.getStateByKey(key).pipe(
      map((state) => state.currentPage > 1),
      distinctUntilChanged(),
    );
  }

  public hasNextList(key: string): Observable<boolean> {
    return this.getStateByKey(key).pipe(
      map(
        (state) =>
          this._getNextListPage(
            state.currentPage,
            this._maxLengthState.get(key) ?? MAXIMUM_LENGTH,
          ) <= state.pageCount,
      ),
      distinctUntilChanged(),
    );
  }

  public hasPreviousList(key: string): Observable<boolean> {
    return this.getStateByKey(key).pipe(
      map(
        (state) =>
          this._getPreviousListPage(
            state.currentPage,
            this._maxLengthState.get(key) ?? MAXIMUM_LENGTH,
          ) > 0,
      ),
      distinctUntilChanged(),
    );
  }

  public isFirstPage(key: string): Observable<boolean> {
    return this.getStateByKey(key).pipe(
      map((state) => state.currentPage === 1),
      distinctUntilChanged(),
    );
  }

  public isLastPage(key: string): Observable<boolean> {
    return this.getStateByKey(key).pipe(
      map((state) => state.currentPage === state.pageCount),
      distinctUntilChanged(),
    );
  }

  public setState(key: string, data?: PaginationData): void {
    const currentState$ = this._state.get(key);
    if (currentState$ && data) {
      const currentData = currentState$.getValue();
      if (this._isDataChanged(currentData, data)) {
        currentState$.next(data);
      }
    } else if (!currentState$) {
      this._state.set(
        key,
        new BehaviorSubject<PaginationData>(data ?? DEFAULT_DATA),
      );
    }
  }

  public setPageSize(key: string, pageSize: number): void {
    const currentData = this._getSubject(key).getValue();
    const newData = {
      ...currentData,
      pageSize,
    };
    this.setState(key, newData);
  }

  public nextPage(key: string): void {
    this._shiftPage(key, 'up');
  }

  public backPage(key: string): void {
    this._shiftPage(key, 'down');
  }

  public nextList(key: string): void {
    const currentData = this._getSubject(key).getValue();
    const newPage = this._getNextListPage(
      currentData.currentPage,
      this._maxLengthState.get(key) ?? MAXIMUM_LENGTH,
    );
    if (newPage <= currentData.pageCount) {
      this.setPage(key, newPage);
    }
  }

  public backList(key: string): void {
    const currentData = this._getSubject(key).getValue();
    const newPage = this._getPreviousListPage(
      currentData.currentPage,
      this._maxLengthState.get(key) ?? MAXIMUM_LENGTH,
    );
    if (newPage > 0) {
      this.setPage(key, newPage);
    }
  }

  public setLastPage(key: string): void {
    const { pageCount } = this._getSubject(key).getValue();
    this.setPage(key, pageCount);
  }

  public setFirstPage(key: string): void {
    this.setPage(key, 1);
  }

  public setPage(key: string, currentPage: number): void {
    const currentData = this._getSubject(key).getValue();
    const newData = {
      ...currentData,
      currentPage,
    };
    this.setState(key, newData);
  }

  private _shiftPage(key: string, direction: 'up' | 'down'): void {
    const currentData = this._getSubject(key).getValue();
    let currentPage = currentData.currentPage;
    if (direction === 'up') {
      currentPage =
        currentPage < currentData.pageCount ? currentPage + 1 : currentPage;
    }
    if (direction === 'down') {
      currentPage = currentPage > 1 ? currentPage - 1 : currentPage;
    }
    const newData = {
      ...currentData,
      currentPage,
    };
    this.setState(key, newData);
  }

  private _isDataChanged(
    oldData: PaginationData,
    newData: PaginationData,
  ): boolean {
    return JSON.stringify(oldData) !== JSON.stringify(newData);
  }

  private _getSubject(key: string): BehaviorSubject<PaginationData> {
    this.setState(key);
    return this._state.get(key) as BehaviorSubject<PaginationData>;
  }

  private _getNextListPage(currentPage: number, maxLength: number): number {
    return Math.ceil(currentPage / maxLength) * maxLength + 1;
  }

  private _getPreviousListPage(currentPage: number, maxLength: number): number {
    return (Math.floor(currentPage / maxLength) - 1) * maxLength + 1;
  }
}
