import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconsModule } from '@prlw/libs/icons/icons.module';
import { DemoAccessContainer } from './demo-access.container';
import { DemoAccessComponent } from './demo-access.component';
import { StatusForIconContainer } from './status-for-icon/status-for-icon.container';
import { StatusForIconComponent } from './status-for-icon/status-for-icon.component';
import { DemoAccessOverlayDirective } from './demo-access-overlay.directive';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';

@NgModule({
  imports: [CommonModule, IconsModule, ResponsiveDirective],
  declarations: [
    DemoAccessOverlayDirective,
    DemoAccessContainer,
    DemoAccessComponent,
    StatusForIconContainer,
    StatusForIconComponent,
  ],
  exports: [StatusForIconContainer, DemoAccessOverlayDirective],
})
export class DemoAccessModule {}
