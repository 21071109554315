<div class="container">
  <div class="info-block">
    <h2 class="title-text">Данные пользователя</h2>
    <h3 class="field-name">ФИО</h3>
    <div
      *ngIf="profile?.fullName; else noInfo"
      data-test="fullName"
      class="data-field"
    >
      {{ profile?.fullName }}
    </div>
    <h3 class="field-name">Мобильный телефон</h3>
    <div
      *ngIf="profile?.phone; else noInfo"
      data-test="phone"
      class="data-field"
    >
      {{ profile?.phone }}
    </div>
    <h3 class="field-name">E-mail</h3>
    <div
      *ngIf="profile?.email; else noInfo"
      data-test="email"
      class="data-field"
    >
      {{ profile?.email }}
    </div>
  </div>
  <div class="info-block">
    <h2 class="title-text">Данные организации</h2>
    <h3 class="field-name">Название организации</h3>
    <div
      *ngIf="profile?.organization?.name; else noInfo"
      data-test="organizationName"
      class="data-field"
    >
      {{ profile?.organization?.name }}
    </div>
    <div class="inn-kpp-container">
      <div class="info-block">
        <h3 class="field-name">ИНН</h3>
        <div
          *ngIf="profile?.organization?.inn; else noInfo"
          data-test="organizationInn"
          class="data-field"
        >
          {{ profile?.organization?.inn }}
        </div>
      </div>
      <div class="info-block">
        <h3 class="field-name">КПП</h3>
        <div
          *ngIf="profile?.organization?.kpp; else noInfo"
          data-test="organizationKpp"
          class="data-field"
        >
          {{ profile?.organization?.kpp }}
        </div>
      </div>
    </div>
    <h3 class="field-name">Адрес</h3>
    <div
      *ngIf="profile?.organization?.actualAddress; else noInfo"
      data-test="organizationAddress"
      class="data-field"
    >
      {{ profile?.organization?.actualAddress }}
    </div>
  </div>
</div>

<prlw-user-list-tab *ngIf="isEditor"></prlw-user-list-tab>

<ng-template #noInfo>
  <div class="data-field" data-test="noInfo">
    <span class="no-information"> Нет информации </span>
  </div>
</ng-template>
