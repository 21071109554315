import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Profile } from '@prlw/core/profile/profile.entity';
import { ProfileController } from '@prlw/core/profile/profile.controller';

@Component({
  selector: 'prlw-legacy-profile-info',
  templateUrl: './profile-info.container.html',
  styleUrls: ['./profile-info.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileInfoContainer {
  public readonly profile$: Observable<Profile>;

  constructor(private readonly profileController: ProfileController) {
    this.profile$ = profileController.profile$;
  }

  isEditor$ = this.profileController.profile$.pipe(
    map(({ roleEditor }) => roleEditor),
  );
}
