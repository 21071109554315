import { Observable } from 'rxjs';
import { Instrument } from './instrument.entity';
import { InjectionToken } from '@angular/core';
import { Product } from '@prlw/core/products/product.entity';
import { AllInstruments } from '@prlw/core/monitor/all-instruments.entity';
import { DealStat } from '@prlw/core/monitor/deal-stat.entity';
import { Modifier } from '@prlw/core/monitor/types/common';

export const MONITOR_GATEWAY = new InjectionToken<MonitorGateway>(
  'monitor-gateway',
);

export interface MonitorGateway {
  getInstruments(
    product: string,
    destination: string | null,
    shipments: string[],
    activeProducts: Product[],
    modifier: Modifier,
  ): Observable<Instrument[]>;

  getAllInstruments(
    productGroups: string[],
    shipmentBasises: string[],
    destination: string,
    modifier: Modifier,
  ): Observable<AllInstruments[]>;

  getDealsStats(
    instrumentCode: string,
    scale: DealStatScale,
  ): Observable<DealStat[]>;
}

export type DealStatScale = '1m' | '2m' | '3m' | '6m' | '1y' | '3y';
