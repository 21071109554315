import { ChangeDetectorRef } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export type ScrollableElements = { elemX: HTMLElement; elemY: HTMLElement };

const SCROLL_OFFSET = 10;

export type OverflowState = {
  top: boolean;
  bottom: boolean;
  left: boolean;
  right: boolean;
};

export class OverflowFader {
  private readonly _isOverflowRight$: ReplaySubject<boolean>;
  private readonly _isOverflowBottom$: ReplaySubject<boolean>;
  private readonly _scrollLeft$: BehaviorSubject<number>;
  private readonly _scrollTop$: BehaviorSubject<number>;
  private readonly _destroy$: Subject<void> = new Subject<void>();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    this._isOverflowRight$ = new ReplaySubject<boolean>(1);
    this._isOverflowBottom$ = new ReplaySubject<boolean>(1);
    this._scrollLeft$ = new BehaviorSubject<number>(0);
    this._scrollTop$ = new BehaviorSubject<number>(0);
  }

  public get overflows$(): Observable<OverflowState> {
    return combineLatest([
      this.isOverflowTop$,
      this.isOverflowBottom$,
      this.isOverflowLeft$,
      this.isOverflowRight$,
    ]).pipe(
      map(([top, bottom, left, right]) => ({ top, bottom, left, right })),
    );
  }

  public get isOverflowTop$(): Observable<boolean> {
    return this._scrollTop$
      .asObservable()
      .pipe(map((scrollTop) => scrollTop > 0));
  }

  public get isOverflowBottom$(): Observable<boolean> {
    return this._isOverflowBottom$.asObservable();
  }

  public get isOverflowLeft$(): Observable<boolean> {
    return this._scrollLeft$
      .asObservable()
      .pipe(map((scrollLeft) => scrollLeft > 0));
  }

  public get isOverflowRight$(): Observable<boolean> {
    return this._isOverflowRight$.asObservable();
  }

  public init(
    elems: ScrollableElements,
    additionalOverflowObservables: Observable<unknown>[] = [],
  ): void {
    const { scrollLeft, scrollTop } = elems.elemY;
    this._destroy$.next();
    this._scrollLeft$.next(scrollLeft || 0);
    this._scrollTop$.next(scrollTop || 0);
    this._setOverflowObserver(elems, additionalOverflowObservables)
      .pipe(takeUntil(this._destroy$))
      .subscribe();
  }

  public setScrollHorizontalPosition(scrollLeft: number): void {
    this._scrollLeft$.next(scrollLeft);
  }

  public setScrollVerticalPosition(scrollTop: number): void {
    this._scrollTop$.next(scrollTop);
  }

  private _resizeObservable(
    elem: HTMLElement,
  ): Observable<ResizeObserverEntry[]> {
    return new Observable((subscriber) => {
      const ro = new ResizeObserver((entries) => {
        subscriber.next(entries);
      });

      ro.observe(elem);
      return function unsubscribe() {
        ro.unobserve(elem);
      };
    });
  }

  private _setOverflowObserver(
    elems: ScrollableElements,
    additionalOverflowObservables: Observable<unknown>[],
  ): Observable<void> {
    const { elemX, elemY } = elems;
    return combineLatest([
      this._scrollLeft$.asObservable(),
      this._scrollTop$.asObservable(),
      this._resizeObservable(elemX),
      this._resizeObservable(elemY),
      ...additionalOverflowObservables,
    ]).pipe(
      map((results) => {
        const scrollLeft = results[0];
        const scrollTop = results[1];
        const { offsetWidth, scrollWidth } = elemX;
        const isEndHorizontalScroll =
          Math.ceil(scrollLeft + offsetWidth) >= scrollWidth - SCROLL_OFFSET;
        const hasHorizontalOverflow =
          offsetWidth < scrollWidth && !isEndHorizontalScroll;

        const { offsetHeight, scrollHeight } = elemY;
        const isEndVerticalScroll =
          Math.ceil(scrollTop + offsetHeight) >= scrollHeight - SCROLL_OFFSET;
        const hasVerticalOverflow =
          offsetHeight < scrollHeight && !isEndVerticalScroll;

        this._isOverflowRight$.next(hasHorizontalOverflow);
        this._isOverflowBottom$.next(hasVerticalOverflow);
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  public destroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
