import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { Profile } from '@prlw/core/profile/profile.entity';
import { ProfileController } from '@prlw/core/profile/profile.controller';
import { SummaryInfo } from '@prlw/core/profile/summary.entity';

@Component({
  selector: 'prlw-profile-summary',
  templateUrl: './profile-summary.container.html',
  styleUrls: ['./profile-summary.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSummaryContainer {
  public readonly profile$: Observable<Profile>;
  public readonly summaryInfo$: Observable<SummaryInfo>;

  constructor(private readonly profileController: ProfileController) {
    this.profile$ = profileController.profile$;
    this.summaryInfo$ = profileController.summaryInfo$;
  }
}
