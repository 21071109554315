export enum AuthLevelValue {
  Guest = 0,
  Demo = 1,
}

export abstract class AuthLevel {
  constructor(private readonly level: AuthLevelValue) {}

  public get isGuest(): boolean {
    return this.level === AuthLevelValue.Guest;
  }

  public get isDemo(): boolean {
    return this.level === AuthLevelValue.Demo;
  }

  public get isDemoOrAbove(): boolean {
    return this.level >= AuthLevelValue.Demo;
  }

  public get isDemoOrBelow(): boolean {
    return this.level <= AuthLevelValue.Demo;
  }
}

export class GuestAuthLevel extends AuthLevel {
  constructor() {
    super(AuthLevelValue.Guest);
  }
}

export class DemoAuthLevel extends AuthLevel {
  constructor() {
    super(AuthLevelValue.Demo);
  }
}
