import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonPaneComponent } from '@prlw/common/common-pane/common-pane.component';

@Component({
  selector: 'prlw-title-logo',
  standalone: true,
  imports: [CommonPaneComponent],
  templateUrl: './title-logo.component.html',
  styleUrl: './title-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleLogoComponent {
  @Input() transparent: boolean | null = null;
}
