import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BuyProductGateway } from '@prlw/core/buy-product/buy-product-gateway.interface';
import { BuyProductDataConfig } from '@prlw/data/buy-product/buy-product-data.module';
import { BUY_PRODUCT_DATA_CONFIG } from '@prlw/data/buy-product/buy-product-data-config.token';
import { BuyProduct } from '@prlw/core/buy-product/buy-product.entity';
import { BuyProductResponse } from '@prlw/data/buy-product/api-types/buy-product.api';

@Injectable({
  providedIn: 'root',
})
export class HttpBuyProductGateway implements BuyProductGateway {
  constructor(
    @Inject(BUY_PRODUCT_DATA_CONFIG)
    private readonly config: BuyProductDataConfig,
    private readonly http: HttpClient,
  ) {}

  public buyProduct(data: BuyProduct): Observable<BuyProductResponse> {
    return this.http.post<BuyProductResponse>(this.config.apiPrefix, data).pipe(
      map(() => ({ success: true, error: null })),
      catchError((errorResponse: HttpErrorResponse) =>
        of({
          success: false,
          error: errorResponse.error.message,
        }),
      ),
    );
  }
}
