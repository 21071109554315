import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { NotificationService } from '@prlw/infrastructure/errors/notification/notification.service';
import { PrlwClientError } from '@prlw/infrastructure/errors/error-types/error-types';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(private readonly injector: Injector) {}

  public handleError(error: Error | HttpErrorResponse): void {
    const notificationService = this.injector.get(NotificationService);
    function getServerErrorMessage(httpError: HttpErrorResponse): void {
      switch (httpError.status) {
        case HttpStatusCode.NotFound: {
          notificationService.customNotify(
            `Не найдено: ${httpError.message}`,
            'Сервер',
          );
          return;
        }
        case HttpStatusCode.Forbidden: {
          if (httpError.error.message === 'Forbidden in light mode') {
            return;
          }
          notificationService.customNotify(
            `Доступ запрещён: ${httpError.message}`,
            'Сервер',
          );
          return;
        }
        case HttpStatusCode.InternalServerError: {
          notificationService.customNotify(
            `Внутренняя ошибка сервера: ${httpError.message}`,
            'Сервер',
          );
          return;
        }
        default: {
          notificationService.customNotify(
            `Неизвестная ошибка сервера: ${httpError.message}`,
            'Сервер',
          );
        }
      }
    }

    if (error instanceof HttpErrorResponse) {
      // Server error
      if (!navigator.onLine) {
        // No Internet connection
        notificationService.customNotify(
          'Отсутствует доступ к интернету.',
          'Интернет',
        );
        return;
      }
      // Http Error
      // it is for cypress test pass, may be is more elegant solution
      if (!error.url?.includes('cypress')) {
        getServerErrorMessage(error);
      }
    } else if (error instanceof PrlwClientError) {
      notificationService.notify(error);
    }
  }
}
