/* eslint-disable */

export enum AuthErrorCode {
  SignInByLogin = '1',
  DemoPeriodHasExpiredError = '2',
  WrongConfirmationCode = '3',
  ConfirmationCodeExpired = '4',
  TooManyCodeAttempts = '5',
  UserNotFound = '6',
  SignInByPhone = '7',
  JwtRefresh = '8',
  SendRecoveryCode = '9',
  SetNewPassword = '10',
  ValidateCode = '11',
  ReAuthorizationRequired = '13',
  MultipleLogin = '15',
}

export const AuthErrorDescription = {
  1: 'Ошибка авторизации по логину.',
  2: 'Демо период закончен.',
  3: 'Не верный код подтверждения.',
  4: 'Время жизни кода подтверждения истекло.',
  5: 'Исчерпан лимит попыток ввода кода подтверждения.',
  6: 'Пользователь с такими данными не найден.',
  7: 'Ошибка авторизации по телефону.',
  8: 'Ошибка при обновлении токена.',
  9: 'Ошибка отправки кода при восстановлении пароля.',
  10: 'Ошибка установки нового пароля.',
  11: 'Ошибка при валидации установки нового пароля.',
  13: 'Требуется повторная авторизация.',
  15: 'Авторизация на другом устройстве.',
};
