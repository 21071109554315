import { RefreshToken } from '@prlw/core/auth/jwt/refresh-token';
import { AccessToken } from '@prlw/core/auth/jwt/access-token';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { PrlwAuthError } from '@prlw/infrastructure/errors/error-types/error-types';

export const JWT_GATEWAY = new InjectionToken<JwtGateway>('jwt-gateway');

export type JwtTokenSet = {
  accessToken: AccessToken;
  refreshToken: RefreshToken;
};

export type JwtRefreshResult =
  | { success: true; tokens: JwtTokenSet }
  | { success: false; error: PrlwAuthError };

export interface JwtGateway {
  refresh(token: RefreshToken): Observable<JwtRefreshResult>;
}
