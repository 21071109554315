import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'prlw-tab',
  template: '<ng-template><ng-content></ng-content></ng-template>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  @Input() public id!: string;
  @Input() public label: string = '';
  @ViewChild(TemplateRef)
  public readonly bodyContent: TemplateRef<HTMLElement> | null = null;
}
