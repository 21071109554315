import { Region } from '@prlw/core/small-wholesale/region.type';
import { SmallWholesaleGateway } from '@prlw/core/small-wholesale/small-wholesale-gateway.interface';
import { SmallWholesaleOrder } from '@prlw/core/small-wholesale/small-wholesale-order.type';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  SMALL_WHOLESALE_DATA_CONFIG,
  SmallWholesaleDataConfig,
} from '@prlw/data/small-wholesale/small-wholesale-data-config.type';
import {
  Base,
  ProductGroupWithBases,
  RegionGetAllResponse,
} from '@prlw/data/small-wholesale/api-types/region-get-all-response.type';
import { AuthStateProvider } from '@prlw/core/auth/auth-state.provider';
import { RegionOrderRequest } from '@prlw/data/small-wholesale/api-types/region-order-request.type';
import * as fp from 'lodash/fp';

type BasesWithProductGroupCodes = (Base & { groupCode: string })[];

function basesWithProductCodes(
  groupWithBases: ProductGroupWithBases,
): BasesWithProductGroupCodes {
  return groupWithBases.data.map((base) => ({
    ...base,
    groupCode: groupWithBases.productGroup,
  }));
}

@Injectable()
export class HttpSmallWholesaleGateway implements SmallWholesaleGateway {
  private readonly skipAuthHeader$: Observable<HttpHeaders>;

  constructor(
    private readonly http: HttpClient,
    @Inject(SMALL_WHOLESALE_DATA_CONFIG)
    private readonly config: SmallWholesaleDataConfig,
    private readonly authState: AuthStateProvider,
  ) {
    this.skipAuthHeader$ = authState.authLevel$.pipe(
      map(
        (level) => new HttpHeaders(level.isGuest ? { SkipAuth: 'true' } : {}),
      ),
    );
  }

  public getRegions(): Observable<Region[]> {
    return this.skipAuthHeader$.pipe(
      switchMap((headers) =>
        this.http
          .get<RegionGetAllResponse>(`${this.config.apiPrefix}/all`, {
            headers,
          })
          .pipe(
            map((response) =>
              response.map((groupWithBases) =>
                basesWithProductCodes(groupWithBases),
              ),
            ),
            map(fp.flatten),
            map(fp.groupBy((entry) => entry.base + entry.region)),
            map(fp.pickBy((_value, key) => key.length)),
            map(fp.values),
            map(
              fp.map(
                (
                  productGroupsPerRegionAndSupplier: BasesWithProductGroupCodes,
                ) => ({
                  name: productGroupsPerRegionAndSupplier[0].region,
                  supplier: productGroupsPerRegionAndSupplier[0].base,
                  productGroups: productGroupsPerRegionAndSupplier.map(
                    (group) => ({
                      code: group.groupCode,
                      name: group.product,
                      price: parseInt(group.offerPrice, 10),
                      priceOfYesterday: parseInt(
                        group.offerPriceOfYesterday,
                        10,
                      ),
                      difference: parseInt(group.offerPriceDifference, 10),
                    }),
                  ),
                }),
              ),
            ),
          ),
      ),
    );
  }

  public submitOrder(order: SmallWholesaleOrder): Observable<void> {
    return combineLatest([
      this.authState.authLevel$.pipe(
        map((authLevel) =>
          authLevel.isDemo
            ? `${this.config.apiPrefix}/user/buy`
            : `${this.config.apiPrefix}/buy`,
        ),
      ),
      this.skipAuthHeader$,
    ]).pipe(
      first(),
      switchMap(([url, headers]) =>
        this.http.post<never>(
          url,
          {
            product: order.productGroup.code,
            base: order.region.supplier,
            amount: order.lots.toString(),
            price: order.productGroup.price.toString(),
            client: order.customerOrganization,
            fio: order.customerName,
            phone: order.customerPhone,
            email: order.customerEmail,
          } as RegionOrderRequest,
          { headers },
        ),
      ),
    );
  }
}
