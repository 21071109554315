import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import {
  OrderStatus,
  OrganizationCard,
  OrganizationStatus,
} from '@prlw/core/profile/summary.entity';
import {
  UntypedFormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { isValidINN, takeFirstError } from './utils/inn-check';

@Component({
  selector: 'prlw-profile-summary-organization-card',
  templateUrl: './profile-summary-organization-card.component.html',
  styleUrls: ['./profile-summary-organization-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSummaryOrganizationCardComponent implements OnInit {
  @Input() public profileOrganization: OrganizationCard | null = null;
  public status!: OrganizationStatus;
  public inn = new UntypedFormControl(
    null,
    takeFirstError([Validators.required, isValidINN]),
  );

  public ngOnInit(): void {
    this.status =
      this.profileOrganization?.organizationStatus ??
      OrganizationStatus.NOT_REGISTERED;
  }

  public get calculateProgressPercent(): number {
    return this.profileOrganization?.sellVolume
      ? // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        (this.profileOrganization.sellVolume / 120) * 100
      : 0;
  }

  public navigate(): void {
    this.status = OrganizationStatus.NOT_REGISTERED;
  }

  public get isSigned(): boolean {
    return this.profileOrganization?.orderStatus === OrderStatus.SIGNED;
  }

  public get innErrors(): ValidationErrors | null {
    const control = this.inn;
    if (control.valid || (control.pristine && control.untouched)) {
      return null;
    }
    return control.errors;
  }

  public changeTab(): void {
    console.log('go to organ tab 2 step');
  }
}
