import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Inject,
  Optional,
} from '@angular/core';
import { Profile } from '@prlw/core/profile/profile.entity';
import { TabsComponent } from '@prlw/libs/tabs/tabs.component';

@Component({
  selector: 'prlw-profile-summary-contact-card',
  templateUrl: './profile-summary-contact-card.component.html',
  styleUrls: ['./profile-summary-contact-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSummaryContactCardComponent {
  constructor(@Optional() @Inject(TabsComponent) public tabs: TabsComponent) {}
  @Input() public profile?: Profile;

  public navigate(): void {
    this.tabs.setActiveTab('personal');
  }
}
