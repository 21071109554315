import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CONTRACTS_DATA_CONFIG,
  ContractsDataConfig,
} from '@prlw/data/contracts/contracts-data.config';
import { Contract } from '@prlw/core/contracts/contracts.entity';
import { ContractsGateway } from '@prlw/core/contracts/contracts.gateway';
import { ContractsFilterQuery } from '@prlw/core/contracts/contracts-filter.provider';
import {
  ContractStatus,
  ContractStatusType,
} from '@prlw/core/contracts/contract-status.entity';
import { DocumentView } from '@prlw/core/contracts/document-view.entity';
import { Buyer } from '@prlw/core/contracts/buyer.entity';
import { Seller } from '@prlw/core/contracts/seller.entity';
import { Station } from '@prlw/core/contracts/station.entity';
import {
  GetContractsResponse,
  GetContractsResponseData,
} from '@prlw/data/contracts/api-types/get-contracts.api';
import { GetMaxAndMinAmountResponse } from '@prlw/data/contracts/api-types/get-max-and-min-amount.api';
import { GetMaxAndMinPriceResponse } from '@prlw/data/contracts/api-types/get-max-and-min-price.api';
import { MinAndMaxData } from '@prlw/core/contracts/min-and-max-data.entity';
import { GetStatusesResponse } from '@prlw/data/contracts/api-types/get-statuses.api';

const TEMP_LIMIT = '50';

@Injectable({
  providedIn: 'root',
})
export class HttpContractsGateway implements ContractsGateway {
  constructor(
    @Inject(CONTRACTS_DATA_CONFIG) private readonly config: ContractsDataConfig,
    private readonly http: HttpClient,
  ) {}

  public getContracts(filters: ContractsFilterQuery): Observable<Contract[]> {
    const params = {
      ...filters,
      pageSize: TEMP_LIMIT,
    };
    return this.http
      .get<GetContractsResponse>(this.config.apiPrefix, { params })
      .pipe(map((data) => this._mapResponseEntity(data.contracts)));
  }

  public getAllSellers(): Observable<Seller[]> {
    return this.http.get<Seller[]>(`${this.config.apiPrefix}/sellers`);
  }

  public getAllBuyers(): Observable<Buyer[]> {
    return this.http.get<Buyer[]>(`${this.config.apiPrefix}/buyers`);
  }

  public getMaxAndMinAmount(): Observable<MinAndMaxData> {
    return this.http
      .get<GetMaxAndMinAmountResponse>(`${this.config.apiPrefix}/amount`)
      .pipe(map((data) => ({ min: data.minAmount, max: data.maxAmount })));
  }

  public getMaxAndMinPrice(): Observable<MinAndMaxData> {
    return this.http
      .get<GetMaxAndMinPriceResponse>(`${this.config.apiPrefix}/price`)
      .pipe(map((data) => ({ min: data.minPrice, max: data.maxPrice })));
  }

  public getAllStations(): Observable<Station[]> {
    return this.http.get<Station[]>(`${this.config.apiPrefix}/stations`);
  }

  public getAllDocumentView(): Observable<DocumentView[]> {
    return this.http.get<DocumentView[]>(
      `${this.config.apiPrefix}/document-view`,
    );
  }

  public getStatuses(): Observable<ContractStatus[]> {
    return this.http
      .get<GetStatusesResponse[]>(`${this.config.apiPrefix}/statuses`)
      .pipe(
        map((statuses) => statuses.map((status) => this._formatStatus(status))),
      );
  }

  private _mapResponseEntity(data: GetContractsResponseData[]): Contract[] {
    return data.map((item) => ({
      buyer: item.buyer,
      seller: item.seller,
      product: item.productGroupCode,
      basis: item.basisCode,
      productName: item.productName,
      amount: Number(item.amount),
      price: Number(item.price),
      station: item.station,
      createDate: item.createTimestamp,
      signingDate: item.signedTimestamp,
      documentView: item.documentView,
      status: item.status && this._formatStatus(item.status),
      name: item.name,
      plant: item.plant,
      totalCost: item.totalCost,
      destinationCode: item.destinationCode,
      destination: item.destination,
      deliveryMethod: item.deliveryMethod,
      actualAddress: item.actualAddress,
      juristicAddress: item.juristicAddress,
      link: item.link,
    }));
  }

  private _formatStatus(status: GetStatusesResponse): ContractStatus {
    const statusDescriptions: { [key: number]: string } = {
      [ContractStatusType.WAITING_SIGN]: 'На подписании',
      [ContractStatusType.SIGNED]: 'Подписан',
      [ContractStatusType.TERMINATED]: 'Расторгнут',
      [ContractStatusType.ANNULLED]: 'Аннулирован',
      [ContractStatusType.CANCELED]: 'Отклонен',
      [ContractStatusType.DRAFT]: 'Черновик',
      [ContractStatusType.WAITING_TERMINATE]: 'Ожидает расторжения',
    };

    return {
      description: statusDescriptions[status.value],
      type: status.value,
    };
  }
}
