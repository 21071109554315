<h1 class="fio">{{ profile?.fullName || 'Сводка' }}</h1>
<prlw-profile-summary-check-list
  [profileCheckList]="summaryInfo?.checkListData || null"
></prlw-profile-summary-check-list>
<div class="card-box">
  <prlw-profile-summary-contact-card
    [profile]="profile"
  ></prlw-profile-summary-contact-card>
  <prlw-profile-summary-organization-card
    [profileOrganization]="summaryInfo?.organisationData || null"
  ></prlw-profile-summary-organization-card>
  <prlw-profile-summary-license-card
    [profileLicense]="summaryInfo?.licenseData || null"
  ></prlw-profile-summary-license-card>
</div>
