import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Profile } from '@prlw/core/profile/profile.entity';
import { SummaryInfo } from '@prlw/core/profile/summary.entity';

@Component({
  selector: 'prlw-profile-summary-ui',
  templateUrl: './profile-summary.component.html',
  styleUrls: ['./profile-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSummaryComponent {
  @Input() public summaryInfo?: SummaryInfo;
  @Input() public profile?: Profile;
}
