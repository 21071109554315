export enum MainTableColumnId {
  BASIS = 1,
  OFFER = 2,
  DEAL = 3,
  DEMAND = 4,
  CHANGES = 5,
  VOLUME = 6,
  PREV_SESSION_VOLUME = 7,
  PREV_DEALS_AMOUNT_DIFF = 8,
  TARIFF = 9,
  LOCAL_PRICE = 10,
  SHIPMENT = 11,
  ON_THE_WAY = 12,
  TRADE_START = 13,
  BRIEF_DESCRIPTION = 14,
}

export type MainTableColumn<MainTableColumnId> = {
  id: MainTableColumnId;
  name: string;
  isVisible?: boolean;
  isStatic?: boolean;
  cellSizeMin?: string;
  cellSizeMax?: string;
};

export type MainTableSorting = {
  columnId: MainTableColumnId;
  direction: 'ASC' | 'DES';
};

export type ValueChanges = {
  isOfferUp: boolean | null;
  isDealUp: boolean | null;
  isDemandUp: boolean | null;
};

export type ValuesChanges = {
  [key: string]: ValueChanges;
};

export type exportData = number | string | Date | null | undefined;

export type LastValues = {
  [key: string]:
    | {
        offer: number | null;
        deal: number | null;
        demand: number | null;
      }
    | undefined;
};
