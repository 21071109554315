import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Layout } from '@prlw/common/layout/layout.enum';
import { PageContextService } from '@prlw/common/page/page-context.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'prlw-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
  public readonly layouts = Layout;
  public activeLayout$: Observable<Layout> | undefined;

  constructor(private readonly pageContextService: PageContextService) {}

  public ngOnInit(): void {
    this.activeLayout$ = this.pageContextService.pageContext$.pipe(
      map((pageContext) => pageContext.layout),
    );
  }
}
