<div class="container">
  <div
    class="input-wrapper"
    prlwFileUploadDnd
    (fileHovered)="hovered($event)"
    (fileDropped)="onFileDropped($event)"
  >
    <input
      #inputFile
      type="file"
      class="file-upload"
      multiple
      (change)="onFileBrowser($event)"
    />
    <div class="control-wrapper">
      <div [class.icon--active]="isHover" class="icon"></div>
      <div class="text">Перетащите сюда файлы или нажмите</div>
      <button class="btn" (click)="onUpload()">Выбрать файл</button>
    </div>
  </div>
</div>
