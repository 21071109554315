import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProductGroup } from '@prlw/core/products/product-group.entity';
import { Shipment } from '@prlw/core/shipments/shipment.entity';
import { ShipmentsGateway } from '@prlw/core/shipments/shipments.gateway';
import { GetShipmentsResponse } from '@prlw/data/shipments/api-types/get-shipments.api';
import {
  ShipmentsDataConfig,
  SHIPMENTS_DATA_CONFIG,
} from '@prlw/data/shipments/shipments-data.config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HttpShipmentsGateway implements ShipmentsGateway {
  constructor(
    @Inject(SHIPMENTS_DATA_CONFIG)
    private readonly config: ShipmentsDataConfig,
    private readonly http: HttpClient,
  ) {}

  public getShipmentsByProductGroupsList(
    groups: ProductGroup[],
  ): Observable<Shipment[]> {
    const params = { productGroups: groups.map((p) => p.code).join(',') };
    return this.http
      .get<GetShipmentsResponse>(this.config.apiPrefix, { params })
      .pipe(
        map((shipments) =>
          shipments.map((item) => ({
            code: item.code,
            name: item.name,
            plants: item.shipmentBasisPlants.map((plantName) => ({
              name: plantName,
            })),
            briefDescription: item.briefDescription,
          })),
        ),
      );
  }
}
