import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Product } from '@prlw/core/products/product.entity';
import { ProductsGateway } from '@prlw/core/products/products.gateway';
import { GetGroupProducts } from '@prlw/data/products/api-types/get-group-products.api';
import {
  ProductsDataConfig,
  PRODUCTS_DATA_CONFIG,
} from '@prlw/data/products/products-data.config';
import { Observable } from 'rxjs';

@Injectable()
export class HttpProductsGateway implements ProductsGateway {
  constructor(
    @Inject(PRODUCTS_DATA_CONFIG) private readonly config: ProductsDataConfig,
    private readonly http: HttpClient,
  ) {}

  public getProductsOfGroup(
    activeProductGroup: string,
    shipments: string[],
  ): Observable<Product[]> {
    const params: {
      productGroup: string;
      shipmentBasics?: string;
    } = {
      productGroup: activeProductGroup,
    };
    if (shipments.length) {
      params.shipmentBasics = shipments.join(',');
    }
    return this.http.get<GetGroupProducts>(`${this.config.apiPrefix}-codes`, {
      params,
    });
  }
}
