<prlw-common-pane [transparent]="transparent">
  <a class="main-page-link" routerLink="/" aria-label="Главная страница">
    <img
      class="logo-image"
      src="/assets/logo/logo-full.svg"
      aria-hidden="true"
      alt="logo-full"
    />
  </a>
</prlw-common-pane>
