import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FEEDBACK_GATEWAY } from '@prlw/core/feedback/feedback-gateway.token';
import { FeedbackGateway } from '@prlw/core/feedback/feedback-gateway.interface';
import { Feedback } from '@prlw/core/feedback/feedback.entity';
import { FeedbackStatus } from '@prlw/core/feedback/feedback-status.enum';
import { takeUntil } from 'rxjs/operators';
import { TFileUploadResponse } from '@prlw/data/feedback/api-types/file-upload.api';

@Injectable({
  providedIn: 'root',
})
export class FeedbackController implements OnDestroy {
  public feedbackStatus$: Observable<FeedbackStatus>;
  private readonly _feedbackStatus$: BehaviorSubject<FeedbackStatus>;
  private readonly _destroy$ = new Subject<void>();

  constructor(
    @Inject(FEEDBACK_GATEWAY) private readonly feedbackGateway: FeedbackGateway,
  ) {
    this._feedbackStatus$ = new BehaviorSubject<FeedbackStatus>(
      FeedbackStatus.INIT,
    );
    this.feedbackStatus$ = this._feedbackStatus$.asObservable();
  }

  public setInit(): void {
    this._feedbackStatus$.next(FeedbackStatus.INIT);
  }

  public sendFeedback(data: Feedback): void {
    this._feedbackStatus$.next(FeedbackStatus.SENDING);

    this.feedbackGateway
      .sendFeedback(data)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        if (res.success) {
          this._feedbackStatus$.next(FeedbackStatus.SENT);
        } else {
          this._feedbackStatus$.next(FeedbackStatus.ERROR);
        }
      });
  }

  public fileUpload(file: File): Observable<TFileUploadResponse> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.feedbackGateway
      .fileUpload(formData)
      .pipe(takeUntil(this._destroy$));
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
