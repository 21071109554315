import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfilePage } from './profile-page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from '@prlw/libs/tabs/tabs.module';
import { ProfileOrganizationTabComponent } from './organization-tab/profile-organization-tab.component';
import { ProfileAddOrganizationComponent } from './organization-tab/add-organization/profile-add-organization.component';
// eslint-disable-next-line max-len
import { ProfileOrganizationDetailsComponent } from './organization-tab/profile-organization-details/profile-organization-details.component';
import { ProfileOrganizationInfoComponent } from './organization-tab/profile-organization-info/profile-organization-info.component';
import { ProfileOrganizationTabContainer } from './organization-tab/profile-organization-tab.container';
import { ProfileAddOrganizationContainer } from './organization-tab/add-organization/profile-add-organization.container';
import { IconsModule } from '@prlw/libs/icons/icons.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { ProfileInfoContainer } from './legacy-profile-info/profile-info.container';
import { ProfileInfoComponent } from './legacy-profile-info/profile-info.component';
import { ProfileSummaryComponent } from './profile-summary-tab/profile-summary.component';
import { ProfileSummaryContainer } from './profile-summary-tab/profile-summary.container';
import { ProfileSummaryCheckListComponent } from './profile-summary-tab/profile-summary-check-list/profile-summary-check-list.component';
// eslint-disable-next-line max-len
import { ProfileSummaryContactCardComponent } from './profile-summary-tab/profile-summary-contact-card/profile-summary-contact-card.component';
// eslint-disable-next-line max-len
import { ProfileSummaryOrganizationCardComponent } from './profile-summary-tab/profile-summary-organization-card/profile-summary-organization-card.component';
// eslint-disable-next-line max-len
import { ProfileSummaryLicenseCardComponent } from './profile-summary-tab/profile-summary-license-card/profile-summary-license-card.component';
import { PersonalDataContainer } from './personal-data-tab/personal-data.container';
import { PersonalDataComponent } from './personal-data-tab/personal-data.component';
import { TimeFormatModule } from '@prlw/infrastructure/time-format/time-format.module';
import { FileUploadOverlayComponent } from './license-tab/file-upload-overlay/file-upload-overlay.component';
import { UploadedDocumentsComponent } from './license-tab/file-upload-overlay/uploaded-documents/uploaded-documents.component';
import { UploadedDocumentsContainer } from './license-tab/file-upload-overlay/uploaded-documents/uploaded-documents-container';
import { FileUploadComponent } from './license-tab/file-upload-overlay/file-upload/file-upload.component';
import { AttachmentsFilesComponent } from './license-tab/file-upload-overlay/attachments-files/attachments-files.component';
import { UploadStepsComponent } from './license-tab/file-upload-overlay/upload-steps/upload-steps.component';
import { AttachmentsFilesContainer } from './license-tab/file-upload-overlay/attachments-files/attachments-files-container';
import { DndDirective } from './license-tab/file-upload-overlay/file-upload/dnd.directive';
import { ClickOutsideDirective } from './license-tab/file-upload-overlay/uploaded-documents/click-outside.directive';
import { FileUploadOverlayContainer } from './license-tab/file-upload-overlay/file-upload-overlay-container';
import { LicenseHeaderComponent } from './license-tab/license-header/license-header.component';
import { LicenseDocsAlertComponent } from './license-tab/license-alert/license-docs-alert/license-docs-alert.component';
import { FileUploadProgressComponent } from './license-tab/file-upload-overlay/file-upload-progress/file-upload-progress.component';
import { FileUploadStatusComponent } from './license-tab/file-upload-overlay/file-upload-status/file-upload-status.component';
import { LicenseTabComponent } from './license-tab/license-tab.component';
import { LicenseAlertComponent } from './license-tab/license-alert/license-alert.component';
import { LicenseCardsComponent } from './license-tab/license-cards/license-cards.component';
import { LicenseHistoryComponent } from './license-tab/license-history/license-history.component';
import { LicenseCardProComponent } from './license-tab/license-cards/license-card-pro/license-card-pro.component';
import { LicenseHistoryContainer } from './license-tab/license-history/license-history.container';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { UserListTabContainer } from './user-list-tab/user-list-tab.container';

@NgModule({
  declarations: [
    ProfilePage,
    LicenseAlertComponent,
    LicenseCardsComponent,
    LicenseHistoryComponent,
    LicenseCardProComponent,
    LicenseHistoryContainer,
    ProfileOrganizationTabComponent,
    ProfileAddOrganizationComponent,
    ProfileOrganizationDetailsComponent,
    ProfileOrganizationInfoComponent,
    ProfileOrganizationTabContainer,
    ProfileAddOrganizationContainer,
    ProfileInfoContainer,
    ProfileInfoComponent,
    ProfileSummaryComponent,
    ProfileSummaryContainer,
    ProfileSummaryCheckListComponent,
    ProfileSummaryContactCardComponent,
    ProfileSummaryOrganizationCardComponent,
    ProfileSummaryLicenseCardComponent,
    PersonalDataComponent,
    PersonalDataContainer,
    FileUploadOverlayComponent,
    UploadedDocumentsComponent,
    UploadedDocumentsContainer,
    FileUploadComponent,
    AttachmentsFilesContainer,
    AttachmentsFilesComponent,
    UploadStepsComponent,
    DndDirective,
    ClickOutsideDirective,
    FileUploadOverlayContainer,
    LicenseHeaderComponent,
    LicenseDocsAlertComponent,
    FileUploadStatusComponent,
    LicenseTabComponent,
  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    ReactiveFormsModule,
    TabsModule,
    FormsModule,
    IconsModule,
    OverlayModule,
    TimeFormatModule,
    NgxMaskDirective,
    UserListTabContainer,
    FileUploadProgressComponent,
  ],
  providers: [provideNgxMask()],
  exports: [FileUploadProgressComponent],
})
export class ProfileUiModule {}
