import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  PaginationService,
  PaginationData,
} from '@prlw/infrastructure/pagination/pagination.service';

const DEFAULT_PAGE_SIZE = '10';

@Injectable({ providedIn: 'root' })
export class PaginationInterceptor implements HttpInterceptor {
  constructor(private readonly paginationService: PaginationService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const paginationKey = request.headers.get('Pagination-key');
    const paginationSize =
      request.headers.get('Pagination-size') ?? DEFAULT_PAGE_SIZE;
    if (paginationKey) {
      return this.paginationService.getDistinctStateByKey(paginationKey).pipe(
        switchMap((paginationState) => {
          const params = this.getParams(
            request,
            paginationState.currentPage,
            paginationState.pageSize,
            paginationSize,
          );
          const cleanedRequest = request.clone({
            headers: request.headers
              .delete('Pagination-key')
              .delete('Pagination-size'),
            params,
          });
          return next.handle(cleanedRequest);
        }),
        tap((response) => {
          const body = (response as HttpResponse<unknown> | undefined)?.body;
          if (body && !Array.isArray(body)) {
            // @ts-expect-error hard to set some type for this
            const { page, pageCount, pageSize, total } = body;
            const data: PaginationData = {
              currentPage: page <= pageCount ? page : 1,
              pageCount,
              pageSize,
              total,
            };
            this.paginationService.setState(paginationKey, data);
          }
        }),
      );
    }
    return next.handle(request.clone());
  }

  private getParams(
    request: HttpRequest<unknown>,
    currentPage: number,
    statePageSize: number,
    headerPageSize: string,
  ): HttpParams {
    let params = request.params;
    let pageSize = headerPageSize;
    if (currentPage) {
      params = params.append('page', String(currentPage));
    }
    if (statePageSize && headerPageSize !== String(statePageSize)) {
      pageSize = String(statePageSize);
    }
    return params.append('pageSize', pageSize);
  }
}
