<ng-container *ngIf="(profile$ | async) as profile; else preloader">
  <prlw-legacy-profile-info-ui
    [profile]="profile"
    [isEditor]="isEditor$ | async"
  ></prlw-legacy-profile-info-ui>
</ng-container>
<ng-template #preloader>
  <div class="preloader">
    <p>Что-то пошло не так.</p>
    <p>Перезагрузите страницу.</p>
  </div>
</ng-template>
