import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AnalyticsChartsGateway } from '@prlw/core/analytics-charts/analytics-charts-gateway.interface';
import { TProductPickerItem } from '@prlw/core/analytics-charts/types/product-picker-item';
import { TPriceComparisonItem } from '@prlw/core/analytics-charts/types/price-comparison-item';
import { TDynamicsOfVolumesItem } from '@prlw/core/analytics-charts/types/dynamics-of-volumes-item';
import { TDynamicsByPlants } from '../../core/analytics-charts/types/dynamics-by-plants';
import { TDynamicsByBidsItem } from '@prlw/core/analytics-charts/types/dynamics-by-bids-item';
import { TCargoFlowItem } from '@prlw/core/analytics-charts/types/cargo-flow-item';

@Injectable({
  providedIn: 'root',
})
export class HttpAnalyticsChartsGateway implements AnalyticsChartsGateway {
  prefix = '/api/analytics-charts';

  constructor(private readonly http: HttpClient) {}

  getProductPickerItems() {
    return this.http.get<TProductPickerItem[]>(`${this.prefix}/product-picker`);
  }

  getProductPickerCargoItems() {
    return this.http.get<TProductPickerItem[]>(
      `${this.prefix}/product-picker-cargo`,
    );
  }

  getPriceComparisonItems(
    productGroupNames: string[],
    startDate: string,
    endDate: string,
  ) {
    return this.http.get<TPriceComparisonItem[]>(
      `${this.prefix}/price-comparison?productGroupNames=${productGroupNames}&startDate=${startDate}&endDate=${endDate}`,
    );
  }

  getDynamicsOfVolumesItems(
    productGroupNames: string[],
    startDate: string,
    endDate: string,
  ) {
    return this.http.get<TDynamicsOfVolumesItem[]>(
      `${this.prefix}/dynamics-of-volumes?productGroupNames=${productGroupNames}&startDate=${startDate}&endDate=${endDate}`,
    );
  }

  getDynamicsByPlants(
    productGroupNames: string[],
    startDate: string,
    endDate: string,
    primary: boolean,
    secondary: boolean,
    f: boolean,
    w: boolean,
    u: boolean,
  ) {
    return this.http.get<TDynamicsByPlants>(
      `${
        this.prefix
      }/dynamics-by-plants?productGroupNames=${productGroupNames.join(
        ',',
      )}&startDate=${startDate}&endDate=${endDate}&primary=${primary}&secondary=${secondary}&f=${f}&w=${w}&u=${u}`,
    );
  }

  getDynamicsByBids(
    productGroupNames: string[],
    startDate: string,
    endDate: string,
    primary: boolean,
    secondary: boolean,
    f: boolean,
    w: boolean,
    u: boolean,
  ) {
    return this.http.get<TDynamicsByBidsItem[]>(
      `${
        this.prefix
      }/dynamics-by-bids?productGroupNames=${productGroupNames.join(
        ',',
      )}&startDate=${startDate}&endDate=${endDate}&primary=${primary}&secondary=${secondary}&f=${f}&w=${w}&u=${u}`,
    );
  }

  getCargoFlowItems(productCargoGroupNames: string[]) {
    return this.http.get<TCargoFlowItem[]>(
      `${
        this.prefix
      }/cargo-flow?productCargoGroupNames=${productCargoGroupNames.join(',')}`,
    );
  }
}
