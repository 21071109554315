import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Profile } from '@prlw/core/profile/profile.entity';

@Component({
  selector: 'prlw-legacy-profile-info-ui',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileInfoComponent {
  @Input() public profile?: Profile;
  @Input() public isEditor: boolean | null = null;
}
