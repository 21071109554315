import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function takeFirstError(
  validators: ValidatorFn[],
): ValidationErrors | null {
  if (validators.length === 0) {
    return null;
  }
  return function (control: AbstractControl) {
    for (const validator of validators) {
      const error = validator(control);
      if (error !== null) {
        return error;
      }
    }
    return null;
  };
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
export function isValidINN(
  control: AbstractControl,
): { invalidInn: boolean } | null {
  // Algorithm: https://www.egrul.ru/test_inn.html

  const weightedSum = (source: number[], weights: number[]): number => {
    const sum = weights.reduce(
      (prevSum, k, index) => prevSum + source[index] * k,
      0,
    );
    return (sum % 11) % 10;
  };
  const value = control.value;
  if (value.length === 10) {
    const digits = [...value].map((c) => +c);
    const check = weightedSum(digits, [2, 4, 10, 3, 5, 9, 4, 6, 8, 0]);
    return check === digits[9] ? null : { invalidInn: true };
  }
  if (value.length === 12) {
    const digits = [...value].map((c) => +c);
    const check1 = weightedSum(digits, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0]);
    const check2 = weightedSum(digits, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0]);
    return check1 === digits[10] && check2 === digits[11]
      ? null
      : { invalidInn: true };
  }
  return { invalidInn: true };
}

/* eslint-enable @typescript-eslint/no-magic-numbers */
