export enum ContractsTableColumnId {
  BUYER = 1,
  SELLER = 2,
  PRODUCT = 3,
  BASIS = 4,
  AMOUNT = 5,
  PRICE = 6,
  STATION = 7,
  CREATE_DATE = 8,
  SIGNING_DATE = 9,
  DOCUMENT_VIEW = 10,
  STATUS = 11,
}

export type ContractsTableColumn<ContractsTableColumnId> = {
  id: ContractsTableColumnId;
  name: string;
  isVisible: boolean;
  isStatic: boolean;
  cellSizeMin: string;
  cellSizeMax?: string;
  cellFixed?: boolean;
};

export type ContractsTableSorting = {
  columnId: ContractsTableColumnId;
  direction: 'ASC' | 'DES';
};

export type exportData = number | string | Date | null | undefined;
