import { SmallWholesaleDataModule } from './../data/small-wholesale/small-wholesale-data.module';
import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@prlw/common/layout/layout.module';
import { PageModule } from '@prlw/common/page/page.module';
import { MarketTimeDataModule } from '@prlw/data/market-time/market-time-data.module';
import { MarketUiModule } from '@prlw/common/market/market-ui.module';
import { IconsModule } from '@prlw/libs/icons/icons.module';
import { FeedbackDataModule } from '@prlw/data/feedback/feedback-data.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { FeedbackUiModule } from './features/feedback/feedback-ui.module';
import { AuthInfrastructureModule } from '@prlw/infrastructure/auth/auth-infrastructure.module';
import { AuthCoreModule } from '@prlw/core/auth/auth-core.module';
import { AuthDataModule } from '@prlw/data/auth/auth-data.module';
import { AuthInitializer, initAuth } from '@prlw/core/auth/auth.initializer';
import { ProfileDataModule } from '@prlw/data/profile/profile-data.module';
import { PersistStateModule } from '../legacy/persist-state/persist-state.module';
import { MonitorDataModule } from '@prlw/data/monitor/monitor-data.module';
import { ProductsDataModule } from '@prlw/data/products/products-data.module';
import { DestinationsDataModule } from '@prlw/data/destinations/destinations-data.module';
import { ContractsDataModule } from '@prlw/data/contracts/contracts-data.module';
import { DemoAccessModule } from './features/demo-access/demo-access.module';
import { ShipmentsDataModule } from '@prlw/data/shipments/shipments-data.module';
import {
  initActivity,
  UserActivityService,
} from '@prlw/libs/user-activity/user-activity.service';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { UserSettingsDataModule } from '@prlw/data/user-settings/user-settings-data.module';
import { DealsDataModule } from '@prlw/data/deals/deals-data.module';
import { BuyProductModule } from './features/buy-product/buy-product.module';
import { BuyProductDataModule } from '@prlw/data/buy-product/buy-product-data.module';
import { DealsCoreModule } from '@prlw/core/deals/deals-core.module';
import { MonitorCoreModule } from '@prlw/core/monitor/monitor-core.module';
import { ContractsCoreModule } from '@prlw/core/contracts/contracts-core.module';
import { PaginationInfrastructureModule } from '@prlw/infrastructure/pagination/pagination-infrastructure.module';
import { ToastrModule } from 'ngx-toastr';
import { ErrorsInfrastructureModule } from '@prlw/infrastructure/errors/errors-infrastructure.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaServiceModule } from '@prlw/infrastructure/recaptcha/recaptcha.module';
import * as Sentry from '@sentry/angular';
import { AnalyticsChartsDataModule } from '@prlw/data/analytics-charts/analytics-charts-data.module';
import { AnalyticsChartsCoreModule } from '@prlw/core/analytics-charts/analytics-charts-core.module';
import { FuturesCoreModule } from '@prlw/core/futures/futures-core.module';
import { FuturesDataModule } from '@prlw/data/futures/futures-data.module';
import { TariffsDataModule } from '@prlw/data/tariffs/tariffs-data.module';
import { MobileFooterContainer } from './common/mobile-footer/mobile-footer.container';
import { SseDataModule } from '@prlw/data/sse/sse-data.module';
import { PRODUCT_GROUP_CODE_IN_URL_REGEX } from '@prlw/core/products/product-groups.service';
import { CalendarDataModule } from '@prlw/data/calendar/calendar-data.module';
import { PreloaderCircleContainer } from './common/preloader-circle/preloader-circle.container';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';

registerLocaleData(localeRu, 'ru');
const TOASTR_TIME_OUT = 3000;
const TOASTR_ITEM_OPENED_COUNT = 3;

@NgModule({
  declarations: [AppComponent],
  imports: [
    RecaptchaServiceModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    OverlayModule,
    FeedbackUiModule,
    BuyProductModule,
    AuthDataModule.forRoot({ apiPrefix: '/api/auth' }),
    AuthCoreModule,
    AnalyticsChartsCoreModule,
    FuturesCoreModule,
    AuthInfrastructureModule,
    PaginationInfrastructureModule,
    LayoutModule,
    PageModule,
    MarketUiModule,
    MarketTimeDataModule.forRoot({ apiPrefix: '/api/calendar/trading-time' }),
    IconsModule.forRoot({ baseUrl: '/assets/icons' }),
    FeedbackDataModule.forRoot({ apiPrefix: '/api/profile/feedback' }),
    BuyProductDataModule.forRoot({ apiPrefix: '/api/notification/send-email' }),
    ProfileDataModule.forRoot({ apiPrefix: '/api/profile' }),
    ProductsDataModule.forRoot({ apiPrefix: '/api/opinfo/products' }),
    DestinationsDataModule.forRoot({ apiPrefix: '/api/opinfo' }),
    MonitorDataModule.forRoot({ apiPrefix: '/api/opinfo' }),
    ContractsDataModule.forRoot({ apiPrefix: '/api/contracts' }),
    ShipmentsDataModule.forRoot({ apiPrefix: '/api/opinfo/shipment-basis' }),
    UserSettingsDataModule.forRoot({ apiPrefix: '/api/preferences' }),
    DealsDataModule.forRoot({ apiPrefix: 'api/opinfo' }),
    SmallWholesaleDataModule.forRoot({ apiPrefix: 'api/region' }),
    AnalyticsChartsDataModule,
    FuturesDataModule,
    TariffsDataModule,
    CalendarDataModule,
    SseDataModule,
    PersistStateModule,
    DemoAccessModule,
    MonitorCoreModule,
    ContractsCoreModule,
    DealsCoreModule,
    ErrorsInfrastructureModule,
    ToastrModule.forRoot({
      timeOut: TOASTR_TIME_OUT,
      closeButton: true,
      enableHtml: true,
      maxOpened: TOASTR_ITEM_OPENED_COUNT,
      autoDismiss: true,
      preventDuplicates: true,
    }),
    MobileFooterContainer,
    PreloaderCircleContainer,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initAuth,
      deps: [AuthInitializer],
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initActivity,
      deps: [UserActivityService],
    },
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: PRODUCT_GROUP_CODE_IN_URL_REGEX, useValue: /monitor\/(.*)\/?$/ },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    provideLottieOptions({
      player: () => player,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
